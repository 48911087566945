import styled from "@emotion/styled";

export default ({ name }: { name: any }) => {
  console.log(name);
  return <AthleteName>{name}</AthleteName>;
};

const AthleteName = styled.div`
  padding-left: 8px;
`;

import { Tab as MUITab, TabProps } from "@mui/material"
import { FC } from "react"
import styled from '@emotion/styled'

export const Tab: FC<TabProps> = (props) => {
  return (
    <SWrapper>
      <MUITab
        iconPosition="start"
        tabIndex={0}
        {...props}
      />
    </SWrapper>
  )
}

const SWrapper = styled.div`
  & > * {
    color: #1A222D;
    font-weight: 600!important;
    text-align: left;
    justify-content: flex-start!important;
    box-sizing: border-box;
    padding: 12px 8px!important;
  }

  & .Mui-selected {
    color: #1A222D!important;
  }
`

import { action, makeAutoObservable, observable } from "mobx";
import Store from "./Store";

type DisplayStateProps = {
  displayWorkoutWindowOpen: boolean;
  addAthleteOn: boolean;
  addWorkoutModalOpen: boolean;
  menuOpen: boolean;
};

export default class CoachStore {
  mineAthletesList = observable.array();

  athletesList = observable.array();

  currentAthleteId: string | undefined;

  displayState: DisplayStateProps = {
    addAthleteOn: false,
    displayWorkoutWindowOpen: false,
    addWorkoutModalOpen: false,
    menuOpen: false,
  };
  currentAthlete: any;

  setDisplayAddAthlete(isOpen: boolean) {
    this.displayState.addAthleteOn = isOpen;
  }

  setWorkoutModalOpen(isOpen: boolean) {
    this.displayState.addWorkoutModalOpen = isOpen;
    console.log("setDisplayAddAthlete call");
  }

  setWorkoutInfoOpen(isOpen: boolean) {
    this.displayState.displayWorkoutWindowOpen = isOpen;
    console.log("setWorkoutInfoOpen called");
  }

  setMenuOpen(isOpen: boolean) {
    this.displayState.menuOpen = isOpen;
  }

  setCurrentAthlete(id: string) {
    if (!id) return;
    this.currentAthleteId = id;
    this.currentAthlete = this.mineAthletesList.find((x) => x.id === id);
  }

  constructor(private store: Store) {
    makeAutoObservable(this, {
      setDisplayAddAthlete: action.bound,
      setWorkoutModalOpen: action.bound,
      setMenuOpen: action.bound,
      setCurrentAthlete: action.bound,
      currentAthlete: observable,
      athletesList: observable,
    });
  }
}

import AppApi from "./AppApi";
import AppStore from "../stores/Store";
import { WorkoutData } from "../stores/WorkoutStore";
import { AxiosResponse } from "axios";
import pMemoize from 'p-memoize';
import { AnalysisDisplayType } from "../pages/TrainAnalysis/types";

export default class WorkoutApi {
  constructor(private api: AppApi, private store: AppStore) {
    this.api = api;
    this.store = store;
    this.initLoadMyWorkouts();
  }

  initLoadMyWorkouts = () => {
    this.loadMyWorkouts = this._loadMyWorkouts;
    // this.loadMyWorkouts = pMemoize(this._loadMyWorkouts, {
    //   cacheKey: (arguments_) => arguments_[0].toISOString() + arguments_[1].toISOString(),
    // });
  }

  loadMyWorkouts = this._loadMyWorkouts;

  async setAnalysisType(type: AnalysisDisplayType) {
    this.store.detailStore.setAnalysisDisplayType(type);
  }

  async _loadMyWorkouts(dateStart: Date, dateEnd: Date) {
    const response = await this.api.client.get(
      `/users/api/v1/workouts?athleteId=${
        this.store.coachStore.currentAthleteId || ""
      }&startDate=${dateStart.toISOString()}&endDate=${dateEnd.toISOString()}`
    );
    if (response.status === 200) {
      this.store.workoutStore.replaceWorkoutItems(
        response.data,
        dateStart,
        dateEnd
      );
      // this.store.workoutStore.items = response.data;
    } else {
      if (response.status === 401) {
        window.location.href = "/login";
      }
      //
    }
    return this.store.coachStore.mineAthletesList;
  }
  async saveWorkout(data: WorkoutData) {
    try {
      let response: AxiosResponse;
      if (!data.id) {
        response = await this.api.client.post(`/users/api/v1/workouts`, data);
      } else {
        response = await this.api.client.put(`/users/api/v1/workouts`, data);
      }

      if (response.status === 200) {
        this.initLoadMyWorkouts();
      } else {
        //
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getWorkoutStream(id: string) {
    const response = await this.api.client.get(
      `/users/api/v1/workouts/${id}/streams`
    );

    const polylineData = response.data["polyline"];
    this.store.detailStore.setWorkout({
      id: response.data["id"],
      coordinates: [],
      polyline: polylineData,
    });

    this.store.detailStore.setStreamData(response.data["stream"]);
  }

  async getWorkoutData(id: string) {
    const response = await this.api.client.get(`/users/api/v1/workouts/${id}`);
    this.store.detailStore.setWorkoutData(response.data);
  }

  async getWorkoutsList(athleteId: string) {
    const response = await this.api.client.get(
      `/users/api/v1/analyze/workouts?athleteId=${athleteId}`
    );
    this.store.workoutStore.setWorkoutList(response.data);
  }

  async deleteWorkout(workoutId: string) {
    await this.api.client.delete(`/users/api/v1/workouts/${workoutId}`);
    this.initLoadMyWorkouts();
  }

  async unMergeWorkout(workoutId: string) {
    const token = await this.store.authStore.getAuthToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    console.log(`/users/api/v1/workouts/${workoutId}/split`);
    this.initLoadMyWorkouts();
    await this.api.client.post(
      `/users/api/v1/workouts/${workoutId}/split`,
      {},
      config
    );
  }

  async mergeWorkout(planId: string, factId: string) {
    const token = await this.store.authStore.getAuthToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    // this.initLoadMyWorkouts();
    await this.api.client.post(
      `/users/api/v1/workouts/merge`,
      {
        planId,
        factId,
      },
      config
    );
  }
}



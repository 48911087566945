import { DragEndEvent } from '@dnd-kit/core';
import { endOfMonth } from 'date-fns';
import { Dispatch, useCallback } from 'react';
import { useAppContext } from '../../../../AppContext';
import { IRow } from '../types/IRow';
import { getIdByDate } from '../utils';

interface IResult {
  dragEndHandle: Dispatch<DragEndEvent>;
}

export const useDragSchedulerCard = (
  rows: IRow[]
): IResult => {
  const {
    store: { workoutStore },
    api,
  } = useAppContext();

  const getDateByCellId = useCallback((id: string | undefined) => {
    if (!id) {
      return;
    }

    for (const row of rows) {
      const dates = row.dates;
      for (const d of dates) {
        if (getIdByDate(d.date) === id) {
          return d.date;
        }
      }
    }
  }, [rows]);

  const dragEndHandle = (data: DragEndEvent) => {
    const cardId = data.active.id;
    const sectionId = String(data.over?.id);
    const newDate = getDateByCellId(sectionId);
    const workout = workoutStore.items.find((item: any) => item.id === cardId);

    if (!newDate || !workout) {
      return;
    }

    const newWorkout = {
      ...workout,
      startDate: newDate.toDate(),
    };

    console.log('work', workout?.startDate, newWorkout.startDate);
    workoutStore.editWorkout(newWorkout);
    api.workoutApi.saveWorkout(newWorkout).then(() => {
      void api.workoutApi.loadMyWorkouts(
        workoutStore.dateStart || new Date(),
        workoutStore.dateEnd || endOfMonth(new Date())
      );
    });
    console.log(cardId);
  };

  return {
    dragEndHandle,
  };
};
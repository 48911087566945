import { Box } from "@mui/system";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as EtcIcon } from "../../../../assets/icons/etc.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/popover_close.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as MergeIcon } from "../../../../assets/icons/merge.svg";
import { ReactComponent as UnMergeIcon } from "../../../../assets/icons/unmerge.svg";
import styled from "@emotion/styled";
import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";

export const Controls = ({
  onClose,
  onEdit,
  onDelete,
  onMerge,
  onUnmerge,
  hasFact,
  hasPlan,
  hasAnyPlansToday,
}: {
  onClose: (e: any) => void;
  onEdit: (e: any) => void;
  onDelete: () => void;
  onMerge: () => void;
  onUnmerge: () => void;
  hasFact: boolean;
  hasPlan: boolean;
  hasAnyPlansToday: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  const handleMerge = () => {
    onMerge();
    handleClose();
  };

  const handleUnMerge = () => {
    onUnmerge();
    handleClose();
  };

  return (
    <SWrapper>
      <SIcon>
        <EditIcon
          onClick={(e) => {
            onEdit(e);
            onClose(e);
          }}
        />
      </SIcon>
      <SIcon>
        <EtcIcon
          onClick={(e: any) => {
            handleClick(e);
          }}
        />
      </SIcon>
      <Box sx={{ display: "flex", flex: 1, justifyContent: "end" }}>
        <CloseIcon onClick={onClose} />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {!hasPlan && hasFact && hasAnyPlansToday && (
          <SMenuItemWrapper onClick={handleMerge}>
            <MergeIcon />
            <SMenuItemText>Связать с планом</SMenuItemText>
          </SMenuItemWrapper>
        )}
        {hasPlan && hasFact && (
          <SMenuItemWrapper onClick={handleUnMerge}>
            <UnMergeIcon />
            <SMenuItemText>Отвязать от плана</SMenuItemText>
          </SMenuItemWrapper>
        )}
        <SMenuItemWrapper onClick={handleDelete}>
          <DeleteIcon />
          <SMenuItemText>Удалить</SMenuItemText>
        </SMenuItemWrapper>
      </Menu>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  border-bottom: 1px solid #cdd9ea;
  gap: 8px;
`;

const SMenuItemWrapper = styled(MenuItem)`
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
`;

const SMenuItemText = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

const SIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

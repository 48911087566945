export default ({ content }: { content: string }) => {
  if (!content) return null;
  return (
    <div
      style={{
        fontSize: "14px",
        color: "#1A222D",
      }}
    >
      {content}
    </div>
  );
};

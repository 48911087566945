import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../AppContext";
export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const { api } = useAppContext();

  async function addToCoach(linkId: string) {
    await api.coachApi.addAthlete(linkId);
    navigate("/dashboard", { replace: true });
  }

  useEffect(() => {
    if (params.linkId) addToCoach(params.linkId);
  });

  return <></>;
};

import styled from "@emotion/styled";
import { getIconByWorkoutType } from "../../calendar/utils/getIconByWorkoutType";
import moment from 'moment';

interface IProps {
  date: Date;
  name: string;
  type: string;
}

export default ({ name, date, type }: IProps) => {
  return (
    <SWrapper>
      <SIcon>
        {getIconByWorkoutType(type)}
      </SIcon>
      {!name && (
        <STitle>{moment(date).subtract(3, 'h').format('D MMMM, H:mm').replace(', 0:00', '')}</STitle>
      )}
      {!!name && (
        <STitle>{name}</STitle>
      )}
    </SWrapper>
  )
}

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 16px;
  box-sizing: border-box;
`

const STitle = styled.span`
  font-weight: 500;
`

const SIcon = styled.div`
  width: 24px;
  height: 24px;
`

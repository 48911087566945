import TableData from "./TableData";
import { Stepper } from './components/Stepper/Stepper';
import { useChartHover } from "./hooks/useChartHover";
import { ChartData } from "./components/ChartData/ChartData";
import { ProgressPopup } from "./components/ProgressPopup/ProgressPopup";
import styled from "@emotion/styled";
import { useSteps } from "./hooks/useSteps";
import { useCallback } from "react";
import { useAppContext } from "../../AppContext";
import { observer } from "mobx-react";
import EmptyTable from "./EmptyTable";

export default observer(() => {
  const { onMouseMove, onMouseOver, positionPercent } = useChartHover();

  const { activeStep, steps } = useSteps(positionPercent);

  const {
    store: { detailStore },
  } = useAppContext();

  const onRowClick = useCallback(
    (idx: number) => {
      if (detailStore.workoutDetails == null) return;

      detailStore.setCurrentIdx(idx);
      detailStore.currentPoint = detailStore.workoutDetails[idx - 1].mapPoint;
    },
    [detailStore]
  );

  const chartClickHandle = useCallback(() => {
    if (activeStep) {
      onRowClick(activeStep);
    }
  }, [onRowClick, activeStep]);

  if (
    detailStore.workoutDetails != null &&
    detailStore.workoutDetails.length === 0
  ) {
    return <EmptyTable />;
  }

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        backgroundColor: "#EEF5FB",
        padding: "16px 24px",
      }}
    >
      <TableData />
      <Stepper
        steps={steps}
        activeStep={activeStep}
        onMouseMove={onMouseMove}
        onMouseOver={onMouseOver}
        onClick={chartClickHandle}
        positionPercent={positionPercent}
      />
      <SChart>
        <ProgressPopup positionPercent={positionPercent} />
        <ChartData
          onMouseMove={onMouseMove}
          onMouseOver={onMouseOver}
          positionPercent={positionPercent}
          activeStep={activeStep}
          stepsCount={steps.length}
          onClick={chartClickHandle}
        />
      </SChart>
    </div>
  );
});

const SChart = styled.div`
  position: relative;
`

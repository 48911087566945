import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { ReactComponent as PulseIcon } from '../../../../../../../../../assets/icons/pulse.svg';
import { ResultWorkoutType } from '../../enums/ResultWorkoutType';

interface IProps extends IPrimaryCardProps {
  children?: ReactNode;
  labelColor: string;
}

export interface IPrimaryCardProps {
  label?: string;
  labelColor?: string;
  km?: string;
  speed?: string;
  time?: string;
  pulse?: string;
  isTarget?: boolean;
  hasFact: boolean;
  workoutPlanType: ResultWorkoutType;
  onlyPlan?: boolean;
  dimensions: any;
}

export const PrimaryCard: FC<IProps> = ({
  onlyPlan,
  labelColor,
  children,
  km,
  speed,
  time,
  pulse,
  isTarget = false,
  hasFact,
  dimensions,
}) => {
  return (
    <SWrapper labelColor={labelColor} isTarget={isTarget}>
      <SContent>
        <SName>{children}</SName>
        <SStat inactive={!hasFact || onlyPlan}>
          {!!km && (
            <span>
              <span>{km}</span>
              <SDimensions> км</SDimensions>
            </span>
          )}
          {!!speed && (
            <span>
              <span>{speed}</span> <SDimensions>{dimensions.pace}</SDimensions>
            </span>
          )}
          {!!time && <span>{time}</span>}
          {!!pulse && (
            <SPulse>
              <PulseIcon />
              {pulse}
            </SPulse>
          )}
        </SStat>
      </SContent>
    </SWrapper>
  );
};

const SWrapper = styled.div<{
  labelColor: string | undefined;
  isTarget: boolean;
}>`
  position: relative;
  border: 1px solid #cdd9ea;
  border-radius: 4px;
  min-width: 124px;
  background: #fff;
  overflow: hidden;

  ${({ labelColor }) =>
    labelColor &&
    css`
      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 4px;
        background: ${labelColor};
      }
    `}

  & span {
    color: #1a222d;
  }

  ${({ isTarget }) =>
    isTarget &&
    css`
      background: #fdffb4;
    `}
`;

const SContent = styled.div`
  box-sizing: border-box;
  padding: 10px 8px;
  padding-left: 12px;

  & span {
    max-width: 90px;
  }
`;

const SStat = styled.div<{
  inactive?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 400;
  margin-top: 6px;
  gap: 5px 0;

  ${({ inactive }) =>
    inactive &&
    css`
      & * {
        color: #97a4b7 !important;
        fill: #97a4b7 !important;
      }
    `}

  & > * {
    width: 50%;
  }
`;

const SPulse = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`;
const SDimensions = styled.span`
  font-size: 11px;
`;

const SName = styled.span`
  font-size: 12px;
`;
import List from "@mui/material/List";
import {ListItem, ListItemButton} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {SettingStep} from "../../stores/SettingsStore";

export type SettingsListProps = {
    visible: boolean;
    onItemClick?: (value: SettingStep) => void;
};

export default (props: SettingsListProps) => {
    const onClickHandler = (step: SettingStep) => {
        if (props.onItemClick) {
            props.onItemClick(step);
        }
    }

    if (props.visible) {
        return <List>
            <ListItem disablePadding>
                <ListItemButton onClick={() => onClickHandler(SettingStep.ConnectingServices)}>
                    <ListItemText primary="Подключенные приложения" secondary="Подключить Strava, Garmin и д.р." />
                </ListItemButton>
            </ListItem>
        </List>
    }
    
    return <></>;
}
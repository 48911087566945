import { Button } from "@mui/material";
import { useAppContext } from "../../AppContext";
import AthleteItem from "./AthleteItem";
import { observer } from "mobx-react";
import { useLayoutEffect } from "react";
import styled from '@emotion/styled';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

const AthletesList = ({
  onAthleteAddClick,
  search,
}: {
  onAthleteAddClick: () => void;
  search: string;
}) => {
  const {
    store,
    store: {
      coachStore: { mineAthletesList },
      authStore,
    },
    api,
  } = useAppContext();
  const params = useParams();
  useLayoutEffect(() => {
    if (!params.athleteId && !!authStore.currentUser?.id) {
      store.coachStore.setCurrentAthlete(authStore.currentUser.id);
    } else if (params.athleteId) {
      store.coachStore.setCurrentAthlete(params.athleteId);
      // return;
    } else if (mineAthletesList.length > 0 && mineAthletesList[0] != null) {
      store.coachStore.setCurrentAthlete(mineAthletesList[0].id);
    }

    // api.workoutApi.loadMyWorkouts(startDate, endDate);
    store.workoutStore.setSchedulerCurrentDate(dayjs(new Date()));
  }, [
    store.coachStore,
    mineAthletesList,
    params,
    store.workoutStore,
    api.workoutApi,
    authStore.currentUser?.id,
  ]);

  const navigate = useNavigate();

  return (
    <>
      {store.coachStore.mineAthletesList
        .filter(
          (item) =>
            !search ||
            item?.fullName.toLowerCase().includes(search.toLowerCase())
        )
        .map((item, idx) => (
          <AthleteItem
            onClick={(id: string) => {
              store.coachStore.setCurrentAthlete(id);
              navigate(`/dashboard/${id}`);
            }}
            me={item?.id === store.authStore?.currentUser?.id}
            key={item?.id || idx}
            selected={item?.id === store.coachStore.currentAthleteId}
            item={{ id: item?.id, name: item?.fullName }}
          />
        ))}
      {store.authStore.currentUser?.isCoach && (
        <SButton>
          <Button onClick={onAthleteAddClick}>
            <PlusIcon />
            Добавить атлета
          </Button>
        </SButton>
      )}
    </>
  );
};

export default observer(AthletesList);

const SButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 56px;
  
  & button {
    padding: 8px 16px;
    display: flex;
    gap: 0 12px;
    text-align: left;
    justify-content: left;
    width: 100%;
    color: #1A222D;
    text-transform: none;
  }

  & svg {
    fill: #1A222D;
  }

  & button:hover svg {
    color: #44caaa;
  }
`

import { RefObject, useEffect, useMemo, useRef } from "react";
import { TChart } from "../types/TChart";
import { TPoint } from "../types/TPoint";

interface IParams {
	chart: TChart | undefined;
	hoverX: RefObject<number | undefined>;
	ref: RefObject<SVGSVGElement>;
	x: TPoint | undefined;
	y: TPoint | undefined;
	data: number[];
	suffix: string;
	positionPercent?: number;
	getPrefix?: (index: number) => string;
}

interface IResult {
	virtualTextRef: RefObject<HTMLDivElement>;
}

export const useCursorInfo = ({
	chart,
	hoverX,
	ref,
	x,
	y,
	data,
	suffix,
	positionPercent,
	getPrefix,
}: IParams): IResult => {
	const virtualTextRef = useRef<HTMLDivElement>(null);

	const circle = useMemo(() => {
		if (!chart) {
			return;
		}

		return chart
			.append('g')
			.append('circle')
			.style("fill", "#44CAAA")
			.attr("stroke", "white")
			.attr('r', 5)
			.style('z-index', '2')
	}, [chart])

	const box = useMemo(() => {
		if (!chart) {
			return;
		}

		const div = chart
			.select("body")
			.append('div')
			.attr("class", "tooltip")
			.style('fill', '#ff5500')
			// .attr('width', 10)
			// .attr('height', 10)
			.style('opacity', 1)
			.style('z-index', '2')

		return div;
	}, [chart])

	const popup = useMemo(() => {
		if (!chart) {
			return;
		}

		const g = chart.append('g')

		const text = g
			.append('text')
			.attr("text-anchor", "middle")
			.attr("alignment-baseline", "top")

		return text;
	}, [chart])

	useEffect(() => {
		const svgEl = ref.current;
		if (!hoverX.current || !x || !y || !svgEl || !virtualTextRef.current) {
			return;
		}


		if (circle && popup) {
			const circleX = hoverX.current
			const percent = (circleX) / svgEl.clientWidth;
			const index = Math.floor(percent * (data.length - 1));
			const circleY = Math.abs(y(data[index]))
			const opacity = !(Number.parseInt(String(data[index])) > 0) ? 0 : 1;

			circle
				.attr("cx", circleX)
				.attr("cy", circleY)
				.style('opacity', opacity)

			const popupX = circleX;
			const popupY = circleY < 15 ? circleY + 20 : circleY - 10;

			// virtualTextRef.current!.innerHTML = `${data[index]}${suffix.trim()}`
			//

			popup
				.html(getPrefix ? getPrefix(index) : `${data[index]}${suffix}`)
				.attr("x", popupX)
				.attr("y", popupY)
				.attr("cx", popupX)
				.attr("cy", popupY)
				.style('opacity', opacity)
		}

		/* if (box) {
			const boxX = hoverX.current
			const percent = (boxX) / svgEl.clientWidth;
			const index = Math.floor(percent * (data.length - 1));
			const boxY = Math.abs(y(data[index]))
			box
				.style("left", boxX + 'px')
				.style("top", boxY + 'px')
				.style('opacity', 1)
		} */
	}, [hoverX, data, circle, popup, x, y, ref, suffix, box])

	useEffect(() => {
		if (!popup || !circle) {
			return;
		}

		if (!hoverX.current) {
			popup.style('opacity', 0)
			circle.style('opacity', 0)
		}
	}, [hoverX, popup, circle, positionPercent])

	return {
		virtualTextRef
	}
}

import { TextField, TextFieldProps, TextFieldVariants } from "@mui/material"
import { FC } from "react";
import { BaseInput, IBaseInputProps } from "../BaseInput/BaseInput";

type ITextFieldProps = Omit<TextFieldProps, 'variant'> & { variant?: TextFieldVariants };

export interface ITextInputProps extends Omit<ITextFieldProps, 'label'>, IBaseInputProps {
  inputType: 'text';
  value?: string;
  placeholder?: string;
}

export const TextInput: FC<ITextInputProps> = (props) => {
  return (
    <BaseInput
      as={TextField}
      style={{ height: "96px", width: "592" }}
      variant="outlined"
      inputProps={{}}
      {...props}
    />
  )
}

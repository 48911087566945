import { observer } from "mobx-react";
import { WorkoutData } from "../../../../stores/WorkoutStore";
import { Box } from "@mui/system";
import { Controls } from "./controls";
import Main from "./main";
import Indicators from "./indicators";
import styled from '@emotion/styled'
import WorkoutDescription from "./WorkoutDescription";
import WorkoutTitle from "./WorkoutTitle";
import WorkoutFooter from "./WorkoutFooter";
import { MouseEvent, useCallback } from "react";
import ConfirmAction from "./ConfirmAction";
import { useAppContext } from "../../../../AppContext";
import CurrentWorkout from "./CurrentWorkout";
import WorkoutCard from "./WorkoutCard";
import dayjs from "dayjs";
import { Button, withStyles } from "@mui/material";

export default observer(
  ({
    item,
    onClose,
    onEdit,
    onDelete,
    onMerge,
    onUnmerge,
    todayEvents,
  }: {
    item: WorkoutData;
    onClose: (e: MouseEvent<HTMLDivElement>) => void;
    onEdit: (e: any) => void;
    onDelete: () => void;
    onMerge: () => void;
    onUnmerge: () => void;
    todayEvents: WorkoutData[];
  }) => {
    const { api, store } = useAppContext();
    function notEmpty(data: string): boolean {
      return data !== "{}" && data !== "" && !!data;
    }

    const closeAfterMerge = useCallback(
      async (id: string) => {
        if (id && item.id) {
          await api.workoutApi.mergeWorkout(id, item.id);
          store.workoutStore.setMergeDisplayModal(false);
          onClose({
            stopPropagation: () => {},
            preventDefault: () => {},
          } as any);

          await api.workoutApi.loadMyWorkouts(
            dayjs(item.startDate).startOf("day").toDate(),
            dayjs(item.startDate).endOf("day").toDate()
          );
        }
      },
      [api.workoutApi, item.id, item.startDate, onClose, store.workoutStore]
    );

    return (
      <Box
        sx={{
          width: 326,
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.25)",
          backgroundColor: "#FFFFFF",
        }}
      >
        <div
          onClick={(e: any) => {
            console.log(`clicked div`);
            e.stopPropagation();
          }}
        >
          <Controls
            onEdit={onEdit}
            onClose={onClose}
            onDelete={onDelete}
            onMerge={onMerge}
            onUnmerge={onUnmerge}
            hasFact={notEmpty(item.fact)}
            hasPlan={notEmpty(item.plan)}
            hasAnyPlansToday={todayEvents.some((te) => te.id !== item.id)}
          />
          <SBody>
            <WorkoutTitle
              date={item.startDate}
              type={item.type}
              name={item.name}
            />
            <WorkoutDescription content={item.description} />
            <Main item={item} />
            <Indicators data={item.indicators} />
            {item.fact && Object.keys(JSON.parse(item.fact)).length > 0 && (
              <WorkoutFooter id={item.id} />
            )}
          </SBody>
        </div>
        <ConfirmAction
          open={store.workoutStore.workoutMenuState.displayDelete}
          okButtonText="Удалить"
          title={null}
          onClose={() => {
            store.workoutStore.setDeleteDisplayModal(false);
          }}
          onConfirm={() => {
            api.workoutApi.deleteWorkout(item.id || "");
            store.workoutStore.setDeleteDisplayModal(false);
            store.workoutStore.removeFromItems(item.id || "");
            onClose({
              stopPropagation: () => {},
              preventDefault: () => {},
            } as any);
          }}
        >
          Вы точно хотите удалить тренировку?
        </ConfirmAction>
        <ConfirmAction
          open={store.workoutStore.workoutMenuState.displayUnmerge}
          okButtonText="Подтвердить"
          title={null}
          onClose={() => {
            store.workoutStore.setUnmergeDisplayModal(false);
          }}
          onConfirm={async () => {
            await api.workoutApi.unMergeWorkout(item.id || "");
            store.workoutStore.setUnmergeDisplayModal(false);
            await api.workoutApi.loadMyWorkouts(
              dayjs(item.startDate).startOf("day").toDate(),
              dayjs(item.startDate).endOf("day").toDate()
            );
            onClose({
              stopPropagation: () => {},
              preventDefault: () => {},
            } as any);
          }}
        >
          Вы точно хотите отвязать тренировку от плана?
        </ConfirmAction>
        <ConfirmAction
          open={store.workoutStore.workoutMenuState.displayMerge}
          okButtonText="Связать"
          title={"Связать тренировку с планом"}
          hideOkButton={true}
          onClose={() => {
            store.workoutStore.setMergeDisplayModal(false);
          }}
          onConfirm={async () => {}}
        >
          <STrainsToMerge>
            <CurrentWorkout item={item} />
            <STrainCards>
              {todayEvents.map((data: WorkoutData) => {
                return (
                  data.id !== item.id &&
                  notEmpty(data.plan) && (
                    <SRow>
                      <WorkoutCard key={data.id} item={data} onlyPlan={true} />
                      <SActionButton
                        className="yna"
                        onClick={async (e) => closeAfterMerge(data.id || "")}
                      >
                        Связать
                      </SActionButton>
                    </SRow>
                  )
                );
              })}
            </STrainCards>
          </STrainsToMerge>
        </ConfirmAction>
      </Box>
    );
  }
);

const SBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  margin-top: 12px;
  padding: 0 15px;
`;

const STrainsToMerge = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const STrainCards = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SActionButton = styled(Button)`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: var(--white, #fff);
  '&:hover': {
      backgroundColor: #FFC0C0,
      color: '#FFC0C0',
      borderColor: '#FFC0C0',
  },
  border-radius: 6px;
  background: var(--red-red, #ff3147);

  text-align: center;
  /* Txt 14 Medium */
  font-family: Golos Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-transform: none;

  height: 42px;
`;

const SRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

import styled from "@emotion/styled";
import { Popover, Switch, SwitchProps } from "@mui/material";
import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../AppContext";
import { observer } from "mobx-react";
import {useFlag} from "@unleash/proxy-client-react";

interface IProps {
  athleteId: string;
  showMenu: boolean;
}

const AthleteAva: FC<IProps> = ({ athleteId, showMenu }) => {
  const {
    api,
    store: { profileStore },
  } = useAppContext();
  const ref = useRef<HTMLImageElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const externalServicesEnabled = useFlag("external.services");

  const onProfileClick = () => {
    api.profileApi.setIsProfileOpen(true);
  };
  
  const onSettingsClick = () => api.settingsApi.open();
  
  const onLogoutClick = async () => {
    await api.authApi.logout();
    navigate("/login");
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    profileStore.setIsCoach(event.target.checked);
    await api.profileApi.saveIsCoach();
    navigate(0);
  };

  const onSupportClick = () => {
    let a= document.createElement('a');
    a.target= '_blank';
    a.href= 'https://support.yna.run/main/';
    a.click();
  }

  return (
    <SWrapper>
      <SAvatar
        ref={ref}
        onClick={() => setIsOpen((prev) => !prev)}
        src={`${process.env.REACT_APP_BASE_URL}/users/api/v1/users/${athleteId}/avatar`}
        alt=""
        loading="lazy"
      />
      <SPopover
        open={isOpen && showMenu}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SPopoverBody>
          <SItem onClick={onProfileClick}>Мой профиль</SItem>
          {externalServicesEnabled && <SItem onClick={onSettingsClick}>Настройки</SItem>}
          <SItem>
            Я тренер{" "}
            <IOSSwitch checked={profileStore.isCoach} onChange={handleChange} />
          </SItem>
          <SItem onClick={onSupportClick}>Поддержка</SItem>
          <SItem onClick={onLogoutClick}>Выйти</SItem>
        </SPopoverBody>
      </SPopover>
    </SWrapper>
  );
};

export default observer(AthleteAva);

const SWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SAvatar = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 100px;
`;

const SPopover = styled(Popover)``;

const SPopoverBody = styled.div`
  background: #fff;
  padding: 5px;
  box-sizing: border-box;
  user-select: none;
`;

const SItem = styled.div`
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
`;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#44CAAA",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
  },
}));

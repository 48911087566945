import { FC, MouseEvent, useMemo, useRef } from 'react';
import styled from '@emotion/styled'
import { Graph } from './components/Graph/Graph';
import { useAppContext } from "../../../../AppContext";
import { css } from "@emotion/react";
import { useHoverWidth } from "./components/Graph/hooks/useHoverWidth";
import { observer } from "mobx-react";
import { getShortenerStreamData } from "./utils/getShortenerStreamData";


interface IProps {
  positionPercent: number | undefined;
  onMouseMove: (percent: number) => void;
  onMouseOver: VoidFunction;
  onClick: VoidFunction;
  activeStep: number | undefined;
  stepsCount: number;
}

const getTemp = (num: number, suffix?: string) => {
  const minutes = num % 60;
  const hours = Math.floor(num / 60);
  return `${hours}:${minutes > 9 ? minutes : `0${minutes}`}${
    suffix ? " " + suffix : ""
  }`;
};

const mapJumps = (items?: number[]) => {
  if (!items) {
    return [];
  }
  return items.map((item: number, idx: number) => {
    if (!Number.isFinite(item)) {
      return 0;
    }

    return Math.abs(item / items[idx - 1]) > 1.5 ? 0 : item;
  });
};

export const ChartData: FC<IProps> = observer(
  ({
    positionPercent,
    onMouseMove,
    onMouseOver,
    onClick,
    activeStep,
    stepsCount,
  }) => {
    const {
      store: { detailStore },
    } = useAppContext();

    const pace = useMemo(() => {
      const distanceData = detailStore.streamData?.distance?.data;
      const timeData = detailStore.streamData?.time?.data;

      const items = distanceData?.map((currentKm: number, idx: number) => {
        const currentSecond = timeData[idx];
        const prevSecond = timeData[idx - 1];
        const deltaSecond = currentSecond - prevSecond;
        const prevKm = distanceData ? distanceData[idx - 1] : 0;
        const deltaKm = currentKm - prevKm;
        return Math.ceil((deltaSecond / deltaKm) * 1000) || 0;
      });

      const getNoFinitItem = (idx: number): number => {
        let item = items[idx];
        if (!item) {
          return 0;
        }
        if (Number.isFinite(item)) {
          return getNoFinitItem(idx - 1);
        }

        return item;
      };

      let newItems: number[] = [];
      let wasTopJump = false;
      let wasBottomJump = false;

      items?.forEach((item: number, idx: number) => {
        const prevItem = newItems[idx - 1] || items[idx - 1]?.item;
        const topJump = prevItem && Math.abs(item - prevItem) > 100;
        const bottomJump =
          items[idx + 1] && Math.abs(item - items[idx + 1].item) > 100;

        if (!Number.isFinite(item)) {
          newItems.push(0);
          return;
        }
        if (topJump || item > 800) {
          if (wasBottomJump) {
            wasBottomJump = false;
          } else {
            newItems.push(0);
            wasTopJump = true;
            return;
          }
        }
        if (bottomJump) {
          if (wasTopJump) {
            wasTopJump = false;
          } else {
            newItems.push(0);
            wasBottomJump = true;
            return;
          }
        }
        if (Math.abs(item / items[idx - 1]) > 1.5) {
          newItems.push(1);
          return;
        }

        newItems.push(item);
      });

      return mapJumps(newItems);
    }, [detailStore.streamData]);
    const cadence = mapJumps(
      getShortenerStreamData(detailStore.streamData?.cadence?.data)
    ).map((j) => j * 2);
    const height = getShortenerStreamData(
      detailStore.streamData?.altitude?.data
    );
    const rate = getShortenerStreamData(
      detailStore.streamData?.heartRate?.data
    );
    // heartrate

    const { hoverWidth, ref } = useHoverWidth();

    const hoverX = useRef<number>();
    // TODO: GRAPH
    const mouseMoveHandler = (e: MouseEvent) => {
      const target = ref.current;

      if (!target) {
        return;
      }

      // const x = e.nativeEvent.offsetX

      const svgTarget = target.querySelector("svg");

      if (!svgTarget) {
        return;
      }

      const width = svgTarget!.clientWidth;
      const x = e.nativeEvent.offsetX;
      hoverX.current = x;
      onMouseMove(x / width);
    };

    const mouseLeaveHandler = () => {
      hoverX.current = undefined;
      onMouseOver();
    };

    return (
      <SWrapper
        ref={ref}
        positionPercent={positionPercent}
        hoverWidth={hoverWidth || 100}
        activeStep={activeStep}
        stepsCount={stepsCount}
        onMouseMove={mouseMoveHandler}
        onMouseLeave={mouseLeaveHandler}
        onClick={onClick}
      >
        {!!pace && (
          <SSection>
            <SLabel>Темп</SLabel>
            <SGraph
              min={getTemp(Math.min(...pace))}
              max={getTemp(Math.max(...pace))}
            >
              <Graph
                reverse={true}
                data={pace?.map((d: number) => Math.ceil(d))}
                getPrefix={(index: number) => getTemp(pace[index], "мин/км")}
                hoverX={hoverX}
                suffix={"мин/км"}
                positionPercent={positionPercent}
                multiplier={1}
              />
            </SGraph>
          </SSection>
        )}
        {!!cadence && (
          <SSection>
            <SLabel>Каденс</SLabel>
            <SGraph
              min={String(Math.min(...cadence))}
              max={String(Math.max(...cadence))}
            >
              <Graph
                data={cadence}
                hoverX={hoverX}
                suffix={" ш/мин"}
                positionPercent={positionPercent}
              />
            </SGraph>
          </SSection>
        )}
        {!!height && (
          <SSection>
            <SLabel>Высота</SLabel>
            <SGraph
              min={String(Math.min(...height))}
              max={String(Math.max(...height))}
            >
              <Graph
                data={height}
                hoverX={hoverX}
                suffix={" м"}
                isRoundLine={true}
                positionPercent={positionPercent}
              />
            </SGraph>
          </SSection>
        )}
        {!!rate && (
          <SSection>
            <SLabel>Пульс</SLabel>
            <SGraph
              min={String(Math.min(...rate))}
              max={String(Math.max(...rate))}
            >
              <Graph
                data={rate}
                hoverX={hoverX}
                suffix={"уд/мин"}
                positionPercent={positionPercent}
              />
            </SGraph>
          </SSection>
        )}
      </SWrapper>
    );
  }
);

const SWrapper = styled.div<{
  positionPercent: number | undefined;
  hoverWidth: number;
  activeStep: number | undefined;
  stepsCount: number;
}>`
	padding: 16px;
  position: relative;
	background: #fff;
	width: 100%;
	border: 1px solid #CDD9EA;
	box-sizing: border-box;
	font-weight: 600;
	font-size: 12px;
	text-transform: uppercase;
  overflow: hidden;

`
// ${getGreenHoverCss() as any}

const SLabel = styled.span`
	font-size: 12px;
	user-select: none;
  z-index: 1;
  position: absolute;
`

const SSection = styled.div`
	box-sizing: border-box;
	border-bottom: 1px solid #CDD9EA;
`

const SGraph = styled.div<{ min: string; max: string }>`
  padding-top: 10px;
	position: relative;
	height: 100%;

  ${({ min }) => min !== undefined && css`
    &::after {
      content: '${min}';
      position: absolute;
      color: #252B4B;
      background: #EEF5FB;
      border-radius: 5px;
      padding: 3px;
      box-sizing: border-box;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  `}
  ${({ max }) => max !== undefined && css`
    &::before {
      content: '${max}';
      color: #252B4B;
      background: #EEF5FB;
      position: absolute;
      top: 10px;
      left: 0;
      border-radius: 5px;
      padding: 3px;
      box-sizing: border-box;
      z-index: 1;
    }
  `}
`

import dayjs from "dayjs";
import { WorkoutData } from "../../../stores/WorkoutStore";
import IconByType from "../../common/IconByType";
import { WorkoutType } from "../types";
import duration from "dayjs/plugin/duration";
import { fromSeconds, getItemDimensions } from "../calendar/utils";
dayjs.extend(duration);

export default ({
  type,
  items,
}: {
  type: WorkoutType;
  items: WorkoutData[];
}) => {
  if (WorkoutType[type] === "running") {
  }
  const filteredItems = items.filter((x: WorkoutData) => {
    return x.type === WorkoutType[type];
  });

  if (filteredItems.length === 0) return null;
  var distance = 0;
  var planDistance = 0;
  var totalTime = 0;
  var planTotalTime: number = 0;
  var totalPace: number = 0;

  for (const idx in filteredItems) {
    var data = JSON.parse(filteredItems[idx].fact) ?? {};
    var plan = JSON.parse(filteredItems[idx].plan) ?? {};
    distance += Number(parseFloat(data["distance"]) || 0);
    planDistance += Number(parseFloat(plan["distance"]) || 0);

    if (data["time"]) {
      totalTime += parseInt(data["time"]);
    }

    if (plan["time"]) {
      planTotalTime += parseInt(plan["time"]);
    }

    if (data["pace"]) {
      totalPace += parseInt(data["pace"]);
    }
  }

  const average_pace = dayjs.duration(0).add({
    seconds: Math.ceil(totalTime / distance),
  });

  const dimensions = getItemDimensions(WorkoutType[type]);

  return (
    <div style={{ paddingBottom: "12px" }}>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <div style={{ width: "24px", height: "24px", paddingRight: "8px" }}>
          <IconByType type={type} />
        </div>

        <div style={{ flexGrow: 1 }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#97A4B7",
              padding: "3px 0",
              borderBottom: "1px solid #CDD9EA",
              fontSize: 12,
            }}
          >
            <div style={{ minWidth: "58px", flexGrow: 1 }}>
              {planDistance ? `${parseFloat(planDistance.toFixed(1))} км` : "-"}
            </div>
            <div>
              {fromSeconds(planTotalTime).utc(false).format("HH:mm:ss")}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#1A222D",
              padding: "3px 0",
              fontSize: 12,
            }}
          >
            <div style={{ minWidth: "58px", flexGrow: 1 }}>
              {distance ? `${parseFloat(distance.toFixed(1))} км` : "-"}
            </div>
            <div>{fromSeconds(totalTime).utc(false).format("HH:mm:ss")}</div>
          </div>
          <div>
            {(type === WorkoutType.running ||
              type === WorkoutType.swimming ||
              type === WorkoutType.cycling) &&
              totalPace > 0 && (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: "4px 0",
                    color: "#1A222D",
                    fontSize: 12,
                  }}
                >
                  <div>
                    {average_pace.format("mm:ss")}
                    {dimensions.pace}
                  </div>
                  <div></div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};


import {Grid} from "@mui/material";
import {Garmin, Google, Strava} from "../common/logo";
import ExternalLoginButton from "./ExternalLoginButton";
import React from "react";

export interface ExternalLoginButtonsProps {
  classes : Record<string, string>
}

export const ExternalLoginButtons = (props : ExternalLoginButtonsProps) => 
<Grid container className={props.classes.socialButtonsWrapper} direction="row" justifyContent="space-evenly" alignItems="center">
  {/*<Grid item>*/}
  {/*  <ExternalLoginButton provider='Garmin'>*/}
  {/*    <Garmin />*/}
  {/*  </ExternalLoginButton>*/}
  {/*</Grid> */}
  <Grid item>
    <ExternalLoginButton provider='Strava'>
      <Strava />
    </ExternalLoginButton>
  </Grid>
  <Grid item>
    <ExternalLoginButton provider='Google'>
      <Google />
    </ExternalLoginButton>
  </Grid>
</Grid>

import styled from '@emotion/styled';
import { MouseEvent, Dispatch, SetStateAction } from "react";
import { useAppContext } from "../../../../../AppContext";
import { WorkoutData } from "../../../../../stores/WorkoutStore";
import { Droppable } from "../../../../common/Droppable/Droppable";
import { WorkoutItem } from "../../../types";
import { WorkoutCard } from "../WorkoutCard/WorkoutCard";

const Today = ({
  item,
  activeCardId,
  onSetActiveCardId,
  id,
  onRemoveActiveCardId,
}: {
  id: string;
  item: WorkoutItem;
  activeCardId: string | undefined;
  onSetActiveCardId: Dispatch<SetStateAction<string | undefined>>
  onRemoveActiveCardId: VoidFunction,
}) => {
  const { store } = useAppContext();
  const events = store.workoutStore.items;
  const todayEvents = events.filter((x) => {
    return item.date.isSame(x.startDate, "day");
  });

  if (!events) {
    return null;
  }
  const confirmDeleteHandle = async () => {
    store.workoutStore.setDeleteDisplayModal(true);
  };

  const confirmUnmergeHandle = async () => {
    store.workoutStore.setUnmergeDisplayModal(true);
  };

  const confirmMergeHandle = async () => {
    store.workoutStore.setMergeDisplayModal(true);
  };

  const editHandle = (e: any, item: WorkoutData) => {
    store.workoutStore.editWorkout(item);
    onRemoveActiveCardId();
    store.coachStore.setWorkoutModalOpen(true);
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>, id: string) => {
    e.stopPropagation();
    if (!!activeCardId) {
      return;
    }

    onSetActiveCardId(id);
  };

  const handleClose = () => {
    onRemoveActiveCardId();
  };

  return (
    <Droppable id={id}>
      <SWrapper>
        <SToday id="today">
          <span style={{ alignItems: "center", textAlign: "center" }}>
            {item.day}
          </span>
        </SToday>
        {todayEvents.map((item: WorkoutData) => (
          <WorkoutCard
            key={item.id}
            isActive={activeCardId === item.id}
            onClick={(e) => handleClick(e, item.id || "")}
            onClose={handleClose}
            onEdit={editHandle}
            onDelete={() => confirmDeleteHandle()}
            onUnmerge={() => confirmUnmergeHandle()}
            onMerge={() => confirmMergeHandle()}
            workout={item}
            todayEvents={todayEvents}
          />
        ))}
      </SWrapper>
    </Droppable>
  );
};

export default Today;

const SWrapper = styled.div`
  display: flex;    
  gap: 8px 0;
  flex-wrap: wrap;
`

const SToday = styled.div`
  display: flex;    
  margin-bottom: 6px;
  max-width: 32px;
  min-height: 32px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background-color: #FF3147;
  border-radius: 300px;
  justify-content: center;
  align-items: center;
  flex: 1;
  
  & span {
    font-family: Roboto Mono, sans-serif;
  }

`

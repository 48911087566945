import { createTheme } from "@mui/material";

export const THEME_LIGHT = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#1976d2",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      default: "#fff",
      paper: "#f5f5f5",
    },
  },
  components: {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          "& .MuiPickersDay-root": {
            "&.Mui-selected": {
              backgroundColor: "#44caaa!important",
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.yna": {
            "& .MuiOutlinedInput-root:hover fieldset": {
              border: "2px solid #44CAAA",
            },
            "& .MuiOutlinedInput-root.Mui-focused fieldset": {
              border: "2px solid #44CAAA",
            },
            "& .MuiInputLabel-outlined.Mui-focused": {
              color: "#44CAAA",
            },
            "& .MuiInputLabel-outlined.Mui-error": {
              color: "#FF3147",
            },

            "& .Mui-error": {
              color: "#FF3147",
            },
            "& .Mui-error fieldset": {
              border: "2px solid #FF3147",
            },
            "& .Mui-error:hover fieldset": {
              border: "2px solid #FF3147",
            },
            "& .Mui-error.Mui-focused fieldset": {
              border: "2px solid #FF3147",
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.yna": {
            "&.Mui-error": {
              color: "#FF3147",
              paddingBottom: "16px",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.yna.MuiButton-root": {
            boxShadow: "none",
            textTransform: "none",
            fontSize: 16,
            padding: "19px 12px",
            border: "1px solid",
            lineHeight: 1.5,
            backgroundColor: "#FF3147",
            borderColor: "#FF3147",
            "&:hover": {
              backgroundColor: "#FFC0C0",
              boxShadow: "none",
              borderColor: "#FFC0C0",
            },
            "&:active": {
              boxShadow: "none",
              backgroundColor: "#FF3147",
              borderColor: "#FF3147",
            },
            "&:focus": {
              boxShadow: "none",
            },
            "&:disabled": {
              backgroundColor: "#FFC0C0",
              borderColor: "#FFC0C0",
              color: "#fff",
              boxShadow: "none",
            },
          },
        },
      },
    },
  } as any,
});





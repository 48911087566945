import { Route, Routes } from "react-router-dom";
import RequireAuth from "../utils/RequireAuth";
import LoginPage from "../pages/AuthPage";
import DashboardPage from "../pages/DashboardPage";
import {
  LoginForm,
  RegisterForm,
  ResetPassword,
  ChangePasswordForm,
  AddExternalEmailForm,
} from "../pages/AuthPage/components";
import AddMeToCoach from "../pages/AddMeToCoach";
import TrainAnalysis from "../pages/TrainAnalysis";
import { MainPage } from "../pages/MainPage/MainPage";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/login" element={<LoginPage />}>
        <Route index element={<LoginForm />} />
        <Route path="register" element={<RegisterForm />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="change-password" element={<ChangePasswordForm />} />
        <Route path="link-email" element={<AddExternalEmailForm />} />
      </Route>
      <Route
        path="/analysis/:id"
        element={
          <RequireAuth>
            <TrainAnalysis />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/:athleteId"
        element={
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        }
      />
      <Route
        path="addmetocoach/:linkId"
        element={
          <RequireAuth>
            <AddMeToCoach />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default App;

// import { ReactComponent as NotificationIcon } from './img/notification.svg'
import styled from '@emotion/styled'

export default () => {
  return (
    <SWrapper>
      {/* <NotificationIcon /> */}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  cursor: pointer;
  height: 100%;
`

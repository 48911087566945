import { action, makeAutoObservable, observable } from "mobx";

import polyline from "@mapbox/polyline";
import Store from "./Store";
import { AnalysisDisplayType } from "../pages/TrainAnalysis/types";
import { DisplayModeTime } from "../pages/TrainAnalysis/DisplayModeTime";


type WorkoutDetailType = {
  id: string;
  coordinates: any;
  polyline: string;
};

type WorkoutDetailItemType = {
  idx: number;
  distance: number;
  segmentDistance: number;
  distanceExact: number;
  pace: number;
  cadence: number;
  cadenceMax: number;
  time: number;
  segmentTime: number;
  mapPoint: any;
  heartRate: number;
  heartRateMax: number;
  up: number;
  down: number;
};

export type WorkoutDetailDataType = {
  athleteName: string;
  athleteId: string;
  description: string;
  fact: string;
  id: string;
  indicators: string;
  name: string;
  plan: string;
  startDate: Date;
  target: boolean;
  type: string;
  link: string;
  createdEmpty: boolean;
  utcOffset: number;
};

type MinMax = {
  min: number;
  max: number;
  idx: number;
  previousIdx: number;
  pointNumber: number;
};

export default class WorkoutDetailStore {
  workout: WorkoutDetailType | undefined;
  streamData: any; //json
  workoutDetails: WorkoutDetailItemType[] | undefined;
  analysisDisplayType: AnalysisDisplayType = AnalysisDisplayType.Distance;
  currentPoint: any;
  currentIdx: number | undefined;
  isMapExpanded: boolean | undefined;
  mapScale: number = 16;
  workoutData: WorkoutDetailDataType | undefined;
  modeTime: DisplayModeTime = DisplayModeTime.Summary;

  constructor(private store: Store) {
    makeAutoObservable(this, {
      setWorkout: action.bound,
      workout: observable,
      workoutDetails: observable,
      currentPoint: observable,
      setCurrentIdx: action.bound,
      isMapExpanded: observable,
      setMapExpanded: action.bound,
      setWorkoutData: action.bound,
      mapScale: observable,
      scalerAdd: action.bound,
      scalerSubtract: action.bound,
      analysisDisplayType: observable,
      setAnalysisDisplayType: action.bound,
      setStreamData: action.bound,
      changeModeTime: action.bound,
      modeTime: observable,
    });
  }

  setMapExpanded(expanded: boolean) {
    this.isMapExpanded = expanded;
  }

  scalerAdd() {
    this.mapScale = Math.min(18, this.mapScale + 1);
    console.log(this.mapScale);
  }

  scalerSubtract() {
    this.mapScale = Math.max(5, this.mapScale - 1);
  }

  setWorkoutData(workoutData: WorkoutDetailDataType) {
    this.workoutData = workoutData;
  }
  setWorkout(item: WorkoutDetailType) {
    const coordinates = item.polyline ? polyline.decode(item.polyline) : null;
    this.workout = {
      id: item.id,
      coordinates: coordinates,
      polyline: item.polyline,
    };
  }

  setStreamData(item: any) {
    this.streamData = item;
    this.streamData["sma_altitude"] = {
      data: createSMALine(this.streamData["altitude"]["data"]),
    };
    this.workoutDetails = prepareDetailsData(
      this.streamData,
      this.analysisDisplayType
    );
  }
  setCurrentIdx(idx: number) {
    if (!this.workoutDetails) return;
    this.currentIdx = idx;
    this.currentPoint = this.workoutDetails[idx].mapPoint;
  }

  setAnalysisDisplayType(type: AnalysisDisplayType) {
    this.analysisDisplayType = type;
    if (type === AnalysisDisplayType.Distance) {
      this.modeTime = DisplayModeTime.Summary;
    } else {
      this.modeTime = DisplayModeTime.Segment;
    }
  }

  changeModeTime() {
    if (this.modeTime === DisplayModeTime.Summary) {
      this.modeTime = DisplayModeTime.Segment;
    } else {
      this.modeTime = DisplayModeTime.Summary;
    }
  }
}

function preparePoints(points: any[]): MinMax[] {
  if (!points) {
    return [];
  }

  let minDistance = 0;
  let maxDistance = 1;
  let distances: MinMax[] = [];
  let pointNumber: number = 0;
  for (const idx in points) {
    const currentDistance = Math.ceil(points[idx] / 1000);

    if (currentDistance > maxDistance) {
      distances[distances.length] = {
        min: minDistance,
        max: maxDistance,
        idx: parseInt(idx),
        previousIdx: distances[distances.length - 1]
          ? distances[distances.length - 1].idx
          : 0,
        pointNumber: pointNumber + 1,
      };
      minDistance = minDistance + 1;
      maxDistance = minDistance + 1;
      pointNumber++;
    }
  }

  distances[distances.length] = {
    min: minDistance,
    max: maxDistance,
    idx: points.length - 1,
    previousIdx: distances[distances.length - 1]
      ? distances[distances.length - 1].idx
      : 0,
    pointNumber: distances.length + 1,
  };

  return distances;
}

function getIndexByTime(timeData: number[], fullElapsed: number): number {
  console.log(timeData);
  for (const idx in timeData) {
    if (timeData[idx] >= fullElapsed) {
      return parseInt(idx);
    }
  }
  return 0;
}

function preparePointsBySegments(points: any[], indexes: any[]) {
  let distances: MinMax[] = [];
  let pointNumber: number = 0;
  let indexNo = 0;
  let fullElapsed = 0;
  for (const idx in indexes) {
    fullElapsed += indexes[idx].elapsedTime;
    const indexByTime = getIndexByTime(points, fullElapsed);

    const nextIdx = indexByTime;
    if (indexNo <= nextIdx) {
      distances[distances.length] = {
        min: parseInt(idx),
        max: parseInt(idx) + 1,
        previousIdx: indexNo,
        idx: nextIdx,
        pointNumber: pointNumber + 1,
      };
    }
    indexNo = nextIdx;

    pointNumber++;
  }
  return distances;
}

function getDataItemByIndex(streamData: any, type: string, idx: number) {
  if (
    !!streamData &&
    streamData[type] != null &&
    streamData[type]["data"] != null
    // streamData[type]["data"].length > 0
  ) {
    return streamData[type]["data"][idx] || 0;
  }
  return 0;
}

function countSegmentData(
  point: MinMax,
  streamData: any
): WorkoutDetailItemType {
  let idx = point.previousIdx;
  let segmentLength = 0;
  let heartRateSum = 0;
  let heartRateMax = 0;
  let cadenceSum = 0;
  let cadenceMax = 0;

  let segmentAltitude = getDataItemByIndex(streamData, "altitude", 0);
  let segmentMaxAltitude = 0;
  let segmentMinAltitude = segmentAltitude;

  while (idx <= point.idx) {
    segmentLength++;
    heartRateSum +=
      streamData["heartRate"] && streamData["heartRate"]["data"][idx];
    heartRateMax =
      streamData["heartRate"] &&
      Math.max(heartRateMax, streamData["heartRate"]["data"][idx]);
    cadenceSum += getDataItemByIndex(streamData, "cadence", idx);

    cadenceMax = Math.max(
      cadenceMax,
      getDataItemByIndex(streamData, "cadence", idx)
    );

    segmentMaxAltitude = Math.max(
      segmentMaxAltitude,
      getDataItemByIndex(streamData, "altitude", idx)
    );

    segmentMinAltitude = Math.min(
      segmentMinAltitude,
      getDataItemByIndex(streamData, "altitude", idx)
    );
    idx++;
  }

  const segmentDistance = countSegmentDistance(
    streamData["distance"]["data"],
    streamData["moving"]["data"],
    point.previousIdx,
    point.idx
  );

  const segmentTime = countSegmentTime(
    streamData["time"]["data"],
    streamData["moving"]["data"],
    point.previousIdx,
    point.idx
  );

  const upDistance = countUpDistance(
    streamData["sma_altitude"]["data"],
    streamData["moving"]["data"],
    point.previousIdx,
    point.idx
  );
  const downDistance = countDownDistance(
    streamData["sma_altitude"]["data"],
    streamData["moving"]["data"],
    point.previousIdx,
    point.idx
  );

  return {
    idx: point.pointNumber,
    distance: Math.ceil(
      (streamData["distance"]["data"][point.previousIdx] + 1) / 1000
    ),
    segmentDistance: segmentDistance,
    distanceExact: parseFloat(
      (streamData["distance"]["data"][point.idx] / 1000).toFixed(2)
    ),
    mapPoint: streamData["latlng"] && streamData["latlng"]["data"][point.idx],
    heartRate:
      streamData["heartRate"] && Math.floor(heartRateSum / segmentLength),
    heartRateMax: heartRateMax,
    cadence: Math.floor(cadenceSum / segmentLength) * 2,
    cadenceMax: cadenceMax * 2,
    pace: segmentTime / segmentDistance,
    time: streamData["time"]["data"][point.idx],
    segmentTime: segmentTime,
    // up: upDistance > downDistance ? upDistance - downDistance : 0,
    // down: downDistance > upDistance ? downDistance - upDistance : 0,
    up: upDistance,
    down: downDistance,
  };
}

function countSummaryData(streamData: any): WorkoutDetailItemType {
  if (!streamData) return {} as WorkoutDetailItemType;

  let idx = 0;
  let heartRateSum = 0;
  let heartRateMax = 0;
  let cadenceSum = 0;
  let cadenceMax = 0;

  let segmentAltitude = getDataItemByIndex(streamData, "altitude", 0);
  let segmentMaxAltitude = 0;
  let segmentMinAltitude = segmentAltitude;
  const arrLength =
    (streamData &&
      streamData["distance"] &&
      streamData["distance"]["data"].length) ||
    0;

  while (idx <= arrLength) {
    heartRateSum +=
      streamData["heartRate"] &&
      streamData["heartRate"]["data"] &&
      parseInt(streamData["heartRate"]["data"][idx] || 0);

    heartRateMax = Math.max(
      heartRateMax,
      streamData["heartRate"] &&
        streamData["heartRate"]["data"] &&
        parseInt(streamData["heartRate"]["data"][idx] || 0)
    );
    cadenceSum += getDataItemByIndex(streamData, "cadence", idx);

    cadenceMax = Math.max(
      cadenceMax,
      getDataItemByIndex(streamData, "cadence", idx)
    );

    segmentMaxAltitude = Math.max(
      segmentMaxAltitude,
      getDataItemByIndex(streamData, "altitude", idx)
    );

    segmentMinAltitude = Math.min(
      segmentMinAltitude,
      getDataItemByIndex(streamData, "altitude", idx)
    );
    idx++;
  }

  return {
    idx: Math.max(
      0,
      streamData["distance"] ? Math.ceil(streamData["distance"]["data"][arrLength - 1] / 1000) - 1 : 0
    ),
    distance: streamData["distance"] ? Math.ceil(
      (streamData["distance"]["data"][arrLength - 1] + 1) / 1000
    ) : 0,
    segmentDistance: 0,
    segmentTime: 0,
    distanceExact: streamData["distance"] ? parseFloat(
      (streamData["distance"]["data"][arrLength - 1] / 1000).toFixed(2)
    ) : 0,
    mapPoint: streamData["latlng"] && streamData["latlng"]["data"][0],
    heartRate: Math.floor(heartRateSum / arrLength),
    heartRateMax: heartRateMax,
    cadence: arrLength > 0 ? Math.floor(cadenceSum / arrLength) * 2 : 0,
    cadenceMax: cadenceMax * 2,
    pace: streamData["time"]["data"][arrLength - 1],
    time: streamData["time"]["data"][arrLength - 1],
    up: 0,
    down: 0,
  };
}

function prepareDetailsData(
  streamData: any,
  type: AnalysisDisplayType
): WorkoutDetailItemType[] {
  if (!streamData) return [];
  const points =
    type === AnalysisDisplayType.Distance
      ? preparePoints(streamData["distance"]["data"])
      : preparePointsBySegments(streamData["time"]["data"], streamData["laps"]);
  if (points.length < 2) {
    console.warn("слишком мало точек");
  }

  const segments = points.map((value) => countSegmentData(value, streamData));
  segments[segments.length - 1].distance =
    segments[segments.length - 1].distanceExact;
  return segments;
}

function countSegmentDistance(
  distances: [],
  movingData: [],
  firstIdx: number,
  lastIdx: number
) {
  let distance: number = 0;
  while (lastIdx > firstIdx) {
    if (movingData[firstIdx + 1] && movingData[firstIdx]) {
      distance += distances[firstIdx + 1] - distances[firstIdx];
    }
    firstIdx++;
  }
  return Math.floor(distance) / 1000;
}

function countSegmentTime(
  items: [],
  movingData: [],
  firstIdx: number,
  lastIdx: number
) {
  let summary: number = 0;
  while (lastIdx > firstIdx) {
    if (movingData[firstIdx + 1] && movingData[firstIdx]) {
      summary += items[firstIdx + 1] - items[firstIdx];
    }
    firstIdx++;
  }
  return summary;
}

function countUpDistance(
  items: [],
  movingData: [],
  firstIdx: number,
  lastIdx: number
) {
  let summary: number = 0;

  while (lastIdx > firstIdx) {
    let previousValue = items[firstIdx];
    let nextValue = items[firstIdx + 1];
    if (!movingData[firstIdx]) {
      nextValue = previousValue;
    }
    // идем по порядку
    // отнимаем от текущего - прошлое
    //1:10
    //2:12
    // == 2

    summary += Math.max(0, nextValue - previousValue);
    // if (summary < 0) summary = 0;
    firstIdx++;
  }

  return Math.round(summary);
}

function countDownDistance(
  items: [],
  movingData: [],
  firstIdx: number,
  lastIdx: number
) {
  let summary: number = 0;

  while (lastIdx > firstIdx) {
    let previousValue = items[firstIdx];
    let nextValue = items[firstIdx + 1];

    if (!movingData[firstIdx]) {
      nextValue = previousValue;
    }
    // идем по порядку
    // отнимаем от текущего - прошлое
    //1:10
    //2:12
    // == 0
    //1:12
    //2:10
    //==2

    summary += Math.max(0, previousValue - nextValue);
    // if (summary < 0) summary = 0;

    firstIdx++;
  }
  return Math.round(summary);
}

export type { WorkoutDetailItemType };
export { countSummaryData, countSegmentData };
function createSMALine(items: number[]): number[] {
  const smaWidth: number = 60;
  let outItems: number[] = [];
  for (let idx = 0; items.length > idx; idx++) {
    let sum = 0;
    const minIdx = idx - smaWidth / 2;
    const maxIdx = idx + smaWidth / 2;
    for (let j = minIdx; j < maxIdx; j++) {
      let innerIdx = j;
      if (innerIdx < 0) {
        innerIdx = 0;
      }

      if (innerIdx > items.length - 1) {
        innerIdx = items.length - 1;
      }
      sum += items[innerIdx];
    }
    outItems[idx] = sum / smaWidth;
  }
  return outItems;
}


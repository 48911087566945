import { useMemo, useRef } from "react";
import { useAppContext } from "../../../../../../../AppContext";

export const useHoverWidth = () => {
	const {
		store: { detailStore },
	} = useAppContext();
	const ref = useRef<HTMLDivElement>(null);

	const hoverWidth = useMemo(() => {
		const wrapper = ref.current?.querySelector('div')
    let steps = detailStore.workoutDetails?.map((w: any) => w.distance)

		if (!steps) {
			return;
		}

    const end = steps[steps.length - 1];
    const isLastStepLowThanOneHalfStep = end - steps[steps.length - 2] < 1;

    if (isLastStepLowThanOneHalfStep) {
      steps = steps.slice(0, steps.length - 2)
    }

		if (!wrapper || !steps?.length) {
			return;
		}

		return (wrapper.clientWidth - 32) / (steps.length - 1)
	}, [detailStore.workoutDetails])

	return {
		ref,
		hoverWidth,
	}
}

import { Typography, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { ChangeEvent } from "react";

export default ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: any) => void;
}) => {
  return (
    <SWrapper>
      <STitle>Имя и фамилия</STitle>
      <STextDiv>
        <STextField
          value={value}
          onChange={(
            evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            console.log(evt.target.value);
            onChange(evt.target.value);
          }}
          label=""
          size="small"
          fullWidth
          variant="outlined"
        />
      </STextDiv>
    </SWrapper>
  );
};

const STextDiv = styled.div`
  display: flex;
  height: 36px;
  padding: 12px 0 0 0;
  align-items: center;
  gap: 15px;
  align-self: stretch;
`;

const STextField = styled(TextField)`
  border-radius: 4px;
  background: var(--G2, #eef5fb);
`;

const STitle = styled(Typography)`
  color: var(--Black, #1a222d);
  font-family: Golos Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
`;

const SWrapper = styled.div`
  width: 182px;
`;
import { IconButton } from "@mui/material";
import { ReactComponent as TrainDataIcon } from "../../assets/icons/train_data.svg";
import { ReactComponent as ChatIcon } from "../../assets/icons/chat.svg";

export default () => {
  return (
    <div
      style={{
        width: "40px",
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid #CDD9EA",
      }}
    >
      <IconButton style={{ backgroundColor: "#C8FCE0", borderRadius: 0 }}>
        <TrainDataIcon />
      </IconButton>
      {/* <IconButton >
        <ChatIcon />
      </IconButton> */}
    </div>
  );
};

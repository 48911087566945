import * as Yup from 'yup';

export const ResetPasswordSchema = (t: any) => Yup.object().shape({
  email: Yup.string()
      .email(t('noCorrectEmail'))
      .required(t('emailRequired')),
  pwd: Yup.string()
      .required(t('passwordRequired')),
  confirmPwd: Yup.string()
      .oneOf([Yup.ref('pwd')], t('passwordsCompability'))
      .required(t('passwordsCompabilityRequired')),
});

import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";

const PasswordTextField = (props: any) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const getFieldType = (): string => (showPassword ? "text" : "password");
  const getIcon = (): JSX.Element =>
    showPassword ? <VisibilityOff /> : <Visibility />;
  const onShowPasswordHandler = () => setShowPassword(!showPassword);

  return (
    <TextField
      {...props}
      type={getFieldType()}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onShowPasswordHandler} edge="end">
              {getIcon()}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordTextField;

import { now } from "../../../../utils/now";
import { ReactComponent as CaretRightIcon } from '../img/caret-right.svg';
import styled from '@emotion/styled';

const renderWeekDays = () => {
  const dateFormat = "dd";
  const days = [];
  for (let i = 0; i < 7; i++) {
    days.push(
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: "32px",
          border: "1px solid #CDD9EA",

          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          textAlign: "center",
          fontSize: '14px',
          fontWeight: '500',
          flexGrow: 1,
          outline: 'none',
          cursor: 'default',
          userSelect: 'none',
        }}
        key={now.weekday(i).toISOString()}
      >
        <div style={{ 
          textTransform: 'capitalize',
          boxSizing: 'border-box'
        }}>
          {now.weekday(i).format(dateFormat)}
        </div>
      </div>
    );
  }

  days.push(
    <div
      style={{
        display: 'flex',
        minWidth: '186px',
        width: '100%',
        height: "32px",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fontSize: '14px',
        fontWeight: '500',
        flexGrow: 1,
        outline: 'none',
        cursor: 'default',
        userSelect: 'none',
        border: '1px solid #CDD9EA',
      }}
    >
      <SResult>
        <SContent>
          <CaretRightIcon width={16} height={16} />
          Итого
        </SContent>
      </SResult>
    </div>
  );
  return days;
};

export default renderWeekDays;

const SResult = styled.div`
  display: block;
  flex-grow: 1;
  text-align: left;
  position: relative;
`

const SContent = styled.div`
  display: flex;
  position: absolute;
  top:0;
  bottom:0;
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
  gap: 0 2px;
  left: 5px;
`

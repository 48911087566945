import styled from "@emotion/styled";
import React from "react";

export type ButtonsWrapperProps = {
    children?: React.ReactNode;
};

export default (props: ButtonsWrapperProps) => <SButtonsWrapper>
    {props.children}
</SButtonsWrapper>

const SButtonsWrapper = styled.div`
    padding: 8px 16px;
`;
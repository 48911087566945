import { ReactComponent as RunningIcon } from "../../assets/icons/running.svg";
import { ReactComponent as VeloIcon } from "../../assets/icons/velo.svg";
import { ReactComponent as SwimIcon } from "../../assets/icons/swim.svg";
import { ReactComponent as FitnessIcon } from "../../assets/icons/fitness.svg";
import { WorkoutType } from "../scheduler/types";

export default (props: { type: WorkoutType }) => {
  switch (props.type) {
    case WorkoutType.running:
      return <RunningIcon color='#1A222D' />;
    case WorkoutType.cycling:
      return <VeloIcon color='#1A222D' />;
    case WorkoutType.swimming:
      return <SwimIcon color='#1A222D' />;
    case WorkoutType.powerTraining:
      return <FitnessIcon color='#1A222D' />;
    default:
      return null;
  }
};

import { ReactComponent as PointIcon } from "../../../assets/icons/point.svg";
import { ReactComponent as ClockIcon } from "../../../assets/icons/clock.svg";
import { ReactComponent as PulseIcon } from "../../../assets/icons/heartrate.svg";
import { ReactComponent as PaceIcon } from "../../../assets/icons/pace.svg";
import DataItem from "../../common/workout/PlanDataItem";
import FactDataItem from "./FactDataItem";
import { fromSeconds } from "../../scheduler/calendar/utils";

export default ({
  data,
  factData,
  indicators,
  dimensions,
}: {
  data: any;
  factData: any;
  indicators: any;
  dimensions: any;
}) => {
  const hasTime = data["time"] || factData["time"];
  const hasDistance = data["distance"] || factData["distance"];
  const hasPace = data["pace"] || factData["pace"];
  const hasData = hasTime || hasDistance || hasPace;
  const minPulse = indicators.heart_rate?.min.toFixed();
  const maxPulse = indicators.heart_rate?.max.toFixed();
  const hasPulse = !!indicators.heart_rate?.min || !!indicators.heart_rate?.max;

  return (
    <>
      <div
        style={{
          display: hasData ? "flex" : "none",
          fontFamily: "Golos Text",
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "130%",
          letterSpacing: "0.5px",
          textTransform: "uppercase",
          color: "#1A222D",
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-start",
          padding: "8px 0",
          boxSizing: "border-box",
        }}
      >
        <div style={{ width: "50%" }}>План</div>
        <div style={{ width: "50%" }}>Факт</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "12px 0",
            flex: 1,
            flexDirection: "column",
            paddingBottom: "",
            width: 139,
          }}
        >
          {!!hasDistance && (
            <DataItem
              Icon={PointIcon}
              data={data["distance"]}
              units="км"
              title="Дистанция"
            />
          )}

          {!!hasTime && (
            <DataItem
              Icon={ClockIcon}
              data={
                data["time"] && fromSeconds(data["time"]).format("HH:mm:ss")
              }
              units=""
              title="Время"
            />
          )}

          {!!hasPace && (
            <DataItem
              Icon={PaceIcon}
              data={data["pace"] && fromSeconds(data["pace"]).format("mm:ss")}
              units={""}
              title="Темп"
            />
          )}
          {!!hasPulse && (
            <DataItem
              Icon={PulseIcon}
              data={data["pulse"]}
              units="уд/мин"
              title="Пульс"
            />
          )}

          <div></div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: "12px 0",
            flexDirection: "column",
            paddingBottom: "",
            width: 139,
          }}
        >
          {!!hasDistance && (
            <FactDataItem
              data={factData["distance"]}
              units="км"
              title="Дистанция"
            />
          )}

          {!!hasTime && (
            <FactDataItem
              data={
                factData["time"] &&
                fromSeconds(factData["time"]).format("HH:mm:ss")
              }
              units=""
              title="Время"
            />
          )}
          {!!hasPace && (
            <FactDataItem
              data={
                factData["pace"] &&
                fromSeconds(factData["pace"]).format("mm:ss")
              }
              units={dimensions.pace}
              title="Темп"
            />
          )}
          {!!hasPulse && (
            <FactDataItem
              data={minPulse}
              max={maxPulse}
              units="уд/мин"
              title="Пульс"
            />
          )}
        </div>
      </div>
    </>
  );
};

import { ReactComponent as ScalerPlus } from "../../assets/icons/scaler_plus.svg";
import { ReactComponent as ScalerMinus } from "../../assets/icons/scaler_minus.svg";
import { Button } from "@mui/material";
import { useCallback } from "react";
import { useAppContext } from "../../AppContext";
import styled from "@emotion/styled";

export default () => {
  const {
    store: { detailStore },
  } = useAppContext();
  const scalerAdd = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      detailStore.scalerAdd();
    },
    [detailStore]
  );

  const scalerSubtract = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      detailStore.scalerSubtract();
    },
    [detailStore]
  );

  return (
    <SDiv>
      <SButton onClick={scalerAdd}>
        <ScalerPlus />
      </SButton>
      <SButton onClick={scalerSubtract}>
        <ScalerMinus />
      </SButton>
    </SDiv>
  );
};

const SButton = styled(Button)`
  font-size: 14px;
  border-radius: 6px;
  padding: 6px;
  box-sizing: border-box;
  min-width: 0px;
`;

const SDiv = styled.div`
  position: absolute;
  top: 65px;
  right: 22px;
  z-index: 10001;
  width: 30px;
  height: 60px;
  background-color: #eef5fb;
  border-radius: 6px;
`;

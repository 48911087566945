export default ({
  Menu,
  Left,
  Detail,
}: {
  Menu: JSX.Element;
  Left: JSX.Element;
  Detail: JSX.Element;
}) => {
  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
      {Menu}
      {Left}
      {Detail}
    </div>
  );
};

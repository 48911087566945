import { WorkoutType } from "../../../../../../types";
import { ResultWorkoutType } from "../enums/ResultWorkoutType";

interface IDataDiffPreparer {
  getDifference: (plan: any, fact: any) => number;
}

class RunningDiffPreparer implements IDataDiffPreparer {
  public getDifference(plan: any, fact: any): number {
    const keys = ["distance", "time", "pace", "pulse"];
    let maxPercent = 0;
    for (const idx in keys) {
      const key = keys[idx];

      if (!!plan && !!fact && !!plan[key] && !!fact[key]) {
        let percent =
          100 *
          ((parseFloat(plan[key]) - parseFloat(fact[key])) /
            parseFloat(plan[key]));

        console.log(`plan - ${key} ${parseFloat(plan[key])}`);
        console.log(`fact - ${key} ${parseFloat(fact[key])}`);
        console.log(`percent ${percent}`);

        percent = Math.abs(percent);
        if (maxPercent < percent) maxPercent = percent;
      }
    }
    return maxPercent;
  }
}

class Creator {
  static createItem(type: WorkoutType): IDataDiffPreparer {
    if (type) {
      return new RunningDiffPreparer();
    }
    return new RunningDiffPreparer();
  }
}

export function isPlanMoreThenFact(
  planData: string,
  factData: string,
  type: WorkoutType
): ResultWorkoutType {
  const plan = JSON.parse(planData);
  const fact = JSON.parse(factData);
  const diffPreparer = Creator.createItem(type);
  let result = diffPreparer.getDifference(plan, fact);
  if (result < 0) result = result * -1;

  console.log(planData, factData);
  console.log(`result - ${result}`);

  if (result < 20) {
    return ResultWorkoutType.PlanCompleted;
  }
  if (result < 40) {
    return ResultWorkoutType.PlanWithDeviation;
  } else {
    return ResultWorkoutType.PlanFailed;
  }
}

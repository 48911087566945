import AppApi from "../apis/AppApi";
import AuthStore from "./AuthStore";
import CoachStore from "./CoachStore";
import WorkoutStore from "./WorkoutStore";
import WorkoutDetailStore from "./WorkoutDetailStore";
import ProfileStore from "./ProfileStore";
import SettingsStore from "./SettingsStore";

class Store {
  authStore = new AuthStore(this);
  coachStore = new CoachStore(this);
  workoutStore = new WorkoutStore(this);
  detailStore = new WorkoutDetailStore(this);
  profileStore = new ProfileStore(this);
  settingsStore = new SettingsStore(this);
  private api: AppApi | undefined;
}

export default Store;

import { FC } from "react";
import { Tabs as MUITabs, TabsProps } from "@mui/material";
import { Tab } from './components/Tab/Tab';
import styled from '@emotion/styled';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IProps extends TabsProps {
  items: string[];
}

export const Tabs: FC<IProps> = ({ value, items, onChange }) => {
  return (
    <SWrapper>
      <MUITabs
        value={value}
        TabIndicatorProps={{
          style: { backgroundColor: "#FF3147" },
        }}
        onChange={onChange}
      >
        {items.map((item, idx) => (
          <Tab key={item} {...a11yProps(idx)} label={item} />
        ))}
        <SDownLine />
      </MUITabs>
    </SWrapper>
  );
}

const SWrapper = styled.div`
  position: relative;
  height: 42px;
`

const SDownLine = styled.div`
  height: 1px;
  width: 100%;
  background: #CDD9EA;
  position: absolute;
  bottom: 0;
`

import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { useAppContext } from '../../../../../AppContext';
import { IRow } from '../../types/IRow';
import { getIdByDate } from '../../utils';
import { Cell, ICellProps } from './components/Cell/Cell';
import { WeeklyTotal } from './components/WeeklyTotal/WeeklyTotal';

interface IProps extends ICellProps {
  row: IRow;
}

export const Row: FC<IProps> = observer(({
  row,
  onCalendarClick,
}) => {
  // const { ref } = useInView({ triggerOnce: false });
  const { store } = useAppContext();
  const events = store.workoutStore.items;
  const currentDate = store.workoutStore.currentDate;
  const [activeCardId, setActiveCardId] = useState<string>();

  const removeActiveCardId = () => {
    setActiveCardId(() => undefined)
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
      {row.dates.map((item) => (
        <SCell key={`${item.day}_${item.month}`}>
          <Cell
            key={`${item.day}_${item.month}`}
            item={item}
            onCalendarClick={onCalendarClick}
            isCurrentMonth={currentDate.get("M") === item.month}
            id={getIdByDate(item.date)}
            activeCardId={activeCardId}
            onSetActiveCardId={setActiveCardId}
            onRemoveActiveCardId={removeActiveCardId}
          />
        </SCell>
      ))}
      <SWeekCell>
        <WeeklyTotal row={row} events={events} />
      </SWeekCell>
    </div>
  );
})

const SCell = styled.div`
  width: 146px;
  flex-grow: 1;
  border: 1px solid #CDD9EA;
`

const SWeekCell = styled(SCell)`
  max-width: 186px;
  width: 176px;
`

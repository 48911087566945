import { Typography } from "@mui/material";

export default () => {
  return (
    <div
      style={{
        padding: "18px 0",
        display: "flex",
        flexDirection: "row",
        width: "145px",
      }}
    ></div>
  );
};

import { FC, ReactNode, useEffect } from "react"

interface IProps {
  children: ReactNode;
}

export const TitleWrapper: FC<IProps> = ({ children }) => {
  useEffect(() => {
    if (process.env.REACT_APP_IS_DEVELOPMENT === '1') {
      document.title = '[DEV] ' + document.title;
    }
  }, [])

  return children
}

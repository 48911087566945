import React from "react";

import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { Grid } from "@mui/material";

export const Yna = (props: DefaultComponentProps<any>) => {
  return (
    <Grid className={props.className}>
      <svg
        width="176"
        height="46"
        viewBox="0 0 176 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M92.0936 45.9204L74.4357 28.945L80.5507 21.3661L91.2004 31.6017L116.691 0L124.125 6.20848L92.0936 45.9204Z"
          fill="white"
        />
        <path
          d="M54.634 45.9485L47.186 39.74L79.2177 0.0280762L96.8207 16.9336L90.7057 24.5264L80.1109 14.3467L54.634 45.9485Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M160.999 36.2302L168.799 45.9205L176 39.4324L144.229 0.0280762L112.445 39.4324L119.646 45.9205L127.45 36.2302H160.999ZM153.121 26.4421L144.229 15.3955L135.333 26.4421H153.121Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.9587 35.928V45.9205H24.0753V35.9743L0.261108 6.75393L8.25873 0.0280762L29.5033 26.0785L50.6928 0.0280762L58.6905 6.75393L34.9587 35.928Z"
          fill="white"
        />
      </svg>
    </Grid>
  );
};

import { TimeFieldProps, TimeField } from "@mui/x-date-pickers";
import { FC } from "react";
import { BaseInput, IBaseInputProps } from "../BaseInput/BaseInput";

export interface IMinutesInputProps extends Omit<TimeFieldProps<any>, 'label'>, IBaseInputProps {
  inputType: 'minutes';
  value?: number;
  placeholder?: string;
  units?: string;
}

export const MinutesInput: FC<IMinutesInputProps> = (props) => {
  return (
    <BaseInput
      as={TimeField}
      size="small"
      style={{ borderRadius: "4px" }}
      format="mm:ss"
      onFocus={(e: any) => {
        e.currentTarget.setSelectionRange(0, 4);
      }}
      {...props}
    />
  )
}

import dayjs, { Dayjs } from "dayjs";
import { WorkoutItem, WorkoutType } from "../types";
import utc from "dayjs/plugin/utc";

import duration from "dayjs/plugin/duration";
dayjs.extend(duration);
dayjs.extend(utc);

const formateDateObject = (date: Dayjs): WorkoutItem => {
  const clonedObject = { ...date.toObject() };

  const formatedObject = {
    day: clonedObject.date,
    month: clonedObject.months,
    year: clonedObject.years,
    date: date,
    isCurrentDay: date.startOf("day").isToday(),
  };

  return formatedObject;
};

const getIdByDate = (date: Dayjs): string => {
  return date.format("YYYYMMDD");
};

const getDateWithoutTz = (date: Date): Date => {
  const dateWithoutTz = dayjs.utc(dayjs(date)).toDate();
  return dateWithoutTz;
};

const toSeconds = (date: Dayjs): number => {
  const secondsCount = dayjs
    .duration({
      hours: date.utc().hour(),
      minutes: date.minute(),
      seconds: date.second(),
    })
    .asSeconds();
  return secondsCount;
};

const fromSeconds = (secondsCount: number | undefined): Dayjs => {
  if (!secondsCount) return dayjs(0);
  return dayjs(0).utc().add(secondsCount, "seconds");
};

const getCurrentYear = () => {
  return dayjs().year().toString();
};

function getItemDimensions(type: string) {
  switch (type) {
    case WorkoutType[WorkoutType.running]:
      return {
        pace: "мин/км",
        distance: "км",
      };
    case WorkoutType[WorkoutType.swimming]:
      return {
        pace: "/100м",
        distance: "км",
      };
  }
  return {};
}

export {
  formateDateObject,
  getIdByDate,
  getDateWithoutTz,
  toSeconds,
  fromSeconds,
  getItemDimensions,
  getCurrentYear,
};

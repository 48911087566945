import styled from "@emotion/styled";
import { useAppContext } from "../../AppContext";
import { Button } from "@mui/material";

export default () => {
  const { store, api } = useAppContext();
  return (
    <SWrapper>
      <SCancelButton onClick={() => store.profileStore.setIsProfileOpen(false)}>
        Отменить
      </SCancelButton>
      <SSaveButton
        onClick={async () => {
          await api.profileApi.save();
          await api.authApi.getUserInfo();
          store.profileStore.setIsProfileOpen(false);
        }}
      >
        Сохранить
      </SSaveButton>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  gap: 0 24px;
  padding: 16px 24px;
  box-sizing: border-box;
  flex: 1;
  justify-content: flex-end;
`;

const SButton = styled(Button)`
  font-size: 14px;
  border-radius: 6px;
  padding: 12px 16px;
  box-sizing: border-box;

`;

const SCancelButton = styled(SButton)`
  background-color: #eef5fb;
  color: #1a222d;

  &:hover {
    background-color: #DBE4F0;
  }

  &:focus {
    background-color: #DBE4F0;
  }
`;

const SSaveButton = styled(SButton)`
  background-color: #ff3147;
  color: #fff;

  &:hover {
    background-color: #CC2739;
  }

  &:focus {
    background-color: #CC2739;
  }
`;

import { Dispatch, RefObject, useCallback, useMemo } from "react";
import { useAppContext } from "../../../../AppContext"
import { GenerateCount } from "../constants";
import { IRow } from "../types/IRow";
import { formateDateObject } from "../utils";
import { useCalendarTarget } from "./useCalendarTarget";
import dayjs from 'dayjs';
import { useCalendarScroll } from "./useCalendarScroll";

interface IResult {
  rows: IRow[];
  setDate: Dispatch<Date>;
  date: Date;
  tableRef: RefObject<HTMLDivElement>;
}

export const useCalendar = (): IResult => {
  const { 
    setDate,
    date: targetDate,
  } = useCalendarTarget();

  const { 
    tableRef,
  } = useCalendarScroll(targetDate, setDate);

  const rows = useMemo(() => {
    const amount = GenerateCount;
    let date = dayjs(targetDate).weekday(1);

    date = date.add(-5, "week");

    let allDates = [];
    let weekDates = [];
    let weekCounter = 1;

    while (allDates.length < amount) {
      const formated = formateDateObject(date);
      weekDates.push(formated);
      if (weekCounter === 7) {
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }
      weekCounter++;
      date = date.add(1, "day");
    }

    return allDates;
  }, [targetDate])

  return {
    date: targetDate,
    tableRef,
    rows,
    setDate,
  }
}

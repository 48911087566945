import { Typography } from "@mui/material";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar_picker.svg";
import { DatePicker } from "@mui/x-date-pickers";
import styled from "@emotion/styled";
import dayjs from "dayjs";

export default ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: any) => void;
}) => {
  return (
    <SWrapper>
      <STitle>Дата рождения</STitle>
      <STextDiv>
        <SDateField
          timezone="UTC"
          value={dayjs(value)}
          onChange={(value) => {
            onChange(value);
          }}
          slotProps={{ textField: { size: "small" } }}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
        />
      </STextDiv>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  width: 182px;
`;

const STitle = styled(Typography)`
  color: var(--Black, #1a222d);
  font-family: Golos Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

const STextDiv = styled.div`
  display: flex;
  height: 36px;
  padding: 12px 0 0 0;
  align-items: center;
  gap: 15px;
  align-self: stretch;
`;

const SDateField = styled(DatePicker)`
  border-radius: 4px;
  background: var(--G2, #eef5fb);
`;
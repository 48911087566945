import * as d3 from "d3";
import { Dispatch, RefObject, useEffect, useMemo } from "react";
import { TChart } from "../types/TChart";
import { TLine } from "../types/TLine";
import { TPoint } from "../types/TPoint";
import { getHighAverageLine } from "../utils/getHighAverageLine";

interface IParams {
	ref: RefObject<SVGSVGElement>;
	chart: TChart | undefined;
	x: TPoint | undefined;
	y: TPoint | undefined;
	onChartlineChange?: Dispatch<TLine>;
	data: number[];
	average: number;
	reverse: boolean;
}

interface ILineData {
	item: number;
	idx: number;
}

export const useChartline = ({
	x,
	y,
	chart,
	ref,
	onChartlineChange,
	data,
	average,
	reverse,
}: IParams) => {
	const lineData: ILineData[] = useMemo(() => data.map((d, idx) => ({
		item: d,
		idx: idx
	})), [data])

	const linesGroups = useMemo(() => {
		const groups: ILineData[][] = [];
		let currentGroup: ILineData[] = [];

		for (const value of lineData) {
			const idx = value.idx
			const topJump = lineData[idx - 1] && Math.abs(value.item / lineData[idx - 1].item) > 1.5
			const bottomJump = lineData[idx + 1] && Math.abs(value.item / lineData[idx + 1].item) > 1.5
			if (topJump || bottomJump) {
				groups.push(currentGroup);
				currentGroup = [];
			}
			else {
				currentGroup.push(value)
			}
		}
		groups.push(currentGroup);

		return groups;
	}, [lineData])

	const highAverageGroups = useMemo(() => {
		const groups: ILineData[][] = [];
		let currentGroup: ILineData[] = [];

		for (const value of lineData) {
			if (value.item > average) {
				currentGroup.push(value)
			}
			else {
				groups.push(currentGroup);
				currentGroup = [];
			}
		}
		groups.push(currentGroup);

		return groups;
	}, [lineData, average])

	useEffect(() => {
		const svgEl = ref.current;

		if (!svgEl || x === undefined || y === undefined || !chart) {
			return;
		}

		const svg = chart
			.append("g")
			.attr("transform", `translate(${0},${0})`);

		const yAxisGroup = svg.append("g").call(y as any);
		yAxisGroup.select(".domain").remove();
		yAxisGroup.selectAll("line").attr("stroke", "rgba(255, 255, 255, 0.2)");

		const line = d3
			.line()
			.x((_d: any, idx: number) => {
				return x(_d.idx)
			})
			.y((d: any) => y(d.item))
		const simpleLine = d3
			.line()
			.x((_d: any, idx: number) => x(idx))
			.y((d: any) => y(d))

		if (onChartlineChange) {
			onChartlineChange(line);
		}

		svg.selectAll(".line")
			.data([data.map(() => average)])
			.enter()
			.append("path")
			.attr("fill", "none")
			.attr("stroke", (_d) => '#CDD9EA')
			.attr("stroke-width", 1)
			.style("stroke-dasharray", ("3, 3"))
			.attr("d", (d) => simpleLine(d as any))

		for (const group of linesGroups) {
			if (group.some((item) => item.item <= 0)) {
				continue;
			}

			svg.selectAll(".line")
				.data([group])
				.enter()
				.append("g")
				.append("path")
				.attr("fill", "none")
				.attr("stroke", (_d) => '#252B4B')
				.attr("stroke-width", 1)
				.attr("d", (d) => line(d as any))
		}
		/* for (const group of highAverageGroups) {
			svg.selectAll(".line")
				.data([group])
				.enter()
				.append("g")
				.append("path")
				.attr("fill", "none")
				.attr("stroke", (_d) => '#FF6071')
				.attr("stroke-width", 1)
				.attr("d", (d) => line(d as any))
		} */

		// getHighAverageLine(svg, line, data, average)

		return () => {
			svg.remove()
		}
	}, [data, chart, ref, onChartlineChange, linesGroups])
}

import { css } from "@emotion/react"

interface IParams {
	positionPercent: number;
  hoverWidth: number;
  activeStep: number | undefined;
  stepsCount: number;
}

export const getGreenHoverCss = (halfHeight = false) => {
    const getHoverX = (stepPercent: number) => {
        return `calc(${stepPercent * 100}%)`
    }

	return ({ positionPercent, hoverWidth, activeStep, stepsCount }: IParams) => (positionPercent && activeStep) ? css`
    & > div > div > div::before {
      content: '';
      left: ${getHoverX((activeStep - 1)/(stepsCount))};
      width: ${hoverWidth - 16}px;
      position: absolute;
      height: ${halfHeight ? '50%' : '100%'};
      ${!!halfHeight ? `bottom: 0;` : ''}
      background: #EEFFF6;
      pointer-events: none;
      z-index: 0;
    }

    & > div > div::after {
      content: '';
      padding-top: 5px;
      position: absolute;
      left: 0;
      pointer-events: none;
      margin-bottom: -1px;
      padding: 0;
      margin-left: calc(${positionPercent*100}%);
      height: ${halfHeight ? '50%' : '100%'};
      ${!!halfHeight ? `bottom: 0;` : 'top: 0;'}
      width: 1px;
      max-width: 1px;
      background: #7B8A9E;
      z-index: 0;
    }
  ` : ''
}

import React from "react";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import {authStyles} from "../../../../styles/authStyles";
import {useAppContext} from "../../../../AppContext";
import {Button, Grid, TextField, Typography} from "@mui/material";
import PasswordTextField from "../../../../components/common/PasswordTextField";
import { useTranslation } from "react-i18next";

export const ChangePasswordForm = () => {
  const { classes } = authStyles();
  const { api } = useAppContext();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: '',
      oldPassword: '',
      newPassword: '',
    },
    onSubmit: async (values) => {
      const result = await api.authApi.changePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
      if (result.success) {
        navigate("/");
      }
    }
  });
  let navigate = useNavigate();

  return <>
    <Typography variant="h4" className={classes.header}>{t('changePasswordTitle')}</Typography>
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <Grid container>
        <Grid item xs={12}>
          <TextField 
            name="email" className="yna" autoComplete="off" label={t('emailInput')} variant="outlined"
            value={formik.values.email} fullWidth
            onChange={formik.handleChange} />
        </Grid>
        <Grid item xs={12}>
          <PasswordTextField
            name="oldPassword" className="yna" autoComplete="off" label={t('currentPasswordInput')} variant="outlined"
            style={{marginTop: '37px'}}
            value={formik.values.oldPassword} fullWidth
            onChange={formik.handleChange} />
        </Grid>
        <Grid item xs={12}>
          <PasswordTextField
            name="newPassword" className="yna" autoComplete="off" label={t('newPasswordInput')} variant="outlined"
            style={{marginTop: '37px'}}
            value={formik.values.newPassword} fullWidth
            onChange={formik.handleChange} />
        </Grid>
      </Grid>
      <Grid container className={classes.submitWrapper}>
        <Button className="yna" type="submit" variant="contained" color="primary" fullWidth>
          {t('changePassword')}
        </Button>
      </Grid>
    </form>
  </>
}

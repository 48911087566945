import {
  MapContainer,
  TileLayer,
  Polyline,
  useMap,
  Marker,
} from "react-leaflet";
import "./style.css";
import { observer } from "mobx-react";
import { useAppContext } from "../../AppContext";
import { WorkoutDetailItemType } from "../../stores/WorkoutDetailStore";
import L from "leaflet";
import { makeStyles } from "../../styles";
import ExpandMapBitton from "./ExpandMapButton";
import MapScaler from "./MapScaler";
import { useEffect } from "react";

const useStyles = makeStyles({})({
  marker: {
    borderRadius: "56px",
    minWidth: "20px",
    minHeight: "20px",
    padding: "12",
    width: "56px",
    backgroundColor: "#FFF",
    borderColor: "#CDD9EA",
    border: "2px solid var(--G4-line-Main, #CDD9EA)",
    textAlign: "center",
    fontSize: "12",
    fontVariantNumeric: "lining-nums tabular-nums",
    fontWeight: 400,
  },
  selectedMarker: {
    borderRadius: "56px",
    minWidth: "20px",
    zIndex: 100,
    minHeight: "20px",
    padding: "12",
    width: "56px",
    backgroundColor: "#000",
    borderColor: "#000",
    border: "2px solid var(--G4-line-Main, #000)",
    color: "#FFF",
    textAlign: "center",
  },

  mapContainerExpanded: {
    height: "100%",
    display: "flex",
    width: "585px",
    border: "solid 1px",
  },
  mapContainer: {
    height: "294px",
    border: "solid 1px",
  },
});

const Map = () => {

  const { classes } = useStyles();
  const { store } = useAppContext();

  if (!store.detailStore.workout) {
    return null;
  }

  if (!store.detailStore.workout.coordinates) {
    return null;
  }

  return (
    <div
      className={
        store.detailStore.isMapExpanded
          ? classes.mapContainerExpanded
          : classes.mapContainer
      }
    >
      <MapContainer
        attributionControl={false}
        zoom={store.detailStore.mapScale}
        scrollWheelZoom={false}
        zoomControl={false}
        center={
          store.detailStore.currentPoint ||
          store.detailStore.workout.coordinates[0]
        }
        style={{ width: "100%", height: "100%", minHeight: "100%" }}
      >
        <ChangeView
          center={
            store.detailStore.currentPoint ||
            store.detailStore.workout.coordinates[0]
          }
        />
        <ChangeZoomView zoom={store.detailStore.mapScale} />
        {store.detailStore.workoutDetails &&
          store.detailStore.workoutDetails.map(
            (item: WorkoutDetailItemType, idx: number) => {
              const text = L.divIcon({
                html: `${idx + 1} `,
                className:
                  idx === store.detailStore.currentIdx
                    ? classes.selectedMarker
                    : classes.marker,
              });
              return (
                <Marker
                  icon={text}
                  key={`marker-${idx}`}
                  position={item.mapPoint}
                  pane={"markerPane"}
                ></Marker>
              );
            }
          )}
        <Polyline positions={store.detailStore.workout.coordinates} />
        <ExpandMapBitton />
        <MapScaler />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      </MapContainer>
    </div>
  );
};

function ChangeView({ center }: { center: any }) {
  const map = useMap();
  useEffect(() => {
    setInterval(() => {
      try {
        if (map) map.invalidateSize();
      } catch {}
    }, 250);
    return () => {
      map.off();
    };
  }, [map]);
  map.setView(center, map.getZoom());
  return null;
}

function ChangeZoomView({ zoom }: { zoom: number }) {
  const map = useMap();
  useEffect(() => {
    setInterval(() => {
      try {
        if (map) map.invalidateSize();
      } catch {}
    }, 250);
    return () => {
      map.off();
    };
  }, [map]);
  map.setZoom(zoom);
  return null;
}

export default observer(Map);

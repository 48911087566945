import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { FC, useMemo, useRef } from "react";
import { useAppContext } from "../../../../AppContext";
import { SecondsToTime } from "../../../../utils/time";

interface IProps {
  positionPercent: number | undefined;
}

export const ProgressPopup: FC<IProps> = observer(({ positionPercent }) => {
  const {
    store: { detailStore },
  } = useAppContext();
  const ref = useRef<HTMLDivElement>(null)

  const time = useMemo(() => {
    const items = detailStore.streamData?.time?.data;
    if (!items?.length || !positionPercent) {
      return;
    }

    const idx = Math.floor(items.length * positionPercent);
    return items[idx];
  }, [positionPercent, detailStore.streamData?.time?.data])

  const km = useMemo(() => {
    const items = detailStore.streamData?.distance?.data;
    if (!items?.length || !positionPercent) {
      return;
    }

    const currentKm = items[items.length - 1] * positionPercent;

    return items.reduce((prev: number, curr: number) => {
      return (Math.abs(curr - currentKm) < Math.abs(prev - currentKm) ? curr : prev);
    });

  }, [positionPercent, detailStore.streamData?.distance?.data])

  const percentLeft = positionPercent ? (positionPercent * 100).toFixed(0) : undefined;

  return (
    <SWrapper
      ref={ref}
      percentLeft={percentLeft}
      width={ref.current?.clientWidth}
    >
      {!!km && <SKm>{(km / 1000).toFixed(2)} км</SKm>}
      {!!time && <STime>{SecondsToTime(time)}</STime>}
    </SWrapper>
  )
})

const SWrapper = styled.div<{
  percentLeft: string | undefined;
  width: number | undefined;
}>`
  position: absolute;
  top: 0;
  background: #fff;
  box-sizing: border-box;
  padding: 3px 4px;
  font-size: 10px;
  pointer-events: none;
  margin-top: -10px;
  ${({ width }) => width && css`
    margin-left: -${width / 2}px;
  `}
  z-index: 2;
  box-shadow: 0px 1px 8px 0px #072C2026;
  border-radius: 2px;

  ${({ percentLeft }) => percentLeft && css`
    left: calc(${percentLeft}% + 10px);
  `}
`

const SKm = styled.span`
  font-weight: 600;
`

const STime = styled.span`
  margin-left: 5px;
`

import styled from "@emotion/styled";
import { FormControl, Select, MenuItem, Typography } from "@mui/material";

export default ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: any) => void;
}) => {
  return (
    <SWrapper>
      <FormControl fullWidth>
        <STitle>Пол</STitle>
        <SInputDiv>
          <STextField
            size="small"
            fullWidth
            value={value}
            label=""
            onChange={(evt) => onChange(evt.target.value)}
          >
            <MenuItem selected={value === "male"} value={"male"}>
              Мужской
            </MenuItem>
            <MenuItem selected={value === "female"} value={"female"}>
              Женский
            </MenuItem>
          </STextField>
        </SInputDiv>
      </FormControl>
    </SWrapper>
  );
};

const STitle = styled(Typography)`
  color: var(--Black, #1a222d);
  font-family: Golos Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

const SWrapper = styled.div`
  width: 182px;
`;

const SInputDiv = styled.div`
  display: flex;
  height: 36px;
  padding: 12px 0 0 0;
  align-items: center;
  gap: 15px;
  align-self: stretch;
`;

const STextField = styled(Select)`
  border-radius: 4px;
  background: var(--G2, #eef5fb);
`;
import dayjs from "dayjs";
import { WorkoutData } from "../../../stores/WorkoutStore";
import duration from "dayjs/plugin/duration";
import { fromSeconds } from "../calendar/utils";
dayjs.extend(duration);

export default ({ items }: { items: WorkoutData[] }) => {
  const filteredItems = items;

  if (filteredItems.length === 0) return null;
  var distance = 0;
  var planDistance = 0;
  var totalTime = 0;
  var planTotalTime = 0;

  for (const idx in filteredItems) {
    var data = JSON.parse(filteredItems[idx].fact) ?? {};
    var plan = JSON.parse(filteredItems[idx].plan) ?? {};
    distance += parseFloat((data["distance"] || "").replace(",", ".") || 0);
    planDistance += parseFloat((plan["distance"] || "").replace(",", ".") || 0);

    if (data["time"]) {
      totalTime += parseInt(data["time"]);
    }

    if (plan["time"]) {
      planTotalTime += parseInt(plan["time"]);
    }
  }

  return (
    <div style={{ padding: "12px 0 6px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        <div
          style={{ width: "24px", height: "24px", paddingRight: "8px" }}
        ></div>

        <div style={{ flexGrow: 1 }}>
          <div
            style={{
              display: "flex",
              flexGrow: "1",
              flex: 1,
              color: "#97A4B7",
              padding: "3px 0",
              borderBottom: "1px solid #CDD9EA",
            }}
          >
            <div style={{ minWidth: "58px", flexGrow: 1 }}>
              {planDistance ? `${parseFloat(planDistance.toFixed(1))} км` : "-"}
            </div>
            <div>
              {fromSeconds(planTotalTime).utc(false).format("HH:mm:ss")}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              color: "#1A222D",
              padding: "3px 0",
              fontWeight: 600,
            }}
          >
            <div style={{ minWidth: "58px", flexGrow: 1 }}>
              {distance ? `${parseFloat(distance.toFixed(1))} км` : "-"}
            </div>
            <div>{fromSeconds(totalTime).utc(false).format("HH:mm:ss")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { YMInitializer } from "react-yandex-metrika";
import { useFlag } from "@unleash/proxy-client-react";
export default ({ children = null }: { children?: React.ReactNode }) => {
  return (
    <>
      {children}
      {useFlag("yametric") && <YMInitializer accounts={[96766205]} />}
    </>
  );
};

import {observer} from "mobx-react";
import {useAppContext} from "../../AppContext";
import {Box, DialogActions, DialogContent, Modal} from "@mui/material";
import Header from "./Header";
import Content from "./Content";
import React from "react";
import ButtonsWrapper from "./components/ButtonsWrapper";
import RedButton from "./components/RedButton";

export default observer(() => {
    const {
        store
    } = useAppContext();
    
    const onCloseHandler = () => store.settingsStore.close();
    const onBackHandler = () => store.settingsStore.onBack();
    
    const getSubTitle = () : string => {
        if (store.settingsStore.isConnectingServices())
            return 'Подключенные приложения';
        
        return '';
    }
    
    return <Modal
        style={{
            top: "1%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "scroll",
            position: "absolute",
        }}
        open={store.settingsStore.isSettingsOpen}
        onClose={onCloseHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
            sx={{
                width: 698,
                height: "760px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#FFF",
            }}
        >
            <Header onClose={onCloseHandler} subTitle={getSubTitle()} />
            <DialogContent dividers>
                <Content />
            </DialogContent>
            <DialogActions>
                <ButtonsWrapper>
                    {store.settingsStore.isSettingsWindow() && <RedButton onClick={onCloseHandler}>Закрыть</RedButton>}
                    {store.settingsStore.isConnectingServices() && <RedButton onClick={onBackHandler}>Назад</RedButton>}
                </ButtonsWrapper>
            </DialogActions>
        </Box>
    </Modal>
});
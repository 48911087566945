import styled from "@emotion/styled";
import { FormControl, Typography, TextField } from "@mui/material";
import { ChangeEvent } from "react";

export default ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: any) => void;
}) => (
  <FormControl fullWidth>
    <STitle>Город</STitle>
    <STextDiv>
      <STextField
        value={value}
        fullWidth
        onChange={(
          evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          console.log(evt.target.value);
          onChange(evt.target.value);
        }}
        size="small"
      />
    </STextDiv>
  </FormControl>
);
const STitle = styled(Typography)`
  color: var(--Black, #1a222d);
  font-family: Golos Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

const STextDiv = styled.div`
  display: flex;
  height: 36px;
  padding: 12px 0 0 0;
  align-items: center;
  gap: 15px;
  align-self: stretch;
`;

const STextField = styled(TextField)`
  border-radius: 4px;
  background: var(--G2, #eef5fb);
`;
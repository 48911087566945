import Store from "./Store";
import {action, makeAutoObservable, observable} from "mobx";

export enum SettingStep {
    MainWindow,
    ConnectingServices
}

export default class SettingsStore {
    isSettingsOpen: boolean = false;
    stepType: SettingStep = SettingStep.MainWindow;

    constructor(private store: Store) {
        makeAutoObservable(this, {
            open: action.bound,
            close: action.bound,
            onBack: action.bound,
            setStepType: action.bound,
            isConnectingServices: observable,
            isSettingsWindow: observable,
        });
    }
    
    open() {
        this.setStepType(SettingStep.MainWindow);
        this.isSettingsOpen = true;
    }
    
    close() {
        this.setStepType(SettingStep.MainWindow);
        this.isSettingsOpen = false;
    }
    
    onBack() {
        this.setStepType(SettingStep.MainWindow);
    }
    
    setStepType(type: SettingStep) {
        this.stepType = type;
    }
    
    isSettingsWindow = () => {
        return this.stepType === SettingStep.MainWindow;
    }
    isConnectingServices = () => {
        return this.stepType === SettingStep.ConnectingServices;
    }
}
import { Typography } from "@mui/material";
import styled from '@emotion/styled';

export default ({
  data,
  Icon,
  title,
  units,
}: {
  data: any;
  Icon?: React.FunctionComponent;
  title: string;
  units: string;
}) => {
  return (
    <div
      style={{
        width: 139,
        height: 36,
        display: "flex",
        flexDirection: "row",
      }}
    >
      {!!Icon && (
        <SIcon>
          <Icon />
        </SIcon>
      )}

      <div
        style={{ display: "flex", flexDirection: "column", justifyContent: 'space-between', color: "#7B8A9E" }}
      >
        {!!data && (
          <div
            style={{
              color: '#1A222D',
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            {data} <span style={{ fontWeight: 500 }}>{units}</span>
          </div>
        )}
        {!data && <Typography>—</Typography>}
        <div
          style={{
            color: "#7B8A9E",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "14px",
            fontFamily: "Golos Text",
          }}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

const SIcon = styled.div`
  padding-right: 12px;

  & svg {
    color: #1A222D;
    height: 36px;
    width: 36px;
  }
`

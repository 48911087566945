import { makeStyles } from 'tss-react/mui';

export const authStyles = makeStyles()((theme) => {
    return {
        logo: {
            margin: '67px 0 0 67px',
            height: 'fit-content',
        },
        authWrapper: {
            minHeight: '100vh',
            backgroundColor: '#FF3147',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundImage: 'url("/bg.svg")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
        },
        loginWrapper: {
            width: '100%',
            margin: '0',
        },
        rightSide: {
            width: '50%',
            height: '100%',
        },
        leftSide: {
            display: 'flex',
            borderRadius: '24px 0px 0px 24px',
            width: '50%',
            height: '100%',
            backgroundColor: '#FFFFFF',
        },
        loginFormWrapper: {
            margin: '0 auto',
            width: '506px',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '24px',
            color: '#A8A7A7',
            alignItems: 'center',
            display: 'grid',
            alignContent: 'center',
        },
        header: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '30px',
            lineHeight: '36px',
            letterSpacing: '0.01em',
            color: '#181818',
            marginBottom: '48px',
        },
        headerSubItem: {
            fontSize: '20px',
        },
        socialButtonsWrapper: {
            marginTop: '14px',
            paddingTop: 0,
        },
        socialButton: {
            marginRight: '52px',
        },
        orLabel: {
            margin: '51px 0',
            textAlign: 'center',
        },
        notchedOutline: {
            borderColor: '#c0c0c0',
        },
        rememberWrapper: {
            marginTop: '19px',
            fontSize: '16px',
            '& .MuiFormControlLabel-label': {
                fontSize: '16px',
            }
        },
        loginPageLink: {
            fontSize: '16px',
            fontWeight: '19px',
            textDecoration: 'none',
            color: '#FF3147',
            '&:hover': {
                color: '#d31818',
            }
        },
        submitWrapper: {
            marginTop: '56px',
            color: '#fff',
        },
        registrationWrapper: {
            marginTop: '21px',
            '& .register-prompt': {
                color: '#A8A7A7',
                fontSize: '16px',
            }
        }
    }
});

import AppApi from "./AppApi";
import AppStore from "../stores/Store";

export default class ProfileApi {
  constructor(private api: AppApi, private store: AppStore) {
    this.api = api;
    this.store = store;
  }

  setIsProfileOpen(isOpen: boolean) {
    this.store.profileStore.setIsProfileOpen(isOpen);
  }

  async saveIsCoach() {
    const data = {
      role: this.store.profileStore.isCoach ? "coach" : "athlete",
    };
    await this.api.client.put(`/users/api/v1/users/role`, data);
    this.setIsProfileOpen(false);
  }

  async saveFile() {
    const profileFile = this.store.profileStore.profileFile;

    if (profileFile == null) return;
    const formData = new FormData();
    formData.append("file", profileFile);

    const token = await this.store.authStore.getAuthToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await this.api.client.post("/users/api/v1/users/avatar", formData, config);
  }

  async saveData() {
    const token = await this.store.authStore.getAuthToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const id = this.store.authStore.currentUser?.id;

    const profileData = this.store.profileStore.profileFormData;

    const data = {
      fullName: profileData.fullName,
      sex: profileData.sex,
      birthDate: profileData.birthDate,
      weight: profileData.weight,
      city: profileData.city,
      about: profileData.about,
    };

    await this.api.client.put(`/users/api/v1/users/${id}`, data, config);
  }
  
  async savePassword() {
    const profileData = this.store.profileStore.profileFormData;

    const token = await this.store.authStore.getAuthToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await this.api.client.put(
      `/auth/api/v1/password`,
      {
        password: profileData.password,
        confirmPassword: profileData.passwordConfirm,
      },
      config
    );
  }

  async setFile(file: any) {
    this.store.profileStore.setCurrentFile(file);
  }

  setPassword(password: string) {
    this.store.profileStore.setPassword(password);
  }

  setPasswordConfirm(password: string) {
    this.store.profileStore.setPasswordConfirm(password);
  }

  async save() {
    await this.saveFile();
    await this.saveData();
    if (this.canSavePassword()) {
      await this.savePassword();
    }
  }

  async loadCurrentProfileData() {
    this.store.profileStore.setAllFormData(this.store.authStore.currentUser);
  }

  canSavePassword(): boolean {
    const profileData = this.store.profileStore.profileFormData;
    if (!profileData.password) {
      return false;
    }

    if (profileData.password !== profileData.passwordConfirm) {
      return false;
    }

    return true;
  }
}



import {observer} from "mobx-react";
import styled from "@emotion/styled";
import SettingsList from "./SettingsList";
import {useAppContext} from "../../AppContext";
import ConnectedServicesList from "./ConnectedServicesList";
import {SettingStep} from "../../stores/SettingsStore";
import useExternalService from "./hooks/useExternalService";

export default observer(() => {
    const {
        store: { settingsStore, authStore },
        api: { settingsApi }
    } = useAppContext();
    
    const [ openOAuthWindow ] = useExternalService(settingsApi)
    
    const onSettingsItemClick = (step: SettingStep) => {
        settingsStore.setStepType(step);
    }
    
    const onServiceItemConnect = async (service: string) => {
        await openOAuthWindow(service);
    }

    return (
        <>
            <SWrapper>
                <SettingsList onItemClick={onSettingsItemClick} visible={settingsStore.isSettingsWindow()} />
                <ConnectedServicesList
                    services={authStore.currentUser?.services}
                    onConnect={onServiceItemConnect}
                    visible={settingsStore.isConnectingServices()} />
            </SWrapper>
        </>
    );
});

const SWrapper = styled.div`
    padding: 24px 60px;
`;

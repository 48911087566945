import React, { useState } from "react";

import {Grid, Button, TextField, FormHelperText, Typography} from "@mui/material";

import { observer } from "mobx-react";
import { useAppContext } from "../../../../AppContext";
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import { GetTokenSchema } from './schemas/GetTokenSchema';

import { authStyles } from '../../../../styles/authStyles';
import { useTranslation } from "react-i18next";

export const GetTokenForm = observer(() => {
  const { t } = useTranslation();
  const { classes } = authStyles();
  const { api } = useAppContext();
  const [ emailSent, setEmailSent ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: GetTokenSchema(t),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const result = await api.authApi.getResetPasswordToken({
          email: values.email,
          link: window.location.origin.toString()
        });
        if (result.success) {
          setEmailSent(true);
        }
      }
      finally {
        setLoading(false);
      }
    }
  });

  return <>
  {!emailSent && <form onSubmit={formik.handleSubmit} autoComplete="off">
    <Grid container>
      <Grid item xs={12}>
        <FormHelperText style={{marginTop: '37px'}} error className="yna">{formik.errors.email ?? ''}</FormHelperText>
        <TextField
          disabled={loading}
          name="email" className="yna" autoComplete="off" label="Email" variant="outlined"
          error={formik.errors.email !== undefined}
          value={formik.values.email} fullWidth
          onChange={formik.handleChange} />
      </Grid>
      <Grid item xs={12} className={classes.submitWrapper}>
        <Button disabled={loading || !(formik.isValid && formik.dirty)} className="yna" type="submit" variant="contained" color="primary" fullWidth>
          {t('recoverPassword')}
        </Button>
      </Grid>
      <Grid container flexWrap={"wrap"} justifyContent={'center'} className={classes.registrationWrapper}>
        <Grid item display={"flex"} alignContent={"center"}>
          <Typography className="register-prompt" variant="body1">{t('notRegistered')}</Typography>
        </Grid>
        <Grid item flexWrap={"wrap"} display={"flex"} alignContent={"center"}>
          <Link to="/login/register" className={classes.loginPageLink}>
            <Typography component="span">&nbsp;{t('notRegisteredCreateAccount')}</Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  </form>}
  {emailSent  && <Grid container>
      <Grid item xs={12}>
        {t('emailSend')}
      </Grid>
      <Grid item xs={12} className={classes.submitWrapper}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button className="yna" type="button" variant="contained" color="primary" fullWidth>
            OK
          </Button>
        </Link>
      </Grid>
    </Grid>}
  </>
});

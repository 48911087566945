import { Box } from "@mui/material";
import { observer } from "mobx-react";

export type AthleteItem = {
  id: string;
  name: string;
  logoUrl?: string;
};

const AthleteItemComp = ({
  item,
  selected,
  onClick,
  me,
}: {
  item: AthleteItem;
  selected: boolean;
  onClick: (id: string) => void;
  me: boolean;
}) => {
  return (
    <Box
      onClick={() => onClick(item.id)}
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        justifyItems: "center",
        alignItems: "center",
        maxHeight: 48,
        padding: "8px 16px",
        backgroundColor: selected ? "#C8FCE0" : "#FFF",
        fontSize: 14,
        "&:hover": {
          backgroundColor: selected ? "#C8FCE0" : "rgba(25, 118, 210, 0.04)",
        },
      }}
    >
      <div>
        <img
          width={40}
          height={40}
          src={`${process.env.REACT_APP_BASE_URL}/users/api/v1/users/${item.id}/avatar`}
          alt=""
          // loading="lazy" - тут не нужен лэзи лоад мы отображаем все иконки сразу
          style={{ marginRight: "8px", borderRadius: "50%" }}
        />
      </div>
      <div style={{ width: "210px", color: "#1A222D" }}>
        {" "}
        {item.name}
        {me && " (Вы)"}
      </div>
    </Box>
  );
};

export default observer(AthleteItemComp);
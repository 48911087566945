import styled from "@emotion/styled";

export default () => (
  <div
    style={{
      display: "flex",
      flex: 1,
      //   height: 800,
      flexDirection: "column",
      backgroundColor: "#EEF5FB",
      padding: "16px 24px",
    }}
  >
    <ContentDiv>
      <Text>Нет данных для отображения</Text>
    </ContentDiv>
  </div>
);

const ContentDiv = styled.div`
  display: flex;
  flex: 1;
  background-color: #fff;
  padding: 140px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

const Text = styled.span`
  color: var(--G6, #97a4b7);
  font-variant-numeric: lining-nums tabular-nums;
  /* Text 16 Reg */
  font-family: "Golos Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

import { useMemo } from "react";
import { useAppContext } from "../../../AppContext";

interface IResult {
  steps: number[];
  activeStep: number | undefined;
}

export const useSteps = (
	positionPercent: number | undefined
): IResult => {
  const {
    store: { detailStore },
  } = useAppContext();

  const steps = useMemo(() => {
    if (!detailStore?.workoutDetails?.length) {
      return []
    }

    let steps = detailStore.workoutDetails.map((w: any) => w.distance)
    const end = steps[steps.length - 1];
    const isLastStepLowThanOneHalfStep = end - steps[steps.length - 2] < 1;

    if (isLastStepLowThanOneHalfStep) {
      steps = steps.slice(0, steps.length - 1)
    }

    return steps
  }, [detailStore.workoutDetails])

  const activeStep = useMemo(() => {
    if (!positionPercent) {
      return;
    }

    const step = steps.find((s, idx) =>
      positionPercent >= (idx) / (steps.length - 1)
      && positionPercent < (idx + 1) / (steps.length - 1)
    )
    return step
  }, [positionPercent, steps])

	return {
    steps,
    activeStep,
  }
}

import { Box } from "@mui/system";
import { useAppContext } from "../../../../AppContext";
import { observer } from "mobx-react";
import { Tabs } from '../../../Tabs/Tabs';
import { WorkoutPlanType } from "../../types";
import { usePlanInputs } from "./hooks/usePlanInputs";
import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";

const TABS_ITEMS = ['План', 'Факт'];

const TrainingData = () => {
  const { store } = useAppContext();

  const { workoutContentData, factWorkoutContentData, workoutData } =
    store.workoutStore;
  const tabState = store.workoutStore.displayState.workoutTabState;
  const tabIdx = tabState + 1;
  const [value, setValue] = useState(workoutContentData);

  useEffect(() => {
    console.log(tabIdx, JSON.stringify(workoutContentData), JSON.stringify(factWorkoutContentData));
    if (tabIdx === WorkoutPlanType.plan) {
      setValue(workoutContentData as any);
      return;
    }

    setValue(factWorkoutContentData);
  }, [tabIdx, tabState, factWorkoutContentData, workoutContentData]);

  const editValue = useCallback((key: string, v: any) => {
    if (tabIdx === WorkoutPlanType.plan) {
      store.workoutStore.setWorkoutContentData(key, v);
    } else {
      store.workoutStore.setFactWorkoutContentData(key, v);
    }

    setValue((prev) => ({
      ...prev,
      [key]: v,
    }));
  }, [tabIdx, store.workoutStore, setValue]);
  const inputs = usePlanInputs(value, editValue, tabState, workoutData.type);

  return (
    <Box sx={{ marginTop: "24px", paddingLeft: "0px" }}>
      <Tabs
        items={TABS_ITEMS}
        value={tabState}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#FF3147",
          },
        }}
        onChange={(e, a) => {
          store.workoutStore.setWorkoutTabState(a)
        }}
      />
      <SWrapper>
        {inputs}
      </SWrapper>
    </Box>
  );
};

export default observer(TrainingData);

const SWrapper = styled.div`
  margin-top: 24px;
  display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px 32px;
`

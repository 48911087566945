import { Button, Modal } from "@mui/material";
import { FC, ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ReactComponent as RoadIcon } from '../../assets/icons/road.svg';
import styled from '@emotion/styled';
import { useTranslation } from "react-i18next";

interface IProps {
  children: ReactNode;
}

export const ErrorProvider: FC<IProps> = ({ children }) => {
  const FallbackWithChildren = (props: Record<string, any>) => (
    <>
      <Fallback {...props} />
      {children}
    </>
  )

  return (
    <ErrorBoundary fallbackRender={FallbackWithChildren} onError={(e) => {
      console.log('err', e)
    }}>
      <>
        {children}
      </>
    </ErrorBoundary>
  )
}

const Fallback: FC<any> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();
  const message = error.message === 'Network Error' ? t('networkError') : error.message;

  return (
    <Modal open={error} onClose={resetErrorBoundary}>
      <SWrapper>
        <SContent>
          <RoadIcon />
          <STitle>{t('errorTitle')}</STitle>
          <SDescription>{message}</SDescription>
          <SCloseButton
            onClick={resetErrorBoundary}
            variant="contained"
            color="primary"
          >
            {t('close')}
          </SCloseButton>
        </SContent>
      </SWrapper>
    </Modal>
  )
}

const SWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: #fff;
  border-radius: 8px;
  padding: 32px 40px;
`

const SContent = styled.div`
  display: flex;
  gap: 16px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 520px;
`

const SCloseButton = styled(Button)`
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
  padding: 12px 16px;
  border: 1px solid;
  line-height: 1.5;
  background-color: #FF3147;
  border-color: #FF3147;
  border-radius: 8px;
  min-width: 120px;
  box-sizing: border-box;
  font-size: 14px;

  &:hover {
    background-color: #d31818;
    box-shadow: none;
  }
`

const STitle = styled.h2`
  padding: 0;
  font-weight: 500;
  margin: 0;
`

const SDescription = styled.div`
  font-size: 16px;
`

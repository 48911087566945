import styled from "@emotion/styled";
import {Button} from "@mui/material";

export type ButtonProps = {
    onClick?: () => void;
    children?: React.ReactNode;
}

export default (props: ButtonProps) =>
    <SButton onClick={props.onClick}>
        {props.children}
    </SButton>

const SButton = styled(Button)`
  font-size: 14px;
  border-radius: 6px;
  padding: 6px 21px;
  box-sizing: border-box;
  background: #DBE4F0;
  color: #1A222D;
  text-transform: capitalize;

  &:hover {
    background-color: #DBE4F0;
  }

  &:focus {
    background-color: #DBE4F0;
  }
`;

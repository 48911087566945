import { Box } from "@mui/system";
import { WorkoutData } from "../../../../stores/WorkoutStore";

import WorkoutDataComponent from "../../../common/workout/WorkoutData";
import { getItemDimensions } from "../../calendar/utils";

export default ({ item }: { item: WorkoutData }) => {
  const data = JSON.parse(item.plan) || {};
  const factData = JSON.parse(item.fact) || {};
  const indicators = item?.indicators ? JSON.parse(item.indicators) || {} : {};
  const dimensions = getItemDimensions(item.type);

  return (
    <Box sx={styles.container}>
      <WorkoutDataComponent
        dimensions={dimensions}
        data={data}
        factData={factData}
        indicators={indicators}
      />
    </Box>
  );
};

const styles = {
  container: {},
};




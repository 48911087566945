import AthleteInfoComponent from "./AthleteInfoComponent";
import CalendarControl from "./CalendarControl";
import Events from "./Events";
// import { currentDate } from "react-declarative/utils/datetime";
import { Dayjs } from "dayjs";
import { FC } from "react";
import styled from "@emotion/styled";

interface IProps {
  onTodayClick: () => void;
  onDateChange: (date: Date) => void;
  currentDate: Date;
}

const UserInfo: FC<IProps> = ({ onTodayClick, onDateChange, currentDate }) => {
  return (
    <SWrapper>
      <div></div>
      <CalendarControl
        onTodayClick={onTodayClick}
        onDateChange={onDateChange}
        currentDate={currentDate}
      />
      <SRow>
        <Events />
        <AthleteInfoComponent />
      </SRow>
    </SWrapper>
  );
};

export default UserInfo;

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
`;

const SRow = styled.div`
  display: flex;
  align-self: center;
  height: 32px;
  gap: 0 20px;
`;

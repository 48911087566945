import styled from "@emotion/styled";
import { WorkoutData } from "../../../../stores/WorkoutStore";
import IconByType from "../../../common/IconByType";
import { WorkoutType } from "../../types";
import { ReactComponent as PulseIcon } from "../../../../assets/icons/pulse.svg";
import dayjs from "dayjs";
import { fromSeconds } from "../../calendar/utils";

export default ({ item }: { item: WorkoutData }) => {
  const factData = JSON.parse(item.fact);
  const planData = JSON.parse(item.plan);
  const indicators = item?.indicators ? JSON.parse(item.indicators) : {};
  const hasFact = factData && Object.keys(factData)?.length > 0;
  const data = hasFact ? factData : planData;
  const speed = data?.pace ? fromSeconds(data?.pace).format("m:ss") : undefined;
  const time = data?.time
    ? fromSeconds(data?.time).format("HH:mm:ss")
    : undefined;
  const indicatorsPulse = indicators?.heart_rate?.max
    ? ((indicators.heart_rate.max + indicators.heart_rate.min) / 2).toFixed()
    : undefined;
  const pulse = hasFact ? indicatorsPulse : data["pulse"];

  return (
    <SWrapper>
      <SIcon>
        {IconByType({ type: WorkoutType.running })} {item.name || "кросс"}
      </SIcon>
      <SDate>{dayjs(item.startDate).locale("ru").format("DD MMMM YYYY")}</SDate>
      <SStat>
        <SRow>
          <SStatItem>
            {!!data?.distance && <span>{data?.distance} км</span>}
          </SStatItem>
          <SStatItem>{!!speed && <span>{speed} / км</span>}</SStatItem>
        </SRow>
        <SRow>
          <SStatItem>{!!time && time}</SStatItem>
          <SStatItem>
            {!!pulse && (
              <SPulse>
                <PulseIcon />
                {pulse}
              </SPulse>
            )}
          </SStatItem>
        </SRow>
      </SStat>
    </SWrapper>
  );
};

const SStatItem = styled.span`
  // max-width: 50px;
  width: 60px;
  padding: 0 0 6px 0;
  display: flex;
`;

const SIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SStat = styled.div``;

const SDate = styled.div`
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SPulse = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`;

const SWrapper = styled.div`
  justify-content: space-between;
  flex: 1;
  display: flex;
  padding-bottom: 42px;

  color: var(--Black, #1a222d);
  /* Txt 12 Reg */
  font-family: Golos Text;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SRow = styled.div`
  flex: 1;
  display: flex;

  color: var(--Black, #1a222d);
  /* Txt 12 Reg */
  font-family: Golos Text;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
import { FC } from "react";
import { DateInput, IDateInputProps } from "./components/DateInput/DateInput";
import { HoursInput, IHoursInputProps } from "./components/HoursInput/HoursInput";
import { IMinutesInputProps, MinutesInput } from "./components/MinutesInput/MinutesInput";
import { INumberInputProps, NumberInput } from "./components/NumberInput/NumberInput";
import { SelectInput, ISelectInputProps } from "./components/SelectInput/SelectInput";
import { ITextInputProps, TextInput } from "./components/TextInput/TextInput";

type IProps = IDateInputProps | ITextInputProps | IHoursInputProps | IMinutesInputProps | INumberInputProps | ISelectInputProps;

export const Input: FC<IProps> = (props) => {
  const { inputType } = props;

  switch(inputType) {
    case 'date':
      return <DateInput {...props as any} inputType={inputType} />;
    case 'minutes':
      return <MinutesInput {...props as any} inputType={inputType} />;
    case 'hours':
      return <HoursInput {...props as any} inputType={inputType} />;
    case 'number':
      return <NumberInput {...props as any} inputType={inputType} />;
    case 'select':
      return <SelectInput {...props as any} inputType={inputType} />;
    case 'text':
    default:
      return <TextInput {...props as any} inputType={inputType} />
  }
}

import styled from "@emotion/styled";
import * as d3 from "d3";
import { FC, memo, RefObject } from 'react';
import { useChart } from "./hooks/useChart";
import { useCursorInfo } from "./hooks/useCursorInfo";
import { TLine } from "./types/TLine";

interface IProps {
	multiplier?: number;
	reverse?: boolean;
	isRoundLine?: boolean;
	data: number[];
	hoverX: RefObject<number | undefined>;
	suffix: string;
	positionPercent?: number;
	getPrefix?: (index: number) => string;
}

export const Graph: FC<IProps> = memo(({
	multiplier,
	isRoundLine = false,
	data,
	hoverX,
	suffix,
	positionPercent,
	getPrefix,
	reverse = false,
}) => {
	const onChartlineChange = (line: TLine) => {
		if (isRoundLine) {
			line.curve(d3.curveCatmullRom.alpha(0.3))
		}
	}

	const { chart, x, y, ref } = useChart(data, onChartlineChange, reverse, multiplier);
	const { virtualTextRef } = useCursorInfo({
		chart, 
		hoverX,
		ref,
		x,
		y,
		data,
		suffix,
		positionPercent,
		getPrefix,
	});

return (
	<SWrapper>
		<div ref={virtualTextRef} style={{ visibility: 'hidden' }} />
		<svg
			ref={ref}
			style={{
				position: 'relative',
				flexGrow: 1,
				height: '100%',
			}}
		>
		</svg>
	</SWrapper>
)
})

const SWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
`

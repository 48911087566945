import { Dayjs } from "dayjs";
import { useEffect } from "react";
import { getIdByDate } from "../utils";

export const useCurrentDateScroll = (
  currentDate: Dayjs
) => {
  useEffect(() => {
    if (currentDate) {
      const project = document.getElementById(getIdByDate(currentDate));
      if (!project) {
        return;
      }

      /* setTimeout(() => {
        project.scrollIntoView({
          block: "center",
          inline: "start",
          behavior: "smooth",
        });
      }, 0) */

      console.log(currentDate);
    }
  }, [currentDate]);
}

import { startOfMonth, endOfMonth } from "date-fns";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import dayjs from 'dayjs';
import { useAppContext } from "../../../../AppContext";
import { getCellIdByDate } from "../utils/getCellIdByDate";
import { getDateByCellId } from "../utils/getDateByCellId";

export interface IResult {
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
}

const getInitialTargetDate = (): Date => {
    const searchParams = new URLSearchParams(window.location.search)
    const cellId = searchParams.get('day');

    if (!cellId) {
      return new Date();
    }

    const queryDate = getDateByCellId(cellId);

    return queryDate;
}

/**
 * Отвечает за позиционирование скроллинга в календаре
 */
export const useCalendarTarget = (): IResult => {
  const { store, api } = useAppContext()
  const currentDate = store.workoutStore.currentDate;
  const [targetDate, setTargetDate] = useState<Date>(getInitialTargetDate());

  const firstLoad = useRef(true);

  useEffect(() => {
    if (!currentDate) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search)
    const cellId = searchParams.get('day');

    if (!cellId) {
      return;
    }

    if (firstLoad.current && targetDate) {
      firstLoad.current = false;
      return;
    }
  }, [currentDate, targetDate])

  const isFirstMount = useRef(true);
  useEffect(() => {
    if (!targetDate || !store.coachStore.currentAthleteId) {
      return;
    }

    const startDate = dayjs(startOfMonth(targetDate)).add(-1, 'month').toDate()
    const endDate = dayjs(endOfMonth(targetDate)).add(1, 'month').toDate()
    console.log('s', startDate, endDate)
    api.workoutApi.loadMyWorkouts(
      startDate,
      endDate,
    );
    store.workoutStore.setCalendarDateStart(startDate);
    startDate.setMonth(startDate.getMonth() + 1)
    store.workoutStore.setSchedulerCurrentDate(dayjs(startDate))
    const cellId = getCellIdByDate(targetDate);
    const calendar = document.getElementById('calendar');

    if (!calendar) {
      return;
    }

    const cell = calendar.querySelector('#cell-' + cellId);
    console.log('#cell-' + cellId, targetDate, cell)

    if (!cell) {
      return;
    }

    cell.scrollIntoView({
      block: "start",
      inline: "start",
      behavior: "auto",
    });

    isFirstMount.current = false;
  }, [targetDate, api.workoutApi, store.workoutStore, store.coachStore.currentAthleteId])

  return {
    date: targetDate,
    setDate: setTargetDate,
  }
}

import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useRef } from "react";
import { getCellIdByDate } from '../utils/getCellIdByDate';
import { insertUrlParam } from '../utils/insertUrlParam';
import { getDateByCellId } from '../utils/getDateByCellId';


interface IResult {
  tableRef: React.RefObject<HTMLDivElement>;
}

export const useCalendarScroll = (
  date: Date | undefined,
  setDate: (date: Date) => void
): IResult => {
  const tableRef = useRef<HTMLDivElement>(null);

  const loadedRef = useRef(true);
  const firstLoad = useRef(true);

  useEffect(() => {
    if (!firstLoad.current) {
      return;
    }

    firstLoad.current = false;

    const tableEl = tableRef.current;

    if (!tableEl || !loadedRef.current || !date) {
      return;
    }
    let searchParams = new URLSearchParams(window.location.search);
    const cellId = searchParams.get('day');

    if (!cellId) {
      return;
    }

    const newDate = getDateByCellId(cellId);
    setDate(newDate)
  }, []);

  useEffect(() => {
    if (!date) {
      return;
    }

    insertUrlParam('day', 'cell-' + getCellIdByDate(date))
  }, [date])

  const scrollListener = useCallback(() => {
    const tableEl = tableRef.current;
    console.log("load", tableEl);

    if (!tableEl || !loadedRef.current) {
      loadedRef.current = true;
      return;
    }

    const getCellByDate = (d: Dayjs) => {
      const cellId = "cell-" + getCellIdByDate(d.toDate()!);
      return document.getElementById(cellId);
    };

    const topEl = getCellByDate(dayjs(date).set("date", 1));
    console.log("scroll", topEl!.offsetTop, tableEl.scrollTop);
    const endOfMonth = dayjs(date)
      .add(-1, "month")
      .endOf("month")
      .add(date ? -date.getDay() : 0, "days");
    const topMonthEl = getCellByDate(endOfMonth);
    const isTopMonth = topMonthEl!.offsetTop > tableEl.scrollTop;
    const startOfMonth = dayjs(date).add(1, "month").startOf("month");
    const bottomMonthEl = getCellByDate(startOfMonth);
    const isBottomMonth = bottomMonthEl!.offsetTop < tableEl.scrollTop;

    if (isTopMonth) {
      console.log('istop')
      setDate(endOfMonth.toDate());
    }
    else if (isBottomMonth) {
      console.log('istbott')
      setDate(startOfMonth.toDate());
    }
    /* if (date) {
      setDate(dayjs(date).startOf('month').toDate())
    } */
    /* if (isTop) {
      const d = dayjs(date).add(-1, 'month').set('date', 30);
      const cellId = 'cell-' + getCellIdByDate(dayjs(d).toDate()!)
      loadedRef.current = false;
      const el = document.getElementById(cellId)
      setTimeout(() => {
        el?.scrollIntoView()
      }, 10)
      // store.workoutStore.setSchedulerCurrentDate(nextTopDate)
    }
    else if (isBottom) {
      const d = dayjs(date).add(1, 'month').set('date', 1);
      const cellId = 'cell-' + getCellIdByDate(dayjs(d).toDate()!)
      loadedRef.current = false;
      const el = document.getElementById(cellId)
      setTimeout(() => {
        el?.scrollIntoView()
      }, 10)
      // store.workoutStore.setSchedulerCurrentDate(nextBottomDate)
    } */

    /* if (loadedRef.current === false) {
      setTimeout(() => {
        loadedRef.current = true;
      }, 300)
    } */
  }, [tableRef, date, loadedRef, setDate]);


  const firstLoadRef = useRef(true);

  /* useEffect(() => {
    if (firstLoadRef.current) {
      firstLoadRef.current = false;
      return;
    }

    scrollListener()
  }, [currentDate, scrollListener]) */

  useEffect(() => {
    const tableEl = tableRef.current;

    if (!tableEl) {
      return;
    }

    tableEl.addEventListener("scroll", scrollListener);

    return () => {
      tableEl.removeEventListener("scroll", scrollListener);
    };
  }, [tableRef, scrollListener]);


  return {
    tableRef,
  }
}

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import 'moment/locale/ru';
import { SnackbarProvider } from 'notistack';

import { ModalProvider } from 'react-declarative';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { TssCacheProvider } from 'tss-react';
import AppApi from './apis/AppApi';


import AppContext from './AppContext';
import App from './components/App';

import { THEME_LIGHT } from './config/theme';
import { LoaderProvider } from './hooks/useLoader';

import './polyfills';
import AppStore from './stores/Store';
import { initI18next } from './utils/i18n';
import { TitleWrapper } from './components/TitleWrapper/TitleWrapper';
import { ErrorProvider } from './components/ErrorProvider/ErrorProvider';
import { FlagProvider } from "@unleash/proxy-client-react";
import Metrics from "./components/common/Metrics";

moment.locale("ru");

initI18next();
const container = document.getElementById("root")!;

const muiCache = createCache({
  key: "mui",
  prepend: true,
});

const tssCache = createCache({
  key: "tss",
});

const config = {
  url: process.env.REACT_APP_IS_DEVELOPMENT === '1' ? "https://dev.yna.run/back/unleash-proxy" : "https://yna.run/back/unleash-proxy", // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: "yna-unleash-proxy-secret", // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 15, // How often (in seconds) the client should poll the proxy for updates
  appName: "fe-web", // The name of your application. It's only used for identifying your application
};

const store = new AppStore();
const api = new AppApi(store);

const wrappedApp = (
  <AppContext.Provider value={{ api, store }}>
    <ErrorProvider>
      <CacheProvider value={muiCache}>
        <TssCacheProvider value={tssCache}>
          <ThemeProvider theme={THEME_LIGHT}>
            <LocalizationProvider
              adapterLocale={"ru"}
              dateAdapter={AdapterDayjs}
            >
              <TitleWrapper>
                <ModalProvider>
                  <SnackbarProvider>
                    <LoaderProvider>
                      <BrowserRouter>
                        <FlagProvider config={config}>
                          <Metrics>
                            <App />
                          </Metrics>
                        </FlagProvider>
                      </BrowserRouter>
                    </LoaderProvider>
                  </SnackbarProvider>
                </ModalProvider>
              </TitleWrapper>
            </LocalizationProvider>
          </ThemeProvider>
        </TssCacheProvider>
      </CacheProvider>
    </ErrorProvider>
  </AppContext.Provider>
);

const root = createRoot(container);

root.render(wrappedApp);
 
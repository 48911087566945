import styled from "@emotion/styled";
import Sex from "./components/Sex";
import City from "./components/City";
import Name from "./components/Name";
import Date from "./components/Date";
import Weight from "./components/Weight";
import { Button } from "@mui/material";
import { useAppContext } from "../../AppContext";
import { observer } from "mobx-react";
import About from "./components/About";
import LoginPassword from "./components/LoginPassword";

export default observer(() => {
  const {
    store: { profileStore, authStore },
  } = useAppContext();

  const defaultAvatar = `${process.env.REACT_APP_BASE_URL}/users/api/v1/users/${authStore.currentUser?.id}/avatar`;

  const handleUploadClick = (event: any) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e: any) {
      profileStore.setCurrentFile([reader.result]);
    };
    profileStore.setCurrentFile(file);
  };

  return (
    <SWrapper>
      <SImageRow>
        <SAvatar>
          <label htmlFor="contained-button-file">
            <Button variant="text" component="span">
              <SInput
                style={{ display: "none" }}
                accept="image/*"
                id="contained-button-file"
                multiple={false}
                type="file"
                hidden
                onChange={handleUploadClick}
              />
              {profileStore.profileFile == null && (
                <SImage src={defaultAvatar} />
              )}
              {profileStore.profileFile != null && (
                <SImage src={profileStore.profileFile} />
              )}
            </Button>
          </label>
        </SAvatar>
        <div></div>
        <SContent>
          <SRow>
            <SField>
              <Name
                value={profileStore.profileFormData.fullName}
                onChange={(value) =>
                  profileStore.setFormData("fullName", value)
                }
              />
            </SField>
            <SRightField>
              <Date
                value={profileStore.profileFormData.birthDate}
                onChange={(value) =>
                  profileStore.setFormData("birthDate", value)
                }
              />
            </SRightField>
          </SRow>
          <SRow>
            <SField>
              <Weight
                value={profileStore.profileFormData.weight}
                onChange={(value) => profileStore.setFormData("weight", value)}
              />
            </SField>
            <SRightField>
              <Sex
                value={profileStore.profileFormData.sex}
                onChange={(value) => profileStore.setFormData("sex", value)}
              />
            </SRightField>
          </SRow>
          <SRow>
            <City
              value={profileStore.profileFormData.city}
              onChange={(value) => profileStore.setFormData("city", value)}
            />
          </SRow>
        </SContent>
      </SImageRow>
      <SAboutRow>
        <About
          value={profileStore.profileFormData.about}
          onChange={(value) => profileStore.setFormData("about", value)}
        ></About>
      </SAboutRow>
      <LoginPassword />
    </SWrapper>
  );
});

const SImageRow = styled.div`
  padding: 0 50px;
  display: flex;
  flex: 1;
  direction: row;
  justify-content: space-between;
`;

const SAboutRow = styled.div`
  padding: 0 50px;
  display: flex;
  flex: 1;
  // direction: row;
  // justify-content: space-between;
`;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding: 24px 24px 0 24px;
  // align-items: center;
`;
const SAvatar = styled.div`
  display: flex;
  align-self: left;
`;

const SContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
`;

const SField = styled.div`
  // width: 155px;
`;
const SRightField = styled.div`
  padding-left: 40px;
  // width: 155px;
`;

const SInput = styled.input`
  display: "none";
`;

const SImage = styled.img`
  width: 110px;
  height: 110px;
  margin: "auto";
  display: "block";
  maxwidth: "100%";
  maxheight: "100%";
  border-radius: 110px;
`;




import * as d3 from "d3";
import { useState, useMemo, useEffect, useRef, RefObject, Dispatch } from "react";
import { TChart } from "../types/TChart";
import { TLine } from "../types/TLine";
import { TPoint } from "../types/TPoint";
import { useChartline } from "./useChartline";

interface IResult {
	ref: RefObject<SVGSVGElement>;
	chart: TChart | undefined;
	x: TPoint | undefined;
	y: TPoint | undefined;
}

export const useChart = (
	data: number[],
	onChartlineChange?: Dispatch<TLine>,
	reverse = false,
	multiplier?: number,
): IResult => {
	const ref = useRef<SVGSVGElement>(null);
	const [hasRef, setHasRef] = useState(false);
	const average = (Math.max(...data) + Math.min(...data)) / 2;

	const chart = useMemo(() => {
		const svgEl = ref.current;
		if (!svgEl || !hasRef) {
			return;
		}
		const element = d3.select(svgEl);
		element.selectAll("*").remove();
		return element;
	}, [ref, hasRef])

	useEffect(() => {
		const svgEl = ref.current;
		if (svgEl && !hasRef) {
			setHasRef(true)
		}
	}, [ref, hasRef])

	const x = useMemo(() => {
		const svgEl = ref.current;
		if (!chart || !svgEl) {
			return;
		}

		const width = svgEl.clientWidth;
		return d3.scaleLinear()
			.domain(d3.extent(data, (d, idx) => idx) as any)
			.range([0, width]);
	}, [chart, ref, data])

	const y = useMemo(() => {
		const svgEl = ref.current;
		let filteredData = data.filter((d) => d > 0);
		const min = Math.min(...filteredData)
		const newData = data.map((d) => d === 0 ? min : d)
		if (!chart) {
			return;
		}
		let domain = [
			d3.min(newData, (d) => d)!,
			d3.max(newData, (d) => d)!,
		]
		const height = svgEl!.clientHeight;
		let range = [height, 0]

		if (reverse) {
			domain = domain.reverse().map((d) => d);
			// range = range.reverse().map((d) => d);
			// console.log(domain, range, data.sort((d1, d2) => d2 - d1))
		}
		if (multiplier) {
			domain = domain.map((d) => d * multiplier);
		}

		const yScale = d3.scaleLinear()
			.domain(domain)
			.range(range);

		return yScale;
	}, [ref, data, chart, multiplier])

	useChartline({
		x,
		y,
		chart,
		ref,
		onChartlineChange,
		data,
		average,
		reverse,
	})

	return {
		ref,
		chart,
		x,
		y,
	}
}

import { action, makeAutoObservable, observable } from "mobx";
import Store from "./Store";

type ProfileForm = {
  fullName: string;
  sex: string;
  birthDate: string;
  weight: string;
  city: string;
  about: string;
  password?: string | undefined;
  passwordConfirm?: string | undefined;
};

export default class ProfileStore {
  isProfileOpen: boolean = false;
  isCoach: boolean = false;
  profileFile: string | null = null;

  profileFormData: ProfileForm = {
    fullName: "",
    city: "",
    sex: "m",
    weight: "",
    birthDate: "",
    about: "",
    passwordConfirm: undefined,
    password: undefined,
  };

  constructor(private store: Store) {
    makeAutoObservable(this, {
      setIsProfileOpen: action.bound,
      isProfileOpen: observable,
      isCoach: observable,
      profileFormData: observable,
      setIsCoach: action.bound,
      setFormData: action.bound,
      getFormData: action.bound,
      setPassword: action.bound,
      setPasswordConfirm: action.bound,
    });
  }
  setIsProfileOpen(isProfileOpen: boolean) {
    this.isProfileOpen = isProfileOpen;
  }

  setIsCoach(isCoach: boolean) {
    this.isCoach = isCoach;
  }

  setCurrentFile(file: any) {
    this.profileFile = file;
  }

  setFormData(key: string, value: any) {
    //@ts-ignore
    this.profileFormData[key] = value;
  }
  getFormData(key: string): any {
    //@ts-ignore
    return this.profileFormData[key];
  }

  setAllFormData(formData: ProfileForm | null | undefined) {
    if (formData == null) return;
    this.profileFormData = formData;
  }

  setPassword(password: string) {
    this.profileFormData.password = password;
  }

  setPasswordConfirm(password: string) {
    this.profileFormData.passwordConfirm = password;
  }
}

import styled from "@emotion/styled";
import { WorkoutData } from "../../../../stores/WorkoutStore";
import { CustomWorkoutCard } from "../../calendar/components/WorkoutCard/components/CustomWorkoutCard/CustomWorkoutCard";


export default ({
  item,
  onlyPlan,
}: {
  item: WorkoutData;
  onlyPlan: boolean;
}) => {
  return (
    <SWrapper>
      <CustomWorkoutCard onlyPlan={onlyPlan} item={item}></CustomWorkoutCard>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  cursor: pointer;
  width: 166px;
`;

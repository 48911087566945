import { makeStyles } from "../../styles";
import { ReactComponent as ExpandIcon } from "../../assets/icons/expand_map.svg";
import { ReactComponent as CollapseIcon } from "../../assets/icons/collapse_map.svg";
import { Button } from "@mui/material";
import { useCallback } from "react";
import { useAppContext } from "../../AppContext";

const useStyles = makeStyles()({
  container: {
    position: "absolute",
    top: "5px",
    right: "5px",
    zIndex: 10000,
  },
});
export default () => {
  const styles = useStyles();
  const {
    store: { detailStore },
  } = useAppContext();
  const expondMapOp = useCallback(() => {
    detailStore.setMapExpanded(detailStore.isMapExpanded ? false : true);
  }, [detailStore]);

  return (
    <Button className={styles.classes.container} onClick={expondMapOp}>
      {detailStore.isMapExpanded ? <CollapseIcon /> : <ExpandIcon />}
    </Button>
  );
};

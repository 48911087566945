import { useEffect } from "react";

export const useTodayScroll = (trigger: boolean) => {
  useEffect(() => {
    if (trigger) {
      const project = document.getElementById("today");
      if (!project) {
        return;
      }

      project.scrollIntoView({
        block: "center",
        inline: "start",
        behavior: "smooth",
      });
    }
  }, [trigger]);
}

import styled from "@emotion/styled";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';

export type SettingsHeaderProps = {
    onClose: () => void;
    subTitle?: string;
}

export default (props: SettingsHeaderProps) => {
  return (
    <SHeader>
      <SettingsIcon />
      <SWrapper>
          <SLeftWrapper>
              <SFontNormal>Настройки</SFontNormal>
              {props.subTitle && <>
                  <SFontNormal> - </SFontNormal>
                  <SFontBold>{props.subTitle}</SFontBold>
              </>}
          </SLeftWrapper>
      </SWrapper>
      <SCloseIcon onClick={props.onClose}>
        <CloseIcon />
      </SCloseIcon>
    </SHeader>
  );
};

const SWrapper = styled.div`
  color: #252b4b;
  font-family: Golos Text;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
`;

const SLeftWrapper = styled.div`
    text-align: left;
    padding-left: 10px;
`;

const SHeader = styled.div`
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SCloseIcon = styled.div`
  cursor: pointer;
`;

const SFontNormal = styled.span`
    font-weight: normal;
`;

const SFontBold = styled.span`
    font-weight: bold;
`;
import { FC, useMemo } from "react";
import { WorkoutData } from "../../../../../../../stores/WorkoutStore";
import FullTotal from "../../../../../total/FullTotal";
import TotalItem from "../../../../../total/TotalItem";
import { WorkoutType } from "../../../../../types";
import { IRow } from "../../../../types/IRow";

interface IProps {
  row: IRow;
  events: WorkoutData[];
}

export const WeeklyTotal: FC<IProps> = ({ row, events }) => {
  const weeklyEvents = useMemo(
    () =>
      events.filter((x) => {
        return (
          row.dates[0].date.startOf("day").isBefore(x.startDate) &&
          row.dates[row.dates.length - 1].date.endOf("day").isAfter(x.startDate)
        );
      }),
    [row.dates, events]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div style={{ padding: 8, flexShrink: "0" }}>
        <TotalItem type={WorkoutType.running} items={weeklyEvents} />
        <TotalItem type={WorkoutType.cycling} items={weeklyEvents} />
        <TotalItem type={WorkoutType.swimming} items={weeklyEvents} />
        <TotalItem type={WorkoutType.powerTraining} items={weeklyEvents} />
      </div>
      <div style={{}}></div>
      <div style={{ backgroundColor: "#F8FAFC", padding: "0 16px" }}>
        <FullTotal items={weeklyEvents} />
      </div>
    </div>
  );
};

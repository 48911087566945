import { useState } from "react";

interface IResult {
	onMouseMove: (percent: number) => void;
	onMouseOver: VoidFunction;
	positionPercent: number | undefined;
}

export const useChartHover = (): IResult => {
	const [positionPercent, setPositionPercent] = useState<number>();

	const onMouseOver = () => {
		setPositionPercent(undefined)
	}

	return {
		onMouseMove: setPositionPercent,
		onMouseOver,
		positionPercent,
	}
}

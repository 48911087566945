import { Box, Modal } from "@mui/material";
import { useAppContext } from "../../AppContext";
import { observer } from "mobx-react";
import Header from "./Header";
import Content from "./Content";
import Buttons from "./Buttons";

export default observer(() => {
  const {
    store,
    api: { profileApi },
  } = useAppContext();
  profileApi.loadCurrentProfileData();
  return (
    <Modal
      style={{
        // height: "800px",
        top: "1%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
        position: "absolute",
      }}
      open={store.profileStore.isProfileOpen}
      onClose={() => store.profileStore.setIsProfileOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: 698,
          height: "760px",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFF",
        }}
      >
        <Header onClose={() => store.profileStore.setIsProfileOpen(false)} />
        <Content />
        <Buttons />
      </Box>
    </Modal>
  );
});

import styled from "@emotion/styled";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

export default ({ onClose }: { onClose: () => void }) => {
  return (
    <SHeader>
      <SWrapper>Мой профиль</SWrapper>
      <SCloseIcon onClick={onClose}>
        <CloseIcon />
      </SCloseIcon>
    </SHeader>
  );
};

const SWrapper = styled.div`
  color: #252b4b;
  font-family: Golos Text;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 22px;
`;

const SHeader = styled.div`
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--G3-line, #dbe4f0);
  align-items: center;
`;

const SCloseIcon = styled.div`
  cursor: pointer;
`;
import { ListItemIcon, MenuItem, Select, SelectProps } from "@mui/material"
import { FC, ReactNode } from "react";
import { BaseInput, IBaseInputProps } from "../BaseInput/BaseInput";

export interface ISelectInputItem {
  value: string;
  id: string;
  leftContent?: ReactNode;
}

export interface ISelectInputProps extends Omit<SelectProps, 'label'>, IBaseInputProps {
  inputType: 'select';
  items: ISelectInputItem[];
  value?: any;
  placeholder?: string;
}

export const SelectInput: FC<ISelectInputProps> = (props) => {
  const { items, ...p } = props;

  return (
    <BaseInput
      as={Select}
      size="small"
      style={{ height: "36px", backgroundColor: "#EEF5FB" }}
      {...p}
    >
      {items.map(({ leftContent, value, id }) => (
        <MenuItem key={id} value={id}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {!!leftContent && <ListItemIcon>{leftContent}</ListItemIcon>}
            {value}
          </div>
        </MenuItem>
      ))}
    </BaseInput>
  );
}

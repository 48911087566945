import { ReactComponent as SwimIcon } from '../img/swim.svg';
import { ReactComponent as CrossIcon } from "../img/cross.svg";
import { ReactComponent as VeloIcon } from "../img/velo.svg";
import { ReactComponent as PowerIcon } from "../../../../assets/icons/fitness.svg";

export const getIconByWorkoutType = (type: string) => {
  switch (type) {
    case 'running':
      return <CrossIcon height={24} />
    case 'cycling':
      return <VeloIcon height={24} />
    case 'swimming':
      return <SwimIcon height={24} />
    case 'powerTraining':
      return <PowerIcon height={24} />
  }
}

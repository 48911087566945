import { Button } from "@mui/material";
import { ReactComponent as AnalysisIcon } from "../../../../assets/icons/analysis.svg";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { ReactComponent as MsgIcon } from "../../../../assets/icons/msg.svg";

export default ({ id }: { id: string | undefined | null }) => {
  const navigate = useNavigate();
  const redirectToAnalysis = (id: string | undefined | null) => {
    navigate(`/analysis/${id}`);
  };

  return (
    <SWrapper>
      <Button
        style={{
          backgroundColor: "#EEF5FB",
          color: "#1A222D",
        }}
        onClick={() => redirectToAnalysis(id)}
        startIcon={<AnalysisIcon />}
      >
        Анализ
      </Button>
      {/* <SMsgIcon>
        <MsgIcon />
      </SMsgIcon> */}
    </SWrapper>
  )
}

const SWrapper = styled.div`
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SMsgIcon = styled.div`
  display: flex;
  cursor: pointer;
`

import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { Dispatch, MouseEvent, SetStateAction } from "react";
import { useAppContext } from "../../../../../AppContext";
import { WorkoutData } from "../../../../../stores/WorkoutStore";
import { Droppable } from "../../../../common/Droppable/Droppable";
import { WorkoutItem } from "../../../types";
import { WorkoutCard } from "../WorkoutCard/WorkoutCard";

const Day = observer(
  ({
    item,
    activeCardId,
    onSetActiveCardId,
    id,
    onRemoveActiveCardId,
  }: {
    id: string;
    item: WorkoutItem;
    activeCardId: string | undefined;
    onSetActiveCardId: Dispatch<SetStateAction<string | undefined>>;
    onRemoveActiveCardId: VoidFunction;
  }) => {
    const { store } = useAppContext();
    const events = store.workoutStore.items;
    const todayEvents = store.workoutStore.items.filter((x) => {
      return item.date.isSame(x.startDate, "day");
    });

    if (!events) {
      return null;
    }

    const editHandle = (e: any, item: WorkoutData) => {
      store.workoutStore.editWorkout(item);
      onRemoveActiveCardId();
      store.coachStore.setWorkoutModalOpen(true);
    };

    const confirmDeleteHandle = async () => {
      store.workoutStore.setDeleteDisplayModal(true);
    };

    const confirmUnmergeHandle = async () => {
      store.workoutStore.setUnmergeDisplayModal(true);
    };

    const confirmMergeHandle = async () => {
      store.workoutStore.setMergeDisplayModal(true);
    };

    const handleClick = (e: MouseEvent<HTMLDivElement>, id: string) => {
      if (!!activeCardId) {
        return;
      }

      onSetActiveCardId(id);
    };

    const handleClose = () => {
      onRemoveActiveCardId();
    };

    return (
      <Droppable id={id}>
        <SWrapper id={id}>
          <span>{item.day}</span>
          <SWorkoutCards>
            {todayEvents.map((item: WorkoutData) => (
              <WorkoutCard
                key={item.id}
                isActive={activeCardId === item.id}
                onClick={(e) => {
                  handleClick(e, item.id || "");
                }}
                onClose={handleClose}
                onEdit={editHandle}
                onDelete={() => confirmDeleteHandle()}
                workout={item}
                todayEvents={todayEvents}
                onUnmerge={() => confirmUnmergeHandle()}
                onMerge={() => confirmMergeHandle()}
              />
            ))}
          </SWorkoutCards>
        </SWrapper>
      </Droppable>
    );
  }
);

export default Day;

const SWorkoutCards = styled.div`
  width: 100%;
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 0;
`;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 500;
  color: #1a222d;
  justify-content: left;
  align-items: flex-start;
  margin-bottom: 14px;

  & span {
    font-family: Roboto Mono, sans-serif;
  }
`;

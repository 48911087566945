export const getGoodTime = (v: string, withHours = true): string => {
	if (!withHours) {
		v = '00:' + v;
	}

	let [hours, minutes, seconds] = v.split(':').map(Number);
	let result = '';

	if (seconds > 60) {
		minutes += Math.floor(seconds / 60);
		seconds = seconds % 60;
	}
	if (minutes > 60) {
		hours += Math.floor(minutes / 60);
		minutes = minutes % 60;
	}

	if (withHours) {
		result += `${String(hours).padStart(2, "0")}:`;
	}

	result += `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`

	return result
}

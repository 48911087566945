import { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from 'moment';
import { getGoodTime } from "../utils/getGoodTime";

interface IResult {
	value: string | undefined;
	setValue: Dispatch<SetStateAction<string>>;
}

export const useTimeInputValue = (
	date: Date | undefined,
	setDate: Dispatch<Date | undefined> | undefined,
	withHours = true,
): IResult => {
	const [value, setValue] = useState<string>(date ? moment(date).format('HH:mm:ss') : '');

	useEffect(() => {
		console.log('date update', date)
		const format = withHours ? 'HH:mm:ss' : 'mm:ss';
		setValue(date ? moment(Number(date) * 1000).utc().format(format) : '')
	}, [date])

	return {
		setValue,
		value,
	}
}

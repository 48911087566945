import styled from "@emotion/styled";
import { FormControl, TextField, Typography } from "@mui/material";
import { useAppContext } from "../../../AppContext";
import { observer } from "mobx-react";

export default observer(() => {
  const {
    store: { profileStore },
  } = useAppContext();

  return (
    <SWrapper>
      <form>
        <SRow>
          <FormControl>
            <STitle>E-mail</STitle>
            <SInputDiv>
              <STextField
                size="small"
                fullWidth
                type="email"
                value={profileStore.getFormData("email")}
              ></STextField>
            </SInputDiv>
          </FormControl>
        </SRow>
        <SRow>
          <SPasswordRow>
            <FormControl>
              <STitle>Новый пароль</STitle>
              <SInputDiv>
                <STextField
                  size="small"
                  fullWidth
                  type="password"
                  value={profileStore.profileFormData.password}
                  onChange={(evt) => profileStore.setPassword(evt.target.value)}
                ></STextField>
              </SInputDiv>
            </FormControl>
            <SDivider />
            <FormControl>
              <STitle>Подтверждение пароля</STitle>
              <SInputDiv>
                <STextField
                  size="small"
                  fullWidth
                  type="password"
                  value={profileStore.profileFormData.passwordConfirm}
                  onChange={(evt) => {
                    profileStore.setPasswordConfirm(evt.target.value);
                  }}
                ></STextField>
              </SInputDiv>
            </FormControl>
          </SPasswordRow>
        </SRow>
      </form>
    </SWrapper>
  );
});

const STitle = styled(Typography)`
  color: var(--Black, #1a222d);
  font-family: Golos Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

const STextField = styled(TextField)`
  border-radius: 4px;
  background: var(--G2, #eef5fb);
  color: var(--G7, #7b8a9e);
`;
const SWrapper = styled.div`
  padding: 0 50px 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SPasswordRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const SRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
`;
const SInputDiv = styled.div`
  display: flex;
  height: 36px;
  padding: 12px 0 0 0;
  align-items: center;
  gap: 15px;
  align-self: stretch;
`;

const SDivider = styled.div`
  width: 40px;
`;

import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import styled from '@emotion/styled';

import dayjs, { Dayjs } from 'dayjs';
import isTodayPlugin from 'dayjs/plugin/isToday';
import objectPlugin from 'dayjs/plugin/toObject';
import weekdayPlugin from 'dayjs/plugin/weekday';
import { FC, RefObject } from 'react';
import { WorkoutData } from '../../../stores/WorkoutStore';
import { Row } from "./components/Row/Row";
import { useCurrentDateScroll } from "./hooks/useCurrentDateScroll";
import { useDragSchedulerCard } from "./hooks/useDragSchedulerCard";
import { useTodayScroll } from "./hooks/useTodayScroll";
import renderWeekDays from "./utils/renderDays";
import { IRow } from "./types/IRow";

dayjs.extend(objectPlugin);
dayjs.extend(isTodayPlugin);
dayjs.extend(weekdayPlugin);

interface IProps {
  onCalendarClick: (date: Dayjs) => void;
  events: WorkoutData[];
  // TODO: trigger must not be a number
  trigger: number;
  currentDate: Dayjs;
  rows: IRow[];
  tableRef: RefObject<HTMLDivElement>;
  setDate: (date: Date) => void;
  date: Date;
}

const CustomScheduler: FC<IProps> = ({
  onCalendarClick,
  rows,
  events,
  trigger,
  currentDate,
  tableRef,
  setDate,
  date,
}) => {
  useTodayScroll(!!trigger);
  useCurrentDateScroll(currentDate);

  const { dragEndHandle } = useDragSchedulerCard(rows);

  // TODO: add key id to rows
  // TODO: dont use render pattern in jsx
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  return (
    <SWrapper id="calendar">
      <DndContext onDragEnd={dragEndHandle} sensors={sensors}>
        <SWeeks>{renderWeekDays()}</SWeeks>
        <SContainer ref={tableRef}>
          {rows.map((row) => {
            return (
              <Row
                key={`${row.dates[0].day}_${row.dates[0].month}`}
                row={row}
                onCalendarClick={onCalendarClick}
              />
            );
          })}
        </SContainer>
      </DndContext>
    </SWrapper>
  );
};
// {loading && (
//   <CircularProgress style={{ position: "absolute", top: "100px" }} />
// )}

export default CustomScheduler;

const SWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`;

const SContainer = styled.div`
  flex-grow: 1;
  position: relative;
  overflow-y: scroll;
  height: calc(100vh - 100px);
`;

const SWeeks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

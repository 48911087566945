import { DateField, DateFieldProps, DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { FC } from 'react';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { BaseInput, IBaseInputProps } from '../BaseInput/BaseInput';

export interface IDateInputProps extends Omit<DateFieldProps<any>, 'label' | 'value'>, IBaseInputProps {
	inputType: 'date';
	value?: Date | Dayjs;
}

export const DateInput: FC<IDateInputProps> = (props) => {
	return (
		<BaseInput
			as={DesktopDatePicker}
			name="startDate"
			// icon={<CalendarIcon />}
			{...props}
			slots={{
				openPickerIcon: CalendarIcon
			}}
			value={props.value ? dayjs(props.value) : null}
		/>
	)
}

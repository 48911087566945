import { useAppContext } from "../../AppContext";
import { FC, RefObject, useCallback } from "react";
import Addworkout from "./addworkout";
import { Menu } from "@mui/material";
import CustomScheduler from "./calendar/CustomScheduler";
import { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import styled from '@emotion/styled';
import { IRow } from "./calendar/types/IRow";

interface IProps {
  rows: IRow[];
  tableRef: RefObject<HTMLDivElement>;
  setDate: (date: Date) => void;
  date: Date;
}

const Scheduler: FC<IProps> = ({ rows, tableRef, setDate, date }) => {
  const { store } = useAppContext();

  const onCalendarClick = useCallback(
    (date: Dayjs) => {
      store.workoutStore.setDateStart(date.toDate());
      store.workoutStore.reset();
      store.coachStore.setWorkoutModalOpen(true);
    },
    [store.coachStore, store.workoutStore]
  );

  return (
    <SWrapper>
      <Addworkout />
      <Menu open={false} />
      <CustomScheduler
        onCalendarClick={onCalendarClick}
        events={store.workoutStore.items}
        trigger={store.workoutStore.goToToday}
        currentDate={store.workoutStore.currentDate}
        date={date}
        setDate={setDate}
        rows={rows}
        tableRef={tableRef}
      />
    </SWrapper>
  );
};

export default observer(Scheduler);

const SWrapper = styled.div`
  height: 100%;
`

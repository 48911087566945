import { useLocation, Navigate } from "react-router-dom";
import { useAppContext } from "../AppContext";

function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const { store, api } = useAppContext();
  api.authApi.getUserInfo();

  if (!store.authStore.isAuthenticated) {
    console.log(location);
    const loginRedirectUrl = `/login?redirect=${location.pathname}`;

    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate to={loginRedirectUrl} state={{ from: location }} replace />
    );
  }

  return children;
}

export default RequireAuth;

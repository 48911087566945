import { Navigate } from "react-router-dom";
import { useAppContext } from "../../AppContext";

export const MainPage = () => {
  const { store } = useAppContext();

  if (!store.authStore.isAuthenticated) {
    return <Navigate to="/login" />
  }

  return <Navigate to="/dashboard" />
}

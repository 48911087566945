import { WorkoutData } from "../../../../../../../stores/WorkoutStore";
import { FC } from "react";
import { CrossCard } from "./components/CrossCard/CrossCard";
import { VeloCard } from "./components/VeloCard/VeloCard";
import { SwimCard } from "./components/SwimCard/SwimCard";
import { getWorkoutCardColor } from "./utils/getWorkoutCardColor";
import { isPlanMoreThenFact } from "./utils/isPlanMoreThanFact";
import { WorkoutType } from "../../../../../types";
import { ResultWorkoutType } from "./enums/ResultWorkoutType";
import { PowerCard } from "./components/PowerCard/PowerCard";
import { fromSeconds, getItemDimensions } from "../../../../utils";

interface IProps {
  item: WorkoutData;
  onlyPlan?: boolean;
}

export const CustomWorkoutCard: FC<IProps> = ({ item, onlyPlan }) => {
  const type = item.type;
  const factData = JSON.parse(item.fact) || {};
  const planData = JSON.parse(item.plan) || {};
  const indicators = item?.indicators ? JSON.parse(item.indicators) : {};
  const hasFact = factData && Object.keys(factData)?.length > 0;
  const data = hasFact && !onlyPlan ? factData : planData;
  const speed = data?.pace ? fromSeconds(data?.pace).format("m:ss") : undefined;
  const time = data?.time
    ? fromSeconds(data?.time).format("HH:mm:ss")
    : undefined;
  const indicatorsPulse = indicators?.heart_rate?.min
    ? indicators.heart_rate.min.toFixed()
    : undefined;
  const pulse = hasFact && !onlyPlan ? indicatorsPulse : data?.pulse;
  const isTarget = item.target;
  //  console.log(item.name);
  const workoutPlanType: ResultWorkoutType = isPlanMoreThenFact(
    item.plan,
    item.fact,
    WorkoutType[Number(item.type)] as any
  );
  const dimensions = getItemDimensions(type);
  switch (type) {
    case "running":
    default:
      return (
        <CrossCard
          onlyPlan={onlyPlan}
          label={item.name}
          labelColor={getWorkoutCardColor(item, workoutPlanType)}
          km={data?.distance}
          dimensions={dimensions}
          speed={speed}
          time={time}
          pulse={pulse}
          isTarget={isTarget}
          hasFact={hasFact}
          workoutPlanType={workoutPlanType}
        />
      );
    case "cycling":
      return (
        <VeloCard
          label={item.name}
          labelColor={getWorkoutCardColor(item, workoutPlanType)}
          km={data?.distance}
          speed={speed}
          time={time}
          dimensions={dimensions}
          pulse={pulse}
          isTarget={isTarget}
          hasFact={hasFact}
          workoutPlanType={workoutPlanType}
        />
      );
    case "swimming":
      console.log("swimming the best");
      return (
        <SwimCard
          label={item.name}
          labelColor={getWorkoutCardColor(item, workoutPlanType)}
          km={data?.distance}
          speed={speed}
          time={time}
          pulse={pulse}
          isTarget={isTarget}
          dimensions={dimensions}
          hasFact={hasFact}
          workoutPlanType={workoutPlanType}
        />
      );
    case "powerTraining":
      return (
        <PowerCard
          label={item.name}
          labelColor={getWorkoutCardColor(item, workoutPlanType)}
          km={data?.distance}
          speed={speed}
          time={time}
          pulse={pulse}
          dimensions={dimensions}
          isTarget={isTarget}
          hasFact={hasFact}
          workoutPlanType={workoutPlanType}
        />
      );
  }
};

import { LinkProps } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
type BaseProps = {
  to: string;
  children?: React.ReactNode;
};
type Props = BaseProps & LinkProps;

export default (props: Props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let url = props.to;
  if (params.has("redirect")) {
    const redirectUrl = params.get("redirect");
    url = `${props.to}?redirect=${redirectUrl}`;
  }

  return (
    <Link to={url} className={props.className}>
      {props.children}
    </Link>
  );
};

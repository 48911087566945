import { makeAutoObservable } from "mobx";
import Store from "./Store";

export const JwtToken: string = "yna-jwt-token";
export const RefreshToken: string = "yna-refresh-token";

type UserData = {
  fullName: string;
  linkId: string;
  id: string;
  isCoach: boolean;
  sex: string;
  birthDate: string;
  weight: string;
  city: string;
  about: string;
  services: string[];
};

class AuthStore {
  isInternalAuthenticated: boolean = false;
  identifier?: string = "";
  loginProvider: string = "";
  currentUser: UserData | undefined;
  isProfileOpen: boolean = false;

  constructor(private store: Store) {
    makeAutoObservable(this);
    this.isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  }

  saveAuthToken(authKey: string, refreshToken: string) {
    localStorage.setItem(JwtToken, authKey);
    localStorage.setItem(RefreshToken, refreshToken);
    localStorage.setItem("isAuthenticated", true.toString());
    this.isAuthenticated = true;
  }

  saveCurrentUser(userData: UserData | undefined) {
    this.currentUser = userData;
  }

  addService(service: string) {
    if (!this.currentUser) return;
    this.currentUser.services = [...this.currentUser.services, service];
  }

  logout(): void {
    localStorage.removeItem(JwtToken);
    localStorage.removeItem(RefreshToken);
    localStorage.removeItem("isAuthenticated");
    this.isAuthenticated = false;
  }

  async getAuthToken() {
    return localStorage.getItem(JwtToken) as string;
  }

  get isAuthenticated() {
    return this.isInternalAuthenticated;
    // const isAuth = localStorage.getItem("isAuthenticated");
    // return isAuth === "true";
  }
  set isAuthenticated(isAuth: boolean) {
    this.isInternalAuthenticated = isAuth;
  }

  getIdentifier(): string {
    return this.identifier ?? "";
  }

  setIdentifier(identity: string | undefined) {
    this.identifier = identity;
  }

  getLoginProvider(): string {
    return this.loginProvider ?? "";
  }

  setLoginProvider(loginProvider: string | "") {
    this.loginProvider = loginProvider;
  }
}

export default AuthStore;

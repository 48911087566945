import AthletesMenu from "../../components/common/AthletesMenu";
import { Box } from "@mui/system";
import YNAScheduler from "../../components/scheduler";
import UserInfo from "../../components/scheduler/userinfo";
import { observer } from "mobx-react";
import styled from "@emotion/styled";
import { ReactComponent as Logo } from './img/logo.svg';
import { ReactComponent as BurgerIcon } from './img/burger.svg';
import './styles.css'
import { useCalendar } from "../../components/scheduler/calendar/hooks/useCalendar";


const DashboardPage = () => {
  const handleTodayClick = () => {
    setDate(new Date());
  };

  const {
    rows,
    tableRef,
    setDate,
    date,
  } = useCalendar()

  return (
    <SWrapper>
      <SRow>
        <SSidebar>
          <Box
            sx={{
              backgroundColor: process.env.REACT_APP_IS_DEVELOPMENT === '1' ? '#000' : "#FF3147",
              padding: "20px 16px",
              maxHeight: 64,
              maxwidth: 242,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Logo />
            <BurgerIcon />
          </Box>
          <Box
            sx={{
              backgroundColor: "#FFF",
              width: 242,
              border: '1px solid #CDD9EA',
              borderRight: '0',
            }}
          >
            <AthletesMenu />
          </Box>
        </SSidebar>
        <SCalendar>
          <UserInfo
            onTodayClick={handleTodayClick}
            onDateChange={setDate}
            currentDate={date}
          />
          <SScheduler>
            <YNAScheduler 
              rows={rows}
              tableRef={tableRef}
              date={date}
              setDate={setDate}
            />
          </SScheduler>
        </SCalendar>
      </SRow>
    </SWrapper>
  );
};

const SWrapper = styled.div`
`

const SSidebar = styled.div`
  height: 100vh;
`

const SRow = styled.div`
  display: flex;
  flex-direction: row;
`

const SCalendar = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
`

const SScheduler = styled.div`
  flex-grow: 1;
`

export default observer(DashboardPage);

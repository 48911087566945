import styled from "@emotion/styled";

import { ReactComponent as GarminIcon } from "../../../assets/icons/garmin.svg";
import { ReactComponent as StravaIcon } from "../../../assets/icons/strava.svg";
import GrayButton from "./GrayButton";
import GreenButton from "./GreenButton";

export type ServiceListItemProps = {
    service: string;
    connected: boolean;
    onConnect?: (service: string) => void;
};

const onConnectHandler = (props: ServiceListItemProps) => {
    if (props.onConnect) {
        props.onConnect(props.service);
    }
}

const getIcon = (service: string) => {
    switch (service) {
        case "Garmin":
            return <GarminIcon style={{ width: '205px', height: '47px' }}  />
        case "Strava":
            return <StravaIcon style={{ width: '215px', height: '53px', marginLeft: '-4px' }}  />
        default:
            return service;
    }
}

const getButton = (props: ServiceListItemProps) =>
    props.connected ?
        <GreenButton>Подключен</GreenButton> :
        <GrayButton onClick={() => onConnectHandler(props)}>Подключить</GrayButton>

export default (props: ServiceListItemProps) => {
    return <SWrapper>
        <div>{getIcon(props.service)}</div>
        <div>
            {getButton(props)}
        </div>
    </SWrapper>
}

const SWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 36px;
`;
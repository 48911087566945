import React from "react";

import { Grid } from "@mui/material";
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';

import { observer } from "mobx-react";

import { authStyles } from '../../styles/authStyles';
import { Yna } from '../../components/common/logo';

export default observer(() => {
  const { classes } = authStyles();

  return (
    <SWrapper>
      <SLoginWrapper>
        <SLeftSide>
          <Yna className={classes.logo} />
        </SLeftSide>
        <SRightSide>
          <SLoginFormWrapper>
            <Outlet />
          </SLoginFormWrapper>
        </SRightSide>
      </SLoginWrapper>
    </SWrapper>
  )
});

const SWrapper = styled.div`
  min-height: 100vh;
  background-color: #FF3147;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-image: url("/bg.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
`

const SLoginWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  margin: 0;
`

const SLeftSide = styled(Grid)`
  width: 50%;
  height: 100%;

  @media (max-width: 940px) {
    display: none;
  }
`

const SRightSide = styled(Grid)`
  display: flex;
  border-radius: 24px 0px 0px 24px;
  width: 50%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #FFFFFF;

  @media (max-width: 940px) {
    width: 100%;
  }
`


const SLoginFormWrapper = styled(Grid)`
  margin: 0 auto;
  width: 506px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #A8A7A7;
  align-items: center;
  display: grid;
  align-content: center;
`

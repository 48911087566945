import styled from "@emotion/styled"
import { ChangeEvent, Dispatch, FC, InputHTMLAttributes, ReactNode, useEffect } from "react"
import { useTimeInputValue } from "./hooks/useTimeInputValue";
import { getGoodTime } from "./utils/getGoodTime";
import moment from "moment";

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value"> {
  label?: string;
  fullWidth?: boolean;
  value?: Date;
  setValue?: Dispatch<Date | undefined>;
  withHours?: boolean;
  units?: string;
}

export const TimeInput: FC<IProps> = ({ withHours, value: date, setValue: setDate, ...props }) => {
  const { value, setValue } = useTimeInputValue(date, setDate, withHours);

  useEffect(() => {
    if (!value) {
      setValue(withHours ? '00:00:00' : '00:00')
    }
  }, [withHours, setValue])

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = e.currentTarget?.value?.match(/:/) ? e.currentTarget?.value.replace(/:/g, '') : e.currentTarget?.value.padStart(6, "0");
  
    if (Number.parseInt(value) > 595959) {
      value = '595959';
    }
    if (!withHours && Number.parseInt(value) > 5959) {
      value = '5959';
    }

    let v = (value.trim() || '000000').slice(withHours ? -6 : -4);
    let $timeItems = [];

    for (let i = 0; i < v.length; i++) {
      if (i !== 0 && i % 2 === 0) {
        $timeItems.push(':');
      }
      $timeItems.push(v[i]);
    }

    const newValue = $timeItems.join('')
    setValue(newValue);
  };

  const onFormatValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!value) {
      return
    }

		const format = withHours ? 'HH:mm:ss' : 'mm:ss';
		const goodTime = getGoodTime(value, withHours)
    if (setDate) {
      setDate(moment(goodTime, format).utc().toDate());
    }
  }

  const onFocus = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (!value.length) {
      onChange(e)
    }

    setTimeout(() => {
      const position = e.target?.value.length;
      e.target.setSelectionRange(position, position)
    }, 0)
  }

  const onClick = (e: any) => {
    const value = e.currentTarget.value;

    if (!value.length) {
      onChange(e)
    }

    setTimeout(() => {
      const position = e.target?.value.length;
      e.target.setSelectionRange(position, position)
    }, 0)
  }


  return (
    <LabelWrapper label={props.label} units={props.units}>
      <SInput 
        {...props} 
        value={value} 
        onChange={onChange} 
        onBlur={onFormatValue} 
        onClick={onClick}
        onFocus={onFocus}
      />
    </LabelWrapper>
  )
}

const LabelWrapper = ({ label, units, children }: { label: string | undefined; units?: string | undefined; children: ReactNode }) => {
  if (!label) {
    return children;
  }

  return (
    <SLabelWrapper>
      <STopWrapper>
        <SLabel>{label}</SLabel>
        {units && <SUnits>
          {units}
        </SUnits>}
      </STopWrapper>
      {children}
    </SLabelWrapper>
  )
}

const SInput = styled.input<{ fullWidth?: boolean }>`
  font: inherit;
  margin-top: 5px;
  height: 36px;
  color: #1A222D;
  background: #EEF5FB;
  border: 1px solid #97A4B7;
  outline-color: #97A4B7;
  overflow: hidden;
  border-radius: 5px;
  padding: 8.5px 14px;
  box-sizing: border-box;
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
`

const SLabelWrapper = styled.div``

const STopWrapper = styled.div!`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const SLabel = styled.div`
  font-size: 14px;
  line-height: 1.4375em;
`

const SUnits = styled.div`
  color: #7B8A9E;
  font-size: 10px;
  line-height: 12px;
`

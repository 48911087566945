import { ReactNode, useMemo } from 'react';
import { Input } from '../../../../Input/Input';
import { WorkoutPlanType, WorkoutType } from '../../../types';
import { fromSeconds, toSeconds } from "../../../calendar/utils";
import { TimeInput } from '../../../../TimeInput/TimeInput';
import dayjs from 'dayjs';

const inputsKeyByPlan = {
  [WorkoutPlanType.fact]: ["time", "distance", "pace", "speed"],
  [WorkoutPlanType.plan]: ["time", "distance", "pace", "speed", "pulse"],
};

const inputsKeyByType = {
  [WorkoutType.running]: ["time", "distance", "pace", "pulse"],
  [WorkoutType.cycling]: ["time", "distance", "speed", "pulse"],
  [WorkoutType.swimming]: ["time", "distance", "pace", "pulse"],
  [WorkoutType.powerTraining]: ["time", "distance"],
};

const formatDate = (d: Date): number => {
  const date = dayjs(d);
  const secondsCount = dayjs
    .duration({
      hours: date.hour(),
      minutes: date.minute(),
      seconds: date.second(),
    })
    .asSeconds();
  return secondsCount;
};

const getTempUnit = (type: string) => {
  switch (type) {
    case WorkoutType[WorkoutType.swimming]:
      return '/100 м';
    case WorkoutType[WorkoutType.running]:
      return 'мин/км';
    default:
      return '';
    }
}

export const usePlanInputs = (
  value: any,
  setValue: (key: string, value: any) => void,
  tabState: WorkoutPlanType,
  type: string
): ReactNode[] => {
  const inputsCollection = useMemo(
    () => ({
      time: (
        <TimeInput
          id="2"
          fullWidth={true}
          withHours={true}
          value={value.time || ""}
          setValue={(v) => {
            if (v) {
              setValue("time", formatDate(v));
            }
          }}
          label="Время"
        />
      ),
      distance: (
        <Input
          inputType="number"
          id="3"
          fullWidth
          name="distance"
          value={value.distance || ""}
          onChange={(e) => {
            setValue("distance", e.currentTarget.value);
          }}
          placeholder="——"
          label="Дистанция"
          units="км"
        />
      ),
      pace: (
        <TimeInput
          id="4"
          withHours={false}
          fullWidth={true}
          value={value.pace}
          setValue={(v) => {
            if (v) {
              setValue("pace", formatDate(v));
            }
          }}
          label="Темп"
          units={getTempUnit(type)}
        />
      ),
      speed: (
        <Input 
          id="6"
          inputType="number" 
          fullWidth
          name="speed"
          value={value?.speed || ""}
          onChange={(e) => setValue("speed", e.currentTarget?.value)}
          placeholder="——"
          label="Скорость"
          units="км./ч"
        />
      ),
      pulse: (
        <Input
          id="5"
          inputType="number"
          fullWidth
          name="pulse"
          value={value.pulse || ""}
          onChange={(e) => setValue("pulse", e.currentTarget?.value)}
          placeholder="——"
          label="Пульс"
          units="уд./мин"
        />
      ),
    }),
    [value, setValue, type]
  );

  const inputs = useMemo(() => {
    if (tabState === undefined || type === undefined) {
      return [];
    }

    const entries = Object.entries(inputsCollection);
    let _inputs: ReactNode[] = [];

    for (const [key, input] of entries) {
      const tabIdx = tabState + 1;
      const hasByState =
        inputsKeyByPlan[tabIdx as WorkoutPlanType].includes(key);
      const idx = Object.entries(WorkoutType).find(
        ([_, value]) => value === type
      )?.[0];

      if (!idx) {
        continue;
      }

      const hasByType =
        inputsKeyByType[Number(idx) as WorkoutType].includes(key);

      if (hasByState && hasByType) {
        _inputs.push(input);
      }
    }

    return _inputs;
  }, [inputsCollection, tabState, type]);

  return inputs;
};

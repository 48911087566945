import { Dayjs } from "dayjs";

export type WorkoutItem = {
  day: number;
  month: number;
  year: number;
  date: Dayjs;
  isCurrentDay: boolean;
};

export enum WorkoutType {
  running = 1,
  cycling,
  swimming,
  powerTraining,
}

export enum WorkoutPlanType {
  plan = 1,
  fact,
}

export enum ScrollDirection {
  top = 1,
  bottom = 2,
}

export type CalendarEventManager = {
  onTodayClick: () => void;
  onDateClick: (date: Dayjs) => void;
};

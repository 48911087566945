import { TextField, TextFieldProps, TextFieldVariants } from "@mui/material"
import { FC } from "react";
import { BaseInput, IBaseInputProps } from "../BaseInput/BaseInput";

type INumberFieldProps = Omit<TextFieldProps, 'variant'> & { variant?: TextFieldVariants };

export interface INumberInputProps extends Omit<INumberFieldProps, 'label'>, IBaseInputProps {
  inputType: 'number';
  value?: number | null;
  placeholder?: string;
  units?: string;
}

export const NumberInput: FC<INumberInputProps> = (props) => {
  return (
    <BaseInput
      as={TextField}
      id="outlined-textarea"
      name={"description"}
      variant="outlined"
      inputProps={{}}
      {...props}
    />
  )
}

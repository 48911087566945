export const getShortenerStreamData = (data: number[] | undefined, step = 2) => {
    if (!data) {
        return undefined;
    }

    let newData = [];

    for (let i = 0; i < data.length; i += 1) {
        if (i % step === 0) {
            newData.push(data[i]);
        }
    }

   return newData;
}

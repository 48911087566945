import { IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react";
import { useAppContext } from "../../AppContext";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { FrontURL } from "../../config/params";

function AddAthlete(): any {
  const { store } = useAppContext();

  const url = `${FrontURL}/addmetocoach/${store.authStore.currentUser?.linkId}`;

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={store.coachStore.displayState.addAthleteOn}
      onClose={() => store.coachStore.setDisplayAddAthlete(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: 490,
          height: 200,
          backgroundColor: "#FFF",
        }}
      >
        <div
          style={{
            margin: "41px 65px 57px 65px",
          }}
        >
          <Typography
            id="modal-modal-title"
            fontSize={14}
            fontWeight={500}
            lineHeight={"130%"}
            color={"#1A222D"}
            paddingBottom={"27px"}
          >
            Для добавления атлета отправьте ему свою ссылку
          </Typography>
          <div
            style={{
              width: "360px",
              height: "42px",
              padding: "12px 10px",

              borderRadius: "6px",
              backgroundColor: "#EEF5FB",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: "10px" }}>{url}</div>
            <div style={{ marginRight: "10px" }}>
              <IconButton onClick={() => navigator.clipboard.writeText(url)}>
                <Copy />
              </IconButton>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
} 
export default observer(AddAthlete);

import styled from "@emotion/styled";
import { css } from "@mui/material";
import { AnalysisDisplayType } from "../types";
import { useAppContext } from "../../../AppContext";
import { observer } from "mobx-react";

export default observer(() => {
  const {
    store: { detailStore },
    api,
  } = useAppContext();
  const onToggle = async (type: AnalysisDisplayType) => {
    await api.workoutApi.setAnalysisType(type);
  };

  return (
    <SContainer>
      <SInnerContainer>
        <STitle
          active={
            detailStore.analysisDisplayType === AnalysisDisplayType.Distance
          }
          onClick={() => onToggle(AnalysisDisplayType.Distance)}
        >
          Расстояние
        </STitle>
        <STitle
          active={
            detailStore.analysisDisplayType === AnalysisDisplayType.Segment
          }
          onClick={() => onToggle(AnalysisDisplayType.Segment)}
        >
          Сегменты
        </STitle>
      </SInnerContainer>
    </SContainer>
  );
});

const SInnerContainer = styled.div`
  display: flex;
  padding: 0 8px 9px 0px;
  border: 0px 0px 3px 0px;
  gap: 8px;
`;

const SContainer = styled.div`
  padding: 0 0 0 0;
`;

const STitle = styled.div<{ active: boolean }>`
  ${({ active }) =>
    active &&
    css`
      border-bottom: 3px solid #ff3147;
    `}

  color: #000000;
  text-transform: uppercase;
  font-weigth: 600;
  line-height: 12px;
  padding: 8px 9px;
`;

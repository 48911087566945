import { Button, Popover } from "@mui/material";
import styled from '@emotion/styled';
import dayjs from "dayjs";
import { observer } from "mobx-react";
import AngleLeft from './img/angle.svg';
import { useRef, useState } from "react";
import { MonthPicker } from "./components/MonthPicker/MonthPicker";
var calendar = require("dayjs/plugin/calendar");
dayjs.extend(calendar);

type Props = {
  onTodayClick: () => void;
  onDateChange: (date: Date) => void;
  currentDate: Date;
};

const CalendarControl = ({
  onTodayClick,
  onDateChange,
  currentDate,
}: Props) => {
  const setPreviousMonth = () => {
    const nextMonth = dayjs(currentDate)
      .month(currentDate.getMonth() - 1)
      .date(1)
      .hour(0)
      .minute(0)
      .second(0);
    onDateChange(nextMonth.toDate());
  };

  const setNextMonth = () => {
    const nextMonth = dayjs(currentDate)
      .month(currentDate.getMonth() + 1)
      .date(1)
      .hour(0)
      .minute(0)
      .second(0);
    onDateChange(nextMonth.toDate());
  };

  const monthPickerButtonRef = useRef<HTMLDivElement>(null);
  const [isOpenMonthPicker, setIsOpenMonthPicker] = useState<boolean>(false);

  return (
    <SWrapper
      ref={monthPickerButtonRef}
    >
      <SDayButton
        onClick={() => onTodayClick()}
      >
        Сегодня
      </SDayButton>
      <SNavigation>
        <SAngleButton
          onClick={() => setPreviousMonth()}
        >
          <SAngleLeft />
        </SAngleButton>
        <SRow
          onClick={() => setIsOpenMonthPicker(true)}
        >
          <SDateButton>
            {dayjs(currentDate).locale("ru").format("MMMM")}
          </SDateButton>
          <SDateButton>
            {dayjs(currentDate).locale("ru").format("YYYY")}
          </SDateButton>
        </SRow>
        <SAngleButton
          onClick={() => setNextMonth()}
        >
          <SAngleRight />
        </SAngleButton>
      </SNavigation>
      <Popover
        open={isOpenMonthPicker}
        anchorEl={monthPickerButtonRef.current}
        onClose={() => setIsOpenMonthPicker(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MonthPicker
          onDateChange={onDateChange}
        />
      </Popover>
    </SWrapper>
  );
};

export default observer(CalendarControl);

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  gap: 0 4px;
`

const SNavigation = styled.div`
  display: flex;
  width: 100%;
  height: 24px;
  gap: 0 16px;
`

const SDayButton = styled(Button)`
  background: #EEF5FB;
  color: #1A222D;
  font-weight: 500;
  text-transform: capitalize;
  height: 30px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 6px 12px;
  min-width: auto;
`

const SAngleButton = styled(Button)`
  padding: 0;
  min-width: 24px;
`

const SAngleLeft = styled('img')``

SAngleLeft.defaultProps = {
  src: AngleLeft,
  alt: 'arrow-left',
}

const SAngleRight = styled(SAngleLeft)`
  transform: rotate(180deg);
`

const SDateButton = styled(Button)`
  min-width: auto;
  padding: 0 3px;
  color: #1A222D;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Golos Text", sans-serif;
`

const SRow = styled.div`
  display: flex;
  min-width: 110px;
  text-align: center;
  justify-content: center;
`

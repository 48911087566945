import dayjs from 'dayjs';
import { WorkoutData } from "../../../../../../../../stores/WorkoutStore";
import { ResultWorkoutType } from '../enums/ResultWorkoutType';

export const getWorkoutCardColor = (data: WorkoutData, workoutPlanType: ResultWorkoutType) => {
  const noPlan = !data.plan || data.plan === '{}'
  const noFact = !data.fact || data.fact === '{}'

  const date = data.startDate;
  const isBeforeToday = dayjs(date).add(1, 'day').set('hour', 0).isBefore(dayjs());
  const isEmptyEdited = data.createdEmpty;

  if (!isBeforeToday && isEmptyEdited && noFact && noPlan) {
    return '#CDD9EA';
  }
  if (!isEmptyEdited && !noFact && noPlan) {
    return "none";
  }

  if (noFact && !isBeforeToday) {
    return '#CDD9EA';
  }
  if (noFact && isBeforeToday) {
    return '#F34444';
  }

  if (workoutPlanType === ResultWorkoutType.PlanFailed) {
    return '#F34444'
  }
  if (workoutPlanType === ResultWorkoutType.PlanWithDeviation) {
    return '#ECF100'
  }
  if (workoutPlanType === ResultWorkoutType.PlanCompleted) {
    return '#44CAAA'
  }


  /* const plan = JSON.parse(data.plan);
  const fact = JSON.parse(data.fact);
  const speedIndex = Math.abs(1 - getSeconds(new Date(fact?.pace)) / getSeconds(new Date(plan?.pace)));
  const timeIndex = Math.abs(1 - getSeconds(new Date(fact?.time)) / getSeconds(new Date(plan?.time)));
  const distanceIndex = Math.abs(1 - Number(fact?.distance) / Number(plan?.distance));
  const index = Math.max(timeIndex, speedIndex, distanceIndex);

  if (index > 0.15 && index < 0.35) {
    return '#44CAAA'
  }
  if (index > 0.35 && index <= 0.4) {
    return '#ECF100'
  }
  if (index > 0.4) {
    return '#FF3147'
  } */

  return 'none';
}

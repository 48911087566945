import styled from "@emotion/styled";
import {Button} from "@mui/material";

export type ButtonProps = {
    onClick?: () => void;
    children?: React.ReactNode;
}

export default (props: ButtonProps) =>
    <SSaveButton onClick={props.onClick}>
        {props.children}
    </SSaveButton>

const SButton = styled(Button)`
  font-size: 14px;
  border-radius: 6px;
  padding: 12px 16px;
  box-sizing: border-box;
`;

const SSaveButton = styled(SButton)`
  background-color: #ff3147;
  color: #fff;
  text-transform: capitalize;

  &:hover {
    background-color: #CC2739;
  }

  &:focus {
    background-color: #CC2739;
  }
`;
import styled from '@emotion/styled';
import dayjs from "dayjs";
import { Dispatch, FC, memo, useEffect, useRef, useState } from "react";
import { useAppContext } from "../../../../../AppContext";
import { ReactComponent as AngleLeft } from "./img/angle-left.svg";
import { ReactComponent as AngleRight } from "./img/angle-right.svg";

interface IProps {
  onDateChange: Dispatch<Date>;
}

export const MonthPicker: FC<IProps> = memo(({ onDateChange }) => {
  const [year, setYear] = useState(dayjs());
  const [month, setMonth] = useState<number>();
  const prevMonth = useRef(month);
  useAppContext();

  useEffect(() => {
    if (month === undefined) {
      return;
    }

    prevMonth.current = month;
    const date = dayjs(year).set("month", month);
    // const date2 = dayjs(date).add(1, 'month')
    // const currentDate = startOfMonth(date.toDate())
    // api.workoutApi.loadMyWorkouts(date.toDate(), date2.toDate());
    // store.workoutStore.setCalendarDateStart(currentDate);
    onDateChange(date.toDate());
  }, [month, year, onDateChange]);

  return (
    <SWrapper>
      <SHeader>
        <AngleLeft onClick={() => setYear(year.add(-1, "year"))} />
        <SYear>{year.year()}</SYear>
        <AngleRight onClick={() => setYear(year.add(1, "year"))} />
      </SHeader>
      <SBody>
        <SMonth onClick={() => setMonth(0)}>ЯНВ</SMonth>
        <SMonth onClick={() => setMonth(1)}>ФЕВ</SMonth>
        <SMonth onClick={() => setMonth(2)}>МАР</SMonth>
        <SMonth onClick={() => setMonth(3)}>АПР</SMonth>
        <SMonth onClick={() => setMonth(4)}>МАЙ</SMonth>
        <SMonth onClick={() => setMonth(5)}>ИЮН</SMonth>
        <SMonth onClick={() => setMonth(6)}>ИЮЛ</SMonth>
        <SMonth onClick={() => setMonth(7)}>АВГ</SMonth>
        <SMonth onClick={() => setMonth(8)}>СЕН</SMonth>
        <SMonth onClick={() => setMonth(9)}>ОКТ</SMonth>
        <SMonth onClick={() => setMonth(10)}>НОЯ</SMonth>
        <SMonth onClick={() => setMonth(11)}>ДЕК</SMonth>
      </SBody>
    </SWrapper>
  );
});

const SWrapper = styled.div`
  width: 273px; 
  height: 244px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
  background-color: #fff;
`

const SHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 20px;
  box-sizing: border-box;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #EDF2F4;

  & > * {
    cursor: pointer;
  }
`

const SYear = styled.div`
  font-family: "Golos Text", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
`

const SBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 8px 12px
`

const SMonth = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Golos Text", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  transition: 0.2s;
  margin: 4px 5px;

  &:hover {
    background: #44CAAA;
    color: #fff;
  }
`

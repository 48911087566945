import { TimeFieldProps, TimeField } from "@mui/x-date-pickers";
import { Dayjs } from 'dayjs';
import { FC, FormEvent } from "react";
import { BaseInput, IBaseInputProps } from "../BaseInput/BaseInput";

export interface IHoursInputProps extends Omit<TimeFieldProps<any>, 'label'>, IBaseInputProps {
  inputType: 'hours';
  value?: Dayjs | null;
  placeholder?: string;
  units?: string;
}

export const HoursInput: FC<IHoursInputProps> = (props) => {
  return (
    <BaseInput
      as={TimeField}
      size="small"
      style={{ borderRadius: "4px" }}
      inputRef={(input: any) => input}
      onFocus={(e: FormEvent<HTMLInputElement>) =>
        e.currentTarget.setSelectionRange(
          e.currentTarget.value.length - 3,
          e.currentTarget.value.length - 1
        )}
      format="HH:mm:ss"
      {...props}
    />
  )
}

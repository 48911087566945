import { useParams } from "react-router-dom";
import { useAppContext } from "../../AppContext";
import ContentArea from "./ContentArea";
import Detail from "./Detail";
import Header from "./Header";
import Menu from "./Menu";
import Plan from "./TrainData";
import { autorun, reaction } from "mobx";
import { AnalysisDisplayType } from "./types";

export default () => {
  const params = useParams();
  const {
    api,
    store: { detailStore },
  } = useAppContext();
  if (params.id) {
    api.workoutApi.getWorkoutStream(params.id);
    api.workoutApi.getWorkoutData(params.id);
  }

  autorun(() => {
    console.log(
      `reaction getWorkoutsList starts for:${detailStore.workoutData?.athleteId}`
    );
    if (detailStore.workoutData?.athleteId) {
      api.workoutApi.getWorkoutsList(detailStore.workoutData?.athleteId);
    }
  });

  reaction(
    () => detailStore.analysisDisplayType,
    (analysisDisplayType: AnalysisDisplayType) =>
      detailStore.setStreamData(detailStore.streamData)
  );

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Header />
        <ContentArea Menu={<Menu />} Left={<Plan />} Detail={<Detail />} />
      </div>
    </>
  );
};


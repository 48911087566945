import styled from '@emotion/styled'

export default ({
  data,
  title,
  units,
  max,
}: {
  data: any;

  title: string;
  units: string;
  max?: string;
}) => {
  return (
    data ? (
      <div style={{
        width: 139,
        height: 36,
        display: "flex",
        flexDirection: "row",
      }}>
        {/* <div style={{ paddingRight: "12px" }}></div> / */}

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            {data} <span style={{ fontWeight: 500 }}>{units}</span>
          </div>
          {!!max && (
            <div
              style={{
                fontWeight: 600,
                color: '#FF3147',
                fontSize: '12px',
              }}
            >
              {max} <span style={{ fontWeight: 500 }}>{units}</span>
            </div>
          )}
          <div
            style={{
              color: "#7B8A9E",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "14px",
            }}
          >
            {/* {title} */}
          </div>
        </div>
      </div>
    ) : (
      <div style={{
        height: 36,
        margin: '12px 0'
      }}></div>
    )
  );
};

const SIcon = styled.div`
  padding-right: 12px;

  & svg {
    color: #1A222D;
    height: 36px;
    width: 36px;
  }
`

import AppApi from "./AppApi";
import AppStore from "../stores/Store";
import { RefreshToken, JwtToken } from "../stores/AuthStore";
import {AxiosError} from "axios";

export enum UserRole {
  Athlete,
  Coach,
}

type UserSaveRequest = {
  fullName: string;
  email: string;
  password: string;
  consent: boolean;
  role: UserRole | null;
};

export default class AuthApi {
  constructor(private api: AppApi, private store: AppStore) {
    this.api = api;
    this.store = store;
  }

  async auth({
    login,
    password,
    rememberMe,
  }: {
    login: string;
    password: string;
    rememberMe: boolean;
  }) {
    const response = await this.api.client.post("/auth/api/v1/auth", {
      email: login,
      pwd: password,
      rememberMe: rememberMe,
    });

    const data = response.data;

    if (response.status === 200) {
      this.store.authStore.saveAuthToken(data.accessToken, data.refreshToken);
      this.store.authStore.saveCurrentUser(data);
      this.store.profileStore.setIsCoach(data.isCoach);
      return { success: true };
    } else {
      return { success: false, errors: [response.data.title] };
    }
  }

  async refresh() {
    const accessToken = localStorage.getItem(JwtToken);
    const client = this.api.clientWithoutInterceptors;
    client.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

    try {
      const response = await client.post("/auth/api/v1/auth/refresh", {
        refreshToken: localStorage.getItem(RefreshToken),
        accessToken: accessToken,
      });

      this.store.authStore.saveAuthToken(
        response.data.accessToken,
        response.data.refreshToken
      );
      return true;
    } catch {
      return false;
    }
  }

  async register(item: UserSaveRequest) {
    const response = await this.api.client.post(
      "/auth/api/v1/auth/register",
      item
    );
    const data = response.data;

    if (response.status === 200) {
      this.store.authStore.saveAuthToken(data.accessToken, data.refreshToken);
      return { success: true };
    } else {
      return { success: false, errors: ["ошибка"] };
    }
  }

  async logout() {
    const token = await this.store.authStore.getAuthToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await this.api.client.post(
      `/auth/api/v1/logout`,
      {},
      config
    );

    console.log(response);
    this.store.authStore.logout();
  }

  async getResetPasswordToken({
    email,
    link,
  }: {
    email: string;
    link: string;
  }) {
    console.log("getResetPasswordToken");
    const response = await this.api.client.post(
      "/auth/api/v1/password/reset/token",
      {
        email: email,
        link: link,
      }
    );

    if (response.status === 200) {
      return { success: true };
    } else {
      return { success: false, errors: ["ошибка"] };
    }
  }

  async resetPassword({
    email,
    pwd,
    token,
  }: {
    email: string;
    pwd: string;
    token: string;
  }) {
    console.log("getResetPasswordToken");
    const response = await this.api.client.post("/auth/api/v1/password/reset", {
      email: email,
      pwd: pwd,
      token: token,
    });

    if (response.status === 200) {
      return { success: true };
    } else {
      return { success: false, errors: ["ошибка"] };
    }
  }

  async changePassword({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) {
    const response = await this.api.client.post(
      "/auth/api/v1/password/change",
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
      }
    );

    if (response.status === 200) {
      return { success: true };
    } else {
      return { success: false, errors: ["ошибка"] };
    }
  }

  async getUserInfo() {
    try {
      this.api.workoutApi.initLoadMyWorkouts();
      const response = await this.api.client.get("/users/api/v1/users/current");
      if (response.status === 200) {
        this.store.authStore.saveCurrentUser(response.data);
        this.store.profileStore.setIsCoach(response.data.isCoach);
      } else {
        this.store.authStore.logout();
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.status === 401) {
          this.store.authStore.saveCurrentUser(undefined);
          window.location.href = "/login";
          return;
        }
      } else {
        console.error(e);
      }
    }
  }
}

import {Typography} from "@mui/material";
import { observer } from "mobx-react";
import { useSearchParams } from "react-router-dom";

import { authStyles } from '../../../../styles/authStyles';
import { GetTokenForm } from "./GetTokenForm";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { useTranslation } from "react-i18next";

export const ResetPassword = observer(() => {
  const { classes } = authStyles();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const getLayout = () => {
    const code = searchParams.get('code');
    return code ? <ResetPasswordForm token={code} /> : <GetTokenForm />
  }

  return <>
    <Typography variant="h4" className={classes.header}>{t('recoverPasswordTitle')}</Typography>
    {getLayout()}
  </>
});

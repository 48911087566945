import styled from "@emotion/styled";
import {Button} from "@mui/material";

export type ButtonProps = {
    onClick?: () => void;
    children?: React.ReactNode;
}

export default (props: ButtonProps) =>
    <SButton disabled onClick={props.onClick}>
        {props.children}
    </SButton>

const SButton = styled(Button)`
  font-size: 14px;
  border: 1px solid #44CAAA;
  border-radius: 6px;
  padding: 6px 21px;
  box-sizing: border-box;
  background: transparent;
  color: #44CAAA;
  text-transform: capitalize;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
  }
    
  &:disabled {
    color: #44CAAA;
  }
`;

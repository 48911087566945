import styled from "@emotion/styled";
import { FC } from "react";
import { truncate } from '../../../../../../../../../utils/truncate';
import { getIconByWorkoutType } from "../../../../../../utils/getIconByWorkoutType";
import { IPrimaryCardProps, PrimaryCard } from "../PrimaryCard/PrimaryCard";

export const PowerCard: FC<IPrimaryCardProps> = ({ 
  labelColor = '#44CAAA', 
  ...props
}) => {
  return (
    <PrimaryCard 
      labelColor={labelColor}
      {...props}
    >
      <SRow>
        {getIconByWorkoutType('powerTraining')}
        <span>{props.label ? truncate(props.label, 27) : 'Силовая'}</span>
      </SRow>
    </PrimaryCard>
  );
}

const SRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`

import { observer } from "mobx-react";
import AthleteAva from "../../components/scheduler/userinfo/AthleteAva";
import { useAppContext } from "../../AppContext";
import AthleteName from "./components/AthleteName";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/popover_close.svg";
import styled from "@emotion/styled";
import TrainSelector from "./TrainSelector";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Addworkout from "../../components/scheduler/addworkout";
import { getCellIdByDate } from "../../components/scheduler/calendar/utils/getCellIdByDate";

export default observer(() => {
  const {
    store: { detailStore, workoutStore, coachStore },
  } = useAppContext();
  const navigate = useNavigate();
  const goToDashboard = () => {
    const date = detailStore.workoutData?.startDate;
    const dateParam = date ? `?day=cell-${getCellIdByDate(new Date(date))}` : '';
    navigate(`/dashboard/${detailStore.workoutData?.athleteId}${dateParam}`);
  };

  const openEdit = () => {
    detailStore.workoutData &&
      workoutStore.editWorkout(detailStore.workoutData);
    coachStore.setWorkoutModalOpen(true);
  };

  return (
    <SContainer>
      <div style={{ height: "40px" }}>
        {detailStore.workoutData?.athleteId && (
          <AthleteData>
            <AthleteAva
              athleteId={detailStore.workoutData?.athleteId}
              showMenu={false}
            />
            <AthleteName name={detailStore.workoutData?.athleteName || ""} />
          </AthleteData>
        )}
      </div>
      <TrainSelector />
      <SControls>
        <SButton>
          <EditIcon onClick={openEdit} />
        </SButton>
        <SButton>
          <Button>
            <CloseIcon onClick={goToDashboard} />
          </Button>
        </SButton>
      </SControls>
      <Addworkout />
    </SContainer>
  );
});

const AthleteData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Golos Text";
  font-size: 16px;
  color: #1a222d;
`;

const SButton = styled.div`
  padding-right: 12px;
  cursor: pointer;
`;

const SControls = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 4px;
  align-items: center;
`;

const SContainer = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid #cdd9ea;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;


import styled from "@emotion/styled";
import { observer } from "mobx-react";
import ProfileForm from "./ProfileForm";

export default observer(() => {
  return (
    <SWrapper>
      <ProfileForm />
    </SWrapper>
  );
});

const SWrapper = styled.div`
  // height: 570px;
  // padding: 50px 24px;
`;

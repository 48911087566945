import { Box } from "@mui/system";
import { ReactComponent as CadenceIcon } from "../../../../assets/icons/cadence.svg";
import { ReactComponent as DownIcon } from "../../../../assets/icons/down.svg";
import { ReactComponent as UpIcon } from "../../../../assets/icons/up.svg";
import DataItem from "../../../common/workout/PlanDataItem";
import EmptyItem from "../../../common/workout/EmptyItem";

export default ({ data }: { data: string | undefined }) => {
  if (!data) return null;

 const item = JSON.parse(data);
 const hasData =
   item["total_elevation_gain"] || item["down"] || item["cadence"];

 if (Object.keys(item).length === 0 || !hasData) {
   return null;
 }

 return (
   <Box sx={{ padding: "0" }}>
     <div
       style={{
         fontFamily: "Golos Text",
         fontWeight: "600",
         fontSize: "12px",
         lineHeight: "130%",
         letterSpacing: "0.5px",
         textTransform: "uppercase",
         color: "#1A222D",
         display: "flex",
         flex: 1,
         flexDirection: "row",
         justifyContent: "space-between",
         padding: "8px 0",
         boxSizing: "border-box",
       }}
     >
       <div style={{ width: "139px" }}>Показатели</div>
       <div style={{ width: "139px" }}></div>
     </div>
     <div
       style={{
         display: "flex",
         flexDirection: "row",
         justifyContent: "space-between",
       }}
     >
       <div
         style={{
           display: "flex",
           flex: 1,
           flexDirection: "column",
           paddingBottom: "",
           gap: "12px 0",
         }}
       >
         <div
           style={{
             display: "flex",
             flexDirection: "row",
           }}
         >
           {!!item["total_elevation_gain"] && (
             <DataItem
               Icon={UpIcon}
               data={item["total_elevation_gain"]}
               units="м"
               title="Подъем"
             />
           )}

           {!!item["down"] && (
             <DataItem
               Icon={DownIcon}
               data={item["down"]}
               units="м"
               title="Спуск"
             />
           )}
         </div>
         {/* <div style={{
            display: "flex",
            flexDirection: "row",
          }}>
            <DataItem
              Icon={CaloriesIcon}
              data={item["calories"]}
              units="ккал"
              title="Сожжено"
            />
            <EmptyItem />
          </div> */}
         {!!item["cadence"] && (
           <div
             style={{
               display: "flex",
               flexDirection: "row",
             }}
           >
             <DataItem
               Icon={CadenceIcon}
               data={item["cadence"] && item["cadence"].toFixed()}
               units="шаг/мин"
               title="Каденс"
             />
             <EmptyItem />
           </div>
         )}
         <div></div>
       </div>
     </div>
   </Box>
 );
};

import { useAppContext } from "../../AppContext";
import { useEffect, useState } from "react";
import AthletesList from "./AthletesList";
import AddAthlete from "../coach/AddAthlete";
import styled from "@emotion/styled";
import { ReactComponent as AngleIcon } from '../../assets/icons/angle.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AthleteItem from "./AthleteItem";
import { useNavigate } from "react-router-dom";
import Profile from "../profile";
import Settings from "../settings";

const AthletesMenu = () => {
  const { store, api } = useAppContext();
  useEffect(() => {
    api.coachApi.loadMyAthletes();
  }, [api.coachApi]);

  const openAthleteAddModal = () => {
    store.coachStore.setDisplayAddAthlete(true);
  };
  const [open, setOpen] = useState(true);
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigate();

  return (
    <div>
      <Profile />
      <Settings />
      <AddAthlete />
      <SAccordion expanded={open} onChange={() => setOpen(!open)}>
        <SButton expandIcon={<SAngleIcon />}>Атлеты</SButton>
        <SBody>
          <SSearch>
            <SSearchIcon>
              <SearchIcon />
            </SSearchIcon>
            <SInput 
              placeholder="Поиск атлета" 
              onChange={(e) => setSearch(e.target.value)}
            />
          </SSearch>
          <AthletesList 
            onAthleteAddClick={() => openAthleteAddModal()} 
            search={search}
          />
        </SBody>
      </SAccordion>
      {!open && (
        <div>
          {store.coachStore.mineAthletesList
            .filter((item) => item?.id === store.coachStore.currentAthleteId)
            .map((item, idx) => (
              <AthleteItem
                onClick={(id: string) => {
                  store.coachStore.setCurrentAthlete(id);
                  navigate(`/dashboard/${id}`);
                }}
                me={item?.id === store.authStore?.currentUser?.id}
                key={item?.id || idx}
                selected={item?.id === store.coachStore.currentAthleteId}
                item={{ id: item?.id, name: item?.fullName }}
              />
            ))}
        </div>
      )}
      <SAccordion disabled>
        <SButton expandIcon={<SAngleIcon />}>Шаблоны</SButton>
        <SBody>
          <div>123</div>
        </SBody>
      </SAccordion>
    </div>
  );
};

export default AthletesMenu;

const SAccordion = styled(Accordion)<{ disabled?: boolean }>`
  margin: 0!important;
  border: 1px solid #CDD9EA;
  box-shadow: none;
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
  `}
`

const SAngleIcon = styled(AngleIcon)`
  transform: rotate(180deg);
`

const SSearch = styled.div`
  display: flex;
  gap: 0 5px;
  align-items: center;
  border-top: 1px solid #CDD9EA;
  border-bottom: 1px solid #CDD9EA;
  padding-left: 16px;
`

const SButton = styled(AccordionSummary)`
  background: #fff;
`

const SBody = styled(AccordionDetails)`
  background: #fff;
  padding: 0;
`

const SSearchIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-bottom: 1px;
`

const SInput = styled.input`
  border: none; 
  box-sizing: border-box;
  font-size: 14px;
  height: 36px;
  padding: 0px 16px 0 8px;
  width: 100%;
  outline: none;
`

import { observer } from "mobx-react";
import { useAppContext } from "../../../AppContext";
import AthleteAva from "./AthleteAva";

const Component = () => {
  const {
    store: { authStore },
  } = useAppContext();
  const userId = authStore?.currentUser?.id

  if (userId === undefined) return null;

  return <AthleteAva showMenu={true} athleteId={userId} />;
};

export default observer(Component);

import { observer } from "mobx-react";
import { useAppContext } from "../../AppContext";
import WorkoutData from "../../components/common/workout/WorkoutData";
import Indicators from "../../components/scheduler/workout/display/indicators";
import { makeStyles } from "../../styles";
import Map from "./Map";
import styled from "@emotion/styled";
import Link from "@mui/material/Link";
import { getItemDimensions } from "../../components/scheduler/calendar/utils";

const useStyles = makeStyles()({
  container: {
    width: "294px",
    borderRight: "1px solid #CDD9EA",
  },
  containerExpanded: {
    width: "585px",
    borderRight: "1px solid #CDD9EA",
  },
  workoutContainer: {
    padding: "16px",
    width: "294px",
  },
  workoutContainerExpanded: {
    padding: "16px",
    width: "585px",
  },
});

export default observer(() => {
  const {
    store: { detailStore },
  } = useAppContext();
  const styles = useStyles();
  if (detailStore.workoutData == null) return;
  const dimensions = getItemDimensions(detailStore.workoutData?.type);
  return (
    <div
      className={
        detailStore.isMapExpanded
          ? styles.classes.containerExpanded
          : styles.classes.container
      }
    >
      {!detailStore.isMapExpanded && (
        <div
          className={
            detailStore.isMapExpanded
              ? styles.classes.workoutContainerExpanded
              : styles.classes.workoutContainer
          }
        >
          <WorkoutData
            dimensions={dimensions}
            data={
              (detailStore.workoutData?.plan &&
                JSON.parse(detailStore.workoutData?.plan)) ||
              {}
            }
            factData={
              (detailStore.workoutData?.fact &&
                JSON.parse(detailStore.workoutData?.fact)) ||
              {}
            }
            indicators={
              (detailStore.workoutData?.indicators &&
                JSON.parse(detailStore.workoutData?.indicators)) ||
              {}
            }
          />
          <Indicators data={detailStore.workoutData?.indicators} />
        </div>
      )}
      <Map />
      <SStravaLDiv>
        <SLink target="_BLANK" href={detailStore.workoutData?.link}>
          View on Strava
        </SLink>
      </SStravaLDiv>
    </div>
  );
});

const SStravaLDiv = styled.div`
  padding: 16px;
`;

const SLink = styled(Link)`
  color: var(--G7, #7b8a9e);
  font-family: Golos Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;
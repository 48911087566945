import { observer } from "mobx-react";
import { useAppContext } from "../../AppContext";
import { WorkoutId } from "../../stores/WorkoutStore";
import { Link } from "react-router-dom";
import { ReactComponent as NextIcon } from "../../assets/icons/next.svg";
import { ReactComponent as PreviousIcon } from "../../assets/icons/previous.svg";
import styled from "@emotion/styled";
import { WorkoutType } from "../../components/scheduler/types";
import { getIconByWorkoutType } from "../../components/scheduler/calendar/utils/getIconByWorkoutType";
import dayjs from "dayjs";

export default observer(() => {
  const {
    store: { workoutStore, detailStore },
  } = useAppContext();
  const nextItem: any = getNext(
    workoutStore.workoutList.slice().reverse(),
    detailStore.workoutData?.id || ""
  );
  const previousItem: any = getNext(
    workoutStore.workoutList,
    detailStore.workoutData?.id || ""
  );

  return (
    <SControls>
      {previousItem && (
        <Link to={`/analysis/${previousItem.id}`}>
          <PreviousIcon />
        </Link>
      )}

      {detailStore.workoutData?.type === WorkoutType[WorkoutType.running] && (
        <SIcon>{getIconByWorkoutType(detailStore.workoutData?.type)}</SIcon>
      )}

      <SDate>
        {detailStore.workoutData?.name
          ? `${detailStore.workoutData?.name}, `
          : ""}
        {dayjs(detailStore.workoutData?.startDate)
          .locale("ru")
          .utc(false)
          .add(detailStore.workoutData?.utcOffset || 0, "hour")
          .format("D MMMM, HH:mm")}
      </SDate>
      {nextItem && (
        <Link to={`/analysis/${nextItem.id}`}>
          <NextIcon />
        </Link>
      )}
    </SControls>
  );
});

function getNext(items: WorkoutId[], currentId: string) {
  let current: WorkoutId | undefined = undefined;

  for (let i = 0; i < items.length; i++) {
    const value = items[i];
    if (value.id === currentId) {
      return current;
    }
    current = value;
  }
  return undefined;
}

const SControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SDate = styled.div`
  color: var(--Black, #1a222d);
  font-family: Golos Text;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 16px;
`;

const SIcon = styled.div`
  padding-left: 16px;
`;

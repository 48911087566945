import styled from "@emotion/styled";
import { FormControl, TextField, Typography } from "@mui/material";
import { ChangeEvent } from "react";

export default ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: any) => void;
}) => (
  <SWrapper>
    <FormControl fullWidth>
      <STitle>Вес</STitle>
      <SInputDiv>
        <STextField
          type="number"
          fullWidth
          value={value}
          onChange={(
            evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            onChange(parseInt(evt.target.value));
          }}
          size="small"
        />
      </SInputDiv>
    </FormControl>
  </SWrapper>
);

const SWrapper = styled.div`
  display: flex;
  // padding-bottom: 24px;
  width: 182px;
`;

const STitle = styled(Typography)`
  color: var(--Black, #1a222d);
  font-family: Golos Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

const SInputDiv = styled.div`
  display: flex;
  height: 36px;
  padding: 12px 0 0 0;
  align-items: center;
  gap: 15px;
  align-self: stretch;
`;

const STextField = styled(TextField)`
  border-radius: 4px;
  background: var(--G2, #eef5fb);
`;